import React, { CSSProperties } from "react";

// Customizable Area Start
import {
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import LandingPageController, {
  Props,
} from "./LandingPageController";
import LandingLeftSide from "../../../components/src/LandingLeftSide.web";
import { Following, Home, Popular, Saved, userIcon } from "./assets";
import LandingPageJobsSection from "./LandingPageJobsSection.web";
import CreateComment from "../../../../packages/blocks/comments/src/CreateComment";
import CreateFeedPost from "./components/CreateFeedPost.web";
import CreatePostSuccessModal from "./components/CreatePostSuccessModal.web";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import LandingPageTalentPool from "./LandingPageTalentPool.web";
import { Helmet } from 'react-helmet';
import { META_TAGS } from '../../../../packages/components/src/Utils.web';
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {

    const globalCSS = `

    ::-webkit-scrollbar {
      display: none;
    }

    * {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    .grid-item {
      padding: 16px; /* Default spacing for all screen sizes */
    }

    .grid-item0 {
      order: 1 !important;
    }
    .grid-item1 {
      order: 2 !important;
    }
    .grid-item2 {
      order: 3 !important
    }
    
    @media (max-width: 600px) {
      .grid-item {
        padding: 5px; /* Spacing for small screens and above */
      }
    }
    
    @media (max-width: 1115px) {
      .grid-item {
        padding: 8px;
      }
    }

    @media (max-width: 960px){
      .grid-item0 grid-item1 grid-item2 {
        padding: 20px;
      }
        .grid-item0 {
          order:3 !important;
        }
        .grid-item1 {
          order: 1 !important;
        }
        .grid-item2 {
          order: 2 !important
        }
    }

    @media (min-width: 600px) {
      .right-fixed {
        position: sticky;
        top: 10px;
      }
    }
 
    @media (max-width: 600px){
      .grid-item0 grid-item1 grid-item2 {
        padding: 20px;
      }
        .grid-item0 {
          order: 1 !important;
        }
        .grid-item1 {
          order: 2 !important;
        }
        .grid-item2 {
          order: 3 !important
        }
    }
`;

    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <Helmet>
          <meta
            name="description"
            content={`
        Explore private equity, venture capital, investment banking, and more. Join our platform for exclusive career opportunities and connect with top firms.
        `}
          />
          <meta
            name="keywords"
            content={META_TAGS}
          />

          <meta property="og:title" content="Join Our Platform for Private Equity Professionals" />
          <meta
            property="og:description"
            content="Unlock private equity career opportunities. Join our platform to connect with top firms. Start your journey today!"
          />
          <meta property="og:type" content="website" />

          <meta name="twitter:title" content="Join Our Platform for Private Equity Professionals" />
          <meta
            name="twitter:description"
            content="Unlock private equity career opportunities. Join our platform to connect with top firms. Start your journey today!"
          />
        </Helmet>
        <style>{globalCSS}</style>
        <Grid container style={webStyle.root as CSSProperties} className="container-landingpage" id="create-comment">
          {this.state.open &&
            <CreateFeedPost
              data-test-id="createFeedPost"
              postDescription={this.state.postDescription}
              postContentHandler={this.postContentHandler}
              handleDeleteImage={this.handleDeleteImage}
              images={this.state.images}
              handleImageChange={this.handleImageChange}
              handleStateUpdate={this.handleStateUpdate}
              loading={this.state.loading}
              selectedTopics={this.state.selectedTopic}
              selectTopics={this.state.categoriesList}
              open={this.state.open}
              selectTopicsValue={this.selectTopicsValue}
              onClose={this.closeModal}
              isPostImageUploaded={this.state.isPostImageUploaded}
              createFeedPostApi={this.createFeedPostApi}
              userProfileName={this.state.userProfileName}
              onSelectHandler={this.onSelectHandler}
              loader={this.state.loaderImage}
              progress={this.state.progressImage}
              remainingTime={this.state.remainingTimeImage}
              profileImage={this.state.profileImage}
            />
          }
          <CreatePostSuccessModal open={this.state.openPopover} onClick={this.viewHandler} />
          <Grid item className=" grid-item grid-item0" xs={12} sm={12} md={3} lg={3} style={{ maxHeight: "calc(100bh - 78px)", order: 1, overflowY: "auto" }} >
            <LandingLeftSide
              categoriesList={this.state.categoriesList}
              selectedSubCategory={this.state.selectedSubCategory}
              setOpenedCategory={this.setOpenedCategory}
              data={[
                { name: "Home", icon: Home, key: "home" },
                { name: "Popular", icon: Popular, key: "popular" },
                { name: "Following", icon: Following, key: "following" },
                { name: "Saved Articles", icon: Saved, key: "saved" }
              ]}
              handlePostList={this.handlePostList}
              selectedPostType={this.state.postType}
            />
          </Grid>
          <Grid item className="grid-item grid-item1" xs={12} sm={8} md={6} lg={6} container style={{ ...webStyle.middleContainer, order: 2 }}>
          {!this.state.isPostCreated &&
          <Grid data-test-id="openPostModal" sm={12} item onClick={() => this.openModal()} style={{
            border: '1px solid #EBE9E9',
            width: '100%',
            borderRadius: '40px',
            height: 'fit-content',
            padding: '10px 15px',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: "20px",
            background: "#FFFFFF",
            color: "#1F497D",
            position: "relative",
            fontFamily: "Arial",
            boxShadow: "0px 4px 4px 0px #1839621A",
            cursor: "pointer"

          }}>
            <img src={userIcon} style={{
              position: "absolute",
              left: "14px",
              top: "3.5px",
            }} />
              What's on your mind?
               </Grid>
            }
            <Grid sm={12} style={{
            width: '100%',
            borderRadius: '40px',
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            position: "relative",
            fontFamily: "Arial",
          }}>
            <AdvancedSearch {...this.props}/>
          </Grid>
          <Grid sm={12} style={{
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'flex-start',
            padding: "24px 0px",
            position: "relative",
            fontFamily: "Arial",
          }}>
            <Typography
              style={{fontFamily:'Arial', fontWeight:400, 
              fontSize:'14px', color:'#000000', width:'670px', height:'22px'}}
              id="header-text"
            >
              {this.renderHeading()}
            </Typography>
          </Grid>
            <CreateComment id="create-comment" navigation={this.props.navigation} selectedSubCategory={this.state.selectedSubCategory} postType={this.state.postType}/>
          </Grid>
          <Grid item className="grid-item grid-item2" xs={12} sm={4} md={3} lg={3} style={{ maxHeight: "calc(100bh - 78px)", order: 3, overflowY: 'auto' }}>
          <div className="right-fixed">
            {this.state.userRole === "recruiter" ?
              <LandingPageTalentPool {...this.props} />
              :
              <LandingPageJobsSection {...this.props} />
            }
            </div>
          </Grid>
        </Grid >
      </>
      // Customizable Area End
    );
  }
}

const webStyle = {
  root: {
    margin: "0px",
    width: "100%",
    padding: "0px 36px",
    paddingBottom: "20px",
    height: "100vh",
    position: "relative",
    maxWidth: "1280px",
  },
  middle: {
    background: "white",
    height: "100%",
    padding: "0px",
    paddingTop: "20px",
    paddingBottom: "60px",
    marginTop: "20px",
    borderRadius: "16px",
  },
  middleContainer: {
    "display": "flex",
    "alignItems": "flex-start",
    "overflow": "hidden",
    margin: "0px",
    alignContent: "baseline"
  },
  userIcon: {
    position: "absolute",
    left: "5px",
    top: "4px"
  }
};
