// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Platform } from "react-native";
import { debounce } from "lodash";
import { toast } from "react-toastify";
export const configJSON = require("./config");
import { buySideIcon, careerDevelopmentIcon, sellSideIcon } from "./assets";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { getUserRole, handleConditionFunction, handleScroll, isPhoneValidOrNot } from "../../../components/src/Utils.web";
import { CountryData } from "react-phone-input-2";

export interface ImageOrVideo {
    id: number;
    url: string;
}

export interface Account {
    account_name: string;
    account_profile: string;
}
export interface UserDetails {
    bio?: string,
    id?: string,
    email?: string,
    city?: string,
    full_name?: string,
    experience?: string,
    work_email_id: string,
    personal_email_id:string,
    resume?: {
        name: string,
        url: string,
        size: number
    } | null,
    skills: [string][],
    photo?: string,
    cover_letter?: {
        name: string,
        url: string,
        size: number,
    } | null,
    open_for_direct_approach: boolean,
    full_phone_number?: string,
    account_details?: {
        id?: number
    },
    work_experiences: {
        data: []
    },
}
export interface CompanyDetail{

    company_name:string,
    company_description:string,
    company_website:string,
    company_indusry:string,
    company_size:number,
    company_headquarters:string
    company_founded_year:number
    company_icon: string
}
export interface State {
    token: string;
    commentData: string;
    loading: boolean;
    errorMsg: string;
    commentsArray: string;
    comment: string;
    currentPage: number;
    replyCommentId: string | number;
    allPosts: Post[];
    isInfiniteLoading: boolean;
    postShowID: number | string;
    showComment: boolean;
}
export interface Post {
    attributes: {
        name: string;
        id: number;
        body: string;
        description: string;
        sub_category_id: string;
        location: string;
        account_id: number;
        sub_topic: string;
        created_at: string;
        category_id: number;
        model_name: string;
        updated_at: string;
        isliked: boolean;
        images: ImageOrVideo[];
        likes_count: number;
        isdisliked: boolean;
        comment_count: number;
        dislikes_count: number;
        comment: Comment[];
        account: Account;
        
    };
    type: string;
    id: string;
}

export interface Comment {
    id: number;
    account_id: number;
    commentable_type: string;
    commentable_id: number;
    parent_id: number | null;
    comment: string;
    created_at: string;
    updated_at: string;
    likes_count: number;
    dislikes_count: number;
    isliked: boolean;
    isdisliked: boolean;
    comment_images: { id: number | string, url: string }[];
    account: Account;
    reply_count: number;
    reply_to: number | null;
    replies: Reply[];
}

export interface Reply {
    id: number;
    commentable_type: string;
    commentable_id: number;
    parent_id: number | null;
    comment: string;
    created_at: string;
    updated_at: string;
    likes_count: number;
    dislikes_count: number;
    isliked: boolean;
    isdisliked: boolean;
    comment_images: { id: number | string, url: string }[];
    account: Account;
    reply_count: number;
    reply_to: number | null;
    replies: Reply[];
}

export interface Activity {
    posts: [],
    comments: [],
    reactions: [],
}

export interface ConfirmDialog {
    open: boolean,
    onConfirm?: () => void,
    title1: string,
    title2: string
}
interface CurrentPost {
    id: string | number;
    description: string;
    images: any;
    selectedTopicId: string
}
// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
}
export interface S {
    // Customizable Area Start
    userDetails: UserDetails,
    showAllPosts: boolean,
    activityType: string ,
    updateActivityType: string,
    allPosts: Post[];
    token: string;
    activity: Activity,
    showComment: boolean,
    postShowID: number | string;
    openConfirmDialogNew: ConfirmDialog,
    postToDelete: string | number,
    loading: boolean;
    openEditFeedPost: boolean;
    currentPost: CurrentPost;
    chatBotAnswersRecruiter: {
        country: { value: string, label: string, cId: string } | null,
        city: { value: string, label: string } | null,
        contact: string,
        job_title: string,
        current_company: string,
        personalEmail: string,
        workEmail: string,
        enterOTP:string,
        websiteLink: string,
        companySize: string,
        tempPersonalEmail: string,
        tempWorkEmail: string,
        tempOTP: string,
        personalEmailError: string,
        workEmailError: string,
        enterOTPError: string
    },
    chatBotAnswersRecruiterToShow: {
        showcity: boolean,
        showcountry: boolean,
        showcurrent_company: boolean,
        showcontact: boolean,
        showjob_title: boolean,
        showpersonalEmail: boolean,
        showworkEmail: boolean,
        showenterOTP: boolean,
        showwebsiteLink: boolean,
        showcompanySize: boolean,
        showtempPersonalEmail: boolean,
        showtempWorkEmail: boolean,
        showtempOTP: boolean,
        showpersonalEmailError: boolean,
        showworkEmailError: boolean,
        showenterOTPError: boolean,
        showContactNumberError: boolean,
        showwebsiteLinkMain: boolean
    },
    loadingChatButton:boolean,
    completeProfileModalRecruiter: boolean,
    cities: {value: string, label: string}[],
    companyList: string[],
    totalSeconds: number,
    shouldFocusBioInput:boolean,
    tabPanelNo: number,
    userID: string;
    companyDetails:CompanyDetail,
    loaderComment: boolean;
    imageList: File[],
    isPostImageUploaded: boolean,
    selectTopicsList: {
        [category: string]: {
            icon: string,
            topics: string[]
        };
    },
    openInvitationDialog: {
        open: boolean;
        receiverId: number | string
    };
    selectedPostId: string | number,
    progressComment: number,
    remainingTimeComment: number,
    expandedPosts: string[]
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ProfilePageForRecruiterController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    upvotePostApiCallId: string = ""
    downvotePostCommentApiCallId: string = ""
    downvotePostApiCallId: string = ""
    upvotePostCommentApiCallId:string=""
    getAllCommentsForApiId:string= ""
    getPostsApiCallId: string = ""
    getReactionPostsApiCallId: string = ""
    getCommentsPostsApiCallId: string = ""
    createCommentApiCallID:string = ""
    deletePostApiId:string = ""
    savePostApiId:string = ""
    updateDirectApproachApiCallId:string = ""
    chatUserRecruiterProfileUpdatApiID: string = ""
    getCompaniesListApiId: string = ""
    interval: number | undefined
    sendOTPApiId: string = ""
    validateOTPApiId: string = ""
    recruiterProfileGetApiCallId: string = ""
    userPostId: string = "";
    commentId: string = "";
    reactionId: string = "";
    companyId: string ="";
    upvoteCommentID: string = "";
    hidePostApiId: string = "";
    createFeedPostApiId: string = "";
    deleteCommentApiId: string="";
    downVoteCommentID: string = "";
    unlikeID: string = "";
    addCommentID: string = "";
    sendInvitationPostApiCallId:string = '';
    removeInvitationPostApiCallId: string = "";
    followUserPostApiCallId: string = "";
    userProfileGetApiCallId: string = "";



    // Customizable Area End
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.state = {
            loadingChatButton: false,
            userDetails: {
                open_for_direct_approach: false,
                work_experiences: { data: [] },
                skills: [],
                personal_email_id:"",
                work_email_id: ""
            },
            showAllPosts: false,
            activityType: "posts",
            updateActivityType: "posts",
            allPosts: [] as Post[],
            token: "",
            activity: {
                posts: [],
                comments: [],
                reactions: [],
            },
            showComment: false,
            postShowID: Infinity,
            openConfirmDialogNew: {
                open: false,
                title1: "",
                title2: ""
            },
            postToDelete: "",
            loading:false,
            openEditFeedPost: false,
            currentPost: {
                id: '',
                description: '',
                images: [],
                selectedTopicId: '',
                

            },
            shouldFocusBioInput:false,
            chatBotAnswersRecruiter: {
                country: null,
                contact: "",
                city: null,
                personalEmail: "",
                job_title: "",
                current_company: "",
                enterOTP: "",
                workEmail: "",
                companySize: "",
                websiteLink: "",
                tempPersonalEmail: "",
                personalEmailError: "",
                tempWorkEmail: "",
                tempOTP: "",
                workEmailError: "",
                enterOTPError: ""
            },
            chatBotAnswersRecruiterToShow: {
                showcurrent_company: false,
                showcontact: false,
                showwebsiteLink: false,
                showjob_title: false,
                showpersonalEmail: false,
                showcountry: false,
                showworkEmail: false,
                showenterOTP: false,
                showcity: false,
                showcompanySize: false,
                showtempWorkEmail: false,
                showtempPersonalEmail: false,
                showpersonalEmailError: false,
                showworkEmailError: false,
                showtempOTP: false,
                showenterOTPError: false,
                showContactNumberError: false,
                showwebsiteLinkMain: false
            },
            completeProfileModalRecruiter: false,
            cities:[],
            companyList: [],
            totalSeconds: 3 * 60,
            tabPanelNo: 0,
            userID: "",
            companyDetails:{
                company_name:"",
                company_description:"",
                company_website:"",
                company_indusry:"",
                company_size:0,
                company_headquarters:"",
                company_founded_year:0,
                company_icon: ""
            },
            loaderComment: false,
           imageList: [],
           isPostImageUploaded: false,
           selectTopicsList: this.topicsList,
           openInvitationDialog: {
            open: false,
            receiverId: ""
        },
        selectedPostId: 0,
        progressComment: 0,
        remainingTimeComment: 0,
        expandedPosts: []
        };
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        switch (apiRequestCallId) {
            case this.getCompaniesListApiId:
                    this.setState({
                        companyList: responseJson.company_listing.map(((item: {name: string}) => item.name))
                    });
                return;
            case this.sendOTPApiId: 
                    if(responseJson?.error) {
                        toast.error(responseJson.error)
                        return;
                    }
                    clearInterval(this.interval);
                    toast.success(responseJson.message);
                    this.interval = setInterval(this.tick, 1000) as unknown as number;
                    this.setState({
                        chatBotAnswersRecruiter: {
                            ...this.state.chatBotAnswersRecruiter,
                            workEmail: this.state.chatBotAnswersRecruiter.tempWorkEmail,
                            workEmailError: ""
                        },
                    })
                    handleScroll();
                    setTimeout(() => {
                        document.getElementById("enterOTP")?.focus();
                    }, 100);
                return;
            case this.validateOTPApiId:
                if(responseJson.verified) {
                    toast.success(responseJson.message);
                    clearInterval(this.interval);
                    this.setState({
                        chatBotAnswersRecruiter: {
                            ...this.state.chatBotAnswersRecruiter,
                            enterOTP: this.state.chatBotAnswersRecruiter.tempOTP
                        }
                    })
                    handleScroll();
                    setTimeout(() => {
                        document.getElementById("job_title")?.focus();
                    }, 100);
                } else {
                    toast.error(responseJson.error)
                }
                return;
            case this.chatUserRecruiterProfileUpdatApiID:
                this.setState({
                    loadingChatButton: false,
                  })
                if(responseJson.message) {
                    this.getUserProfile();
                    toast.success(responseJson.message);
                    this.setState({
                        chatBotAnswersRecruiter: {
                            city: null,
                            country: null,
                            current_company: "",
                            contact: "",
                            job_title: "",
                            personalEmail: "",
                            workEmail: "",
                            enterOTP: "",
                            websiteLink: "",
                            companySize: "",
                            tempPersonalEmail: "",
                            tempWorkEmail: "",
                            tempOTP: "",
                            personalEmailError: "",
                            workEmailError: "",
                            enterOTPError: ""
                        },
                        chatBotAnswersRecruiterToShow: {
                            showcity: false,
                            showcountry: false,
                            showcurrent_company: false,
                            showcontact: false,
                            showjob_title: false,
                            showpersonalEmail: false,
                            showworkEmail: false,
                            showenterOTP: false,
                            showwebsiteLink: false,
                            showcompanySize: false,
                            showtempPersonalEmail: false,
                            showtempWorkEmail: false,
                            showtempOTP: false,
                            showpersonalEmailError: false,
                            showworkEmailError: false,
                            showenterOTPError: false,
                            showContactNumberError: false,
                            showwebsiteLinkMain: false
                        },
                    })
                }
                return;    
            case this.recruiterProfileGetApiCallId:
                window.dispatchEvent(new CustomEvent("USER_PROFILE_DETAILS", { detail: responseJson.data }))
                this.handleGetUserData1(responseJson);
                return;
            default:
                break;
        }
        this.handleGetUserData(from ,message);
        this.handleResponseForHidePost(from, message);
        this.handleDeleteComments(from, message);
        this.handleEditComment(from, message);
        this.handleResponseForUserPostActivity(from, message);
        this.handleResponseForUserCommentActivity(from, message);
        this.handleResponseForUserReactiontActivity(from, message);
        this.handleResponseForUserPostActivity(from, message);
        this.handleResponseForUserCommentActivity(from, message);
        this.handleResponseForUserReactiontActivity(from, message);
        this.handleResponseForDownVoteApi(from, message);
        this.handleResponseForAddCommentAPi(from, message);
        this.handleResponseForcommentLikeAPi(from, message);
        this.handleResponseForcommentDislike(from, message);
        this.handleResponseForUpVotePost(from, message);
        this.handleResponseForDownVotePost(from, message);
        this.handleResponseForCommentPost(from, message)
        this.handleResponseForCreatPost(from, message)
        this.handleResponseForSavePost(from, message)
        this.handleResponseForDeletePost(from, message)
        this.handleEditComment(from, message)
        this.handleCreateFeedResposne(from, message)
        this.handleResponseForSentInvitation(from, message)
        this.handleResponseForRemoveConnection(from, message)
        this.handleResponseForFollowunfollow(from, message)
        this.handleResponseForDirectApproachChnage(from, message)
        this.handleResponseForCompany(from, message);
        // Customizable Area End
    }
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const containerDiv = document.getElementById('pageContainer')
        if (containerDiv) { containerDiv.scrollIntoView() }
        await this.getToken();
        const userID = JSON.parse(localStorage.getItem("userID") || "");
        this.setState({ userID });
        this.handleNetworkTab();
        this.getUserProfile()
        this.getCompanyList()
        this.getAllUpdatedPosts()
        // Customizable Area End
    }
    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        if (prevState.tabPanelNo !== this.state.tabPanelNo || prevState.activityType !== this.state.activityType) {
            this.handleNetworkTab();
        }
    }
    // Customizable Area Start

    handleKeyDownRecruiterBot = (name: string, value: boolean) => {
        this.setState({
            chatBotAnswersRecruiterToShow: {
                ...this.state.chatBotAnswersRecruiterToShow,
                [name]: value
            }
        });
        handleScroll()
    } 


    handleCondition = (condition: HTMLElement | null | boolean | string | ImageOrVideo[] | number, 
        truePart: HTMLElement | undefined | void | any, falsePart: string | any) => {
        return condition ? truePart : falsePart
    }
    topicsList = {
        "Buy-Side": { icon: buySideIcon, topics: ["Private Equity", "Growth Equity", "Venture Capital", "Infrastructure Investment", "Real Estate Investment", "Investor Relations and Capital Raising", "Corporate Development"] },
        "Sell-Side": { icon: sellSideIcon, topics: ["Investment Banking", "Equity Research", "Sales and Trading", "Market Analysis"] },
        "Career Development": { icon: careerDevelopmentIcon, topics: ["Breaking into Buy-side", "Breaking into Sell Side", "Career Advancement", "Work-Life Balance", "Networking and Mentorship"] }
    }

    isValidEmail = (email: string) => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    }

    tick = () => {
        this.setState(prevState => ({
          totalSeconds: prevState.totalSeconds - 1
        }));
    
        if (this.state.totalSeconds === 0) {
          clearInterval(this.interval);
          this.setState({
            totalSeconds:3*60,
            chatBotAnswersRecruiter: {
                ...this.state.chatBotAnswersRecruiter,
                enterOTP: "",
                workEmail: "",
                tempWorkEmail: "",
                tempOTP: ""
            }
          });
          toast.info("Try again...");
        }
      };
    
    handleChangeChatBotField = (value: string, fieldName: string, cId: string = "", countryData?: CountryData | {} | undefined) => {
        switch (fieldName) {
            case "city":
                this.setState({
                    chatBotAnswersRecruiter: {
                        ...this.state.chatBotAnswersRecruiter,
                        city: { value: value, label: value }
                    },
                })
                return;
            case "country":
                this.setState({
                    chatBotAnswersRecruiter: {
                        ...this.state.chatBotAnswersRecruiter,
                        country: { value: value, label: value, cId: cId }
                    },
                })
                return;
            case "current_company":
                this.setState({
                    chatBotAnswersRecruiter: {
                        ...this.state.chatBotAnswersRecruiter,
                        current_company: value
                    },
                })
                return;
            case "contact":
                this.handleContact(value, countryData);
                return;
            case "job_title":
                this.setState({
                    chatBotAnswersRecruiter: {
                        ...this.state.chatBotAnswersRecruiter,
                        job_title: value
                    },
                })
                return;
            case "personalEmail":
                this.setState({
                    chatBotAnswersRecruiter: {
                        ...this.state.chatBotAnswersRecruiter,
                        tempPersonalEmail: value
                    },
                })
                return;
            case "workEmail":
                this.setState({
                    chatBotAnswersRecruiter: {
                        ...this.state.chatBotAnswersRecruiter,
                        tempWorkEmail: value
                    },
                })
            return;
            case "enterOTP":
               this.handleOtp(value);
            return;
            case "personalEmailExt":
                this.personalEmailExt(value);
            return;
            case "workEmailExt":
                this.handleEmailExt(value);
            return;
            case "enterOTPExt":
               this.validateOTP(this.state.chatBotAnswersRecruiter.workEmail, +value);
            return;
            case "websiteLink":
                this.setState({
                    chatBotAnswersRecruiter: {
                        ...this.state.chatBotAnswersRecruiter,
                        websiteLink: value
                    },
                })
            return;
            case "companySize":
                this.setState({
                    chatBotAnswersRecruiter: {
                        ...this.state.chatBotAnswersRecruiter,
                        companySize: value
                    },
                })
            return;
        }
    }

    handleContact = (value: string, countryData?: CountryData | {} | undefined) => {
        if (countryData && !isPhoneValidOrNot({ phone: value, country: countryData })) {
            this.setState({
                chatBotAnswersRecruiterToShow: {
                    ...this.state.chatBotAnswersRecruiterToShow,
                    showContactNumberError: true
                }
            })
        } else {
            this.setState({
                chatBotAnswersRecruiterToShow: {
                    ...this.state.chatBotAnswersRecruiterToShow,
                    showContactNumberError: false
                }
            }) 
        }
        this.setState({
            chatBotAnswersRecruiter: {
                ...this.state.chatBotAnswersRecruiter,
                contact: value
            },
        })
    }

    handleOtp = (value: string) => {
        if(Number.isNaN(+value)) {
            return
        }
        this.setState({
            chatBotAnswersRecruiter: {
                ...this.state.chatBotAnswersRecruiter,
                tempOTP: value
            },
        })
    }

    handleEmailExt = (value: string) => {
        if(this.isValidEmail(value)){
            if (this.state.userDetails.work_email_id === value){
                this.setState({
                    chatBotAnswersRecruiter: {
                        ...this.state.chatBotAnswersRecruiter,
                        workEmail: this.state.chatBotAnswersRecruiter.tempWorkEmail,
                        workEmailError: "",
                        enterOTP: "558585"
                    },
                    chatBotAnswersRecruiterToShow: {
                        ...this.state.chatBotAnswersRecruiterToShow,
                        showenterOTP: false
                    }
                })

                handleScroll();
                setTimeout(() => {
                    document.getElementById("job_title")!.focus();
                }, 1000);
            } else {
                this.handleKeyDownRecruiterBot("showenterOTP", true)
                this.sendOTPonMail(value);
            }
        } else {
            this.setState({
                chatBotAnswersRecruiter: {
                    ...this.state.chatBotAnswersRecruiter,
                    workEmailError: "Email is invalid"
                },
            })
        }
    }

    personalEmailExt = (value: string) => {
        if(this.isValidEmail(value)){
            this.setState({
                chatBotAnswersRecruiter: {
                    ...this.state.chatBotAnswersRecruiter,
                    personalEmail: value,
                    personalEmailError: ""
                },
            })
            setTimeout(() => {
                document.getElementById("workEmail")!.focus();
            }, 200);
        } else {
            this.setState({
                chatBotAnswersRecruiter: {
                    ...this.state.chatBotAnswersRecruiter,
                    personalEmailError: "Email is invalid"
                },
            })
        }
    }

    handleCitiesList = (value: { value: string, label: string }[]) => {
        this.setState({
            cities: value
        })
    }

    companyListHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value) {
            this.getCompaniesList(value);
        }
    };

    getCompaniesList = debounce(async (value: string) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken")
        };
        let userData = { search_name: this.handleCondition(value !== "", value, "") };

        const updateUserDetailsOne = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCompaniesListApiId = updateUserDetailsOne.messageId;

        updateUserDetailsOne.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.CompanyListApiEndPoint}`
        );

        updateUserDetailsOne.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        updateUserDetailsOne.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(userData)
        );

        updateUserDetailsOne.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(updateUserDetailsOne.id, updateUserDetailsOne);
    }, 100)

    updateUserDetailsFromChat = async () => {
        const headers = {
            "token": localStorage.getItem('authToken')
          };
          this.setState({
            loadingChatButton: true,
          })
          const updateRecruiterUserProfile = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.chatUserRecruiterProfileUpdatApiID = updateRecruiterUserProfile.messageId;
      
          let formData = new FormData();
      
          formData.append("recruiter_profile[api_name]", "recruiter_chatbot");
          formData.append("recruiter_profile[country]", (this.state.chatBotAnswersRecruiter.country as {
            value: string;
            label: string;
            cId: string;
          }).value);
          formData.append("recruiter_profile[full_phone_number]", this.state.chatBotAnswersRecruiter.contact);
          formData.append("recruiter_profile[personal_email_id]", this.state.chatBotAnswersRecruiter.personalEmail);
          formData.append("recruiter_profile[work_email_id]", this.state.chatBotAnswersRecruiter.workEmail);
          formData.append("recruiter_profile[current_title]", this.state.chatBotAnswersRecruiter.job_title);
          formData.append("recruiter_profile[company_website]", this.state.chatBotAnswersRecruiter.websiteLink);
          formData.append("recruiter_profile[company_size]", this.state.chatBotAnswersRecruiter.companySize);
          formData.append("recruiter_profile[company_name]", this.state.chatBotAnswersRecruiter.current_company);
          formData.append("recruiter_profile[company_founded_year]", "2025");
          formData.append("recruiter_profile[city]", (this.state.chatBotAnswersRecruiter.city as {
            value: string;
            label: string;
          }).value);
        
          updateRecruiterUserProfile.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateRecruiterProfileApi            
          );
      
          updateRecruiterUserProfile.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
      
          updateRecruiterUserProfile.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
          );
      
          updateRecruiterUserProfile.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
          );
          runEngine.sendMessage(updateRecruiterUserProfile.id, updateRecruiterUserProfile);
      
          this.toggleDrawerRecruiter(false)
    }

    toggleDrawerRecruiter = (open: boolean) => {
        this.setState({ 
            completeProfileModalRecruiter: open, 
            chatBotAnswersRecruiter: {
                city: null,
                country: null,
                current_company: "",
                contact: "",
                job_title: "",
                personalEmail: "",
                workEmail: "",
                enterOTP: "",
                websiteLink: "",
                companySize: "",
                tempPersonalEmail: "",
                tempWorkEmail: "",
                tempOTP: "",
                personalEmailError: "",
                workEmailError: "",
                enterOTPError: ""
            }
        });
    };

    sendOTPonMail = async (value: string) => {
        const authToken = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };
        const requestMessage1 = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sendOTPApiId = requestMessage1.messageId;
        requestMessage1.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendOTPEndPoint
        );
        requestMessage1.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage1.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
            email: value
        })
        )
        requestMessage1.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(requestMessage1.id, requestMessage1);
    }

    validateOTP = async (email: string, OTP: number) => {
        const authToken = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };
        const requestMessage1 = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.validateOTPApiId = requestMessage1.messageId;
        requestMessage1.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.verifyOTPApiEndPoint
        );
        requestMessage1.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage1.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
            email: email,
            otp: OTP
        })
        )
        requestMessage1.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(requestMessage1.id, requestMessage1);
    }

    getUserProfile = async () => {
        const authToken = await getStorageData("authToken");
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.recruiterProfileGetApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateUserDetailsEndPoint
        );

        const header = {
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
            token: authToken
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleGetUserData1 = (response: any) => {
        if (!response.errors) {
            this.setState({
                userDetails: response.data.attributes
            })
        }
    }
    handleNetworkTab = async () => {
        this.handleCondition(this.state.activityType === "posts", this.getPostList(), "")
        this.handleCondition(this.state.activityType === "comments", this.getCommentList(), "")
        this.handleCondition(this.state.activityType === "reactions", this.getReactionList(), "")
    }
    handleResponseForHidePost = async (
        from: string,
        message: Message
    ) => {
        if (
            this.hidePostApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                toast.success(apiResponse.message);
                await this.getPostList();
            }
        }
    };
    handleResponseForRemoveConnection = async (
        from: string,
        message: Message
    ) => {
        if (
            this.removeInvitationPostApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                toast.success(apiResponse.message)
                await this.getPostList();

            }
        }
    };
    handleResponseForUpVotePost = async (
        from: string,
        message: Message
    ) => {
        if (
            this.upvotePostApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                this.getPostList();
            }
        }
    };
    getUserName = () => {
        return this.state.userDetails.full_name ? `Mr. ${this.state.userDetails.full_name}` : ''
    }
    handleResponseForCommentPost = async (
        from: string,
        message: Message
    ) => {
        if (
            this.upvotePostCommentApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
           
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                this.getPostList();
            }
        }
    };
    handleResponseForSavePost = async (
        from: string,
        message: Message
    ) => {
        if (
            this.savePostApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                this.getPostList();
            }
        }
    };
    handleResponseForDownVotePost = async (
        from: string,
        message: Message
    ) => {
        if (
            this.downvotePostApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                this.getPostList();
            }
        }
    };
    handleResponseForDeletePost = async (
        from: string,
        message: Message
    ) => {
        if (
            this.deletePostApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                toast.success(apiResponse.message);
                this.handleClose();
                this.getPostList();
            }
        }
    };
    handleFeedImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = [
                ...this.state.currentPost.images,
                ...Array.from(event.target.files)
            ];
            const imageList = [...this.state.imageList, ...Array.from(event.target.files)];
            this.setState({
                currentPost: {
                    ...this.state.currentPost,
                    images: files,
                },
                imageList: imageList
            });
        }
        this.setState({ isPostImageUploaded: true })
    };
  
    handleStateUpdate = () => {
        this.setState({ isPostImageUploaded: false });
    }
    
    handleInvitationClose = () => {
        this.setState({
            openInvitationDialog: {
                ...this.state.openInvitationDialog,
                open: false,
            },
        })
    }
    handleResponseForFollowunfollow = async (
        from: string,
        message: Message
    ) => {
        if (
            this.followUserPostApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                await this.getPostList();
            }
        }
    };
    handleEditComment = async (
        from: string,
        message: Message
    ) => {
        if (
            this.getAllCommentsForApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            }else {
                this.handleEditClose();
                await this.getPostList();
            }
        }
    };
    getCommentsOfPost = (commentId: number | string) => {
        const authToken = localStorage.getItem("authToken")
        const header = { 'Content-Type': configJSON.ApiContentType, token: authToken };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getAllCommentsForApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCommentOfPostEndPoint.replace(":comment_id", commentId)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleDeleteComment = async (commentId: number | string) => {
        const authToken = localStorage.getItem("authToken")
        const headers = {
            token: authToken,
        };
        const handleDeleteComment = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteCommentApiId = handleDeleteComment.messageId;
        handleDeleteComment.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        handleDeleteComment.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCommentsEndPoint}/${commentId}`
        );
        handleDeleteComment.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        runEngine.sendMessage(handleDeleteComment.id, handleDeleteComment);
    }
    handleResponseForCreatPost = async (
        from: string,
        message: Message
    ) => {
        if (
            this.createCommentApiCallID ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                this.getPostList();
            }
        }
    };
    handleDeleteComments = async (
        from: string,
        message: Message
    ) => {
        if (
            this.deleteCommentApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            }else {
                await this.getPostList();
            }
        }
    };
    selectTopicsValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        this.setState({
            currentPost: {
                ...this.state.currentPost,
                selectedTopicId: value
            }
        })
    }
    
    handleCreateFeedResposne = async (
        from: string,
        message: Message
    ) => {
        if (
            this.createFeedPostApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            }else {
                this.setState({loaderComment: false, openEditFeedPost: false})
                await this.getPostList();
            }
        }
    };
    updateComment = async (comment: string = "", files: (File | { url: string })[] = [], nested: boolean = false, commentId: number | string = "", postID: number | string = "") => {
        const startTime = Date.now();
        if (files.length > 0) {
            this.setState({ loaderComment: true })
        }
        const authToken = await getStorageData("authToken")
        let commentData = new FormData();
        commentData.append("comment[commentable_type]", !nested ? "BxBlockPosts::Post" : "BxBlockComments::Comment");
        commentData.append("comment[comment]", `${comment}`);
        files.forEach((file: File | { url: string }, _index: number) => {
            commentData.append(`comment[comment_images][]`, (file as { url: string })?.url ? (file as { url: string }).url : file as File);
        });
        const config: AxiosRequestConfig = {
            headers: { token: authToken ?? '' },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                const { loaded, total } = progressEvent;
                if (total) {
                    const percentCompleted = Math.round((loaded * 100) / total)
                    const currentTime = Date.now()
                    const elapsedTime = (currentTime - startTime) / 1000;
                    const uploadSpeed = loaded / elapsedTime
                    const remainingTime = (total - loaded) / uploadSpeed;
                    this.setState({
                        progressComment: percentCompleted, remainingTimeComment: remainingTime
                    })
                }
            }
        };
        axios.patch(`${configJSON.baseURL.baseURL}/${configJSON.getCommentsEndPoint}/${commentId}`, commentData, config)
            .then(response => {
                this.getCommentsOfPost(this.state.postShowID);
            })
            .catch(error => {
            }).finally(() => {
                this.setState({
                    loaderComment: false,
                    progressComment: 0,
                    remainingTimeComment: 0
                })
            });
    }
    getToken = async () => {
        const token: string =
            Platform.OS == "android" || Platform.OS == "ios"
                ? await getStorageData("token-access")
                : localStorage.getItem("authToken");
        this.setState({ token: token });
    };

    deleteFollowUsers = async (accountId: number | string) => {
        const authToken = localStorage.getItem("authToken")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken,
        };
        const followUserMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.followUserPostApiCallId = followUserMessage.messageId;
        followUserMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.followUserApiEndPoint}/${accountId}?token=${authToken}`
        );
        followUserMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        followUserMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        runEngine.sendMessage(followUserMessage.id, followUserMessage);
    }

    getAllUpdatedPosts = () => {
        this.getPosts("posts")
        this.getPosts("reactions")
        this.getPosts("comments")
    }
    handleClose = () => {
        this.setState({
            openConfirmDialogNew: {
                open: false,
                title1: "",
                title2: "",
            },
        })
    }
    handleEditClose=()=>{
        this.setState({openEditFeedPost:!this.state.openEditFeedPost})
    }
    handleInvite = (noteValue: string) => {
        this.sendInvitation(noteValue, this.state.openInvitationDialog.receiverId)
    }
    sendInvitation = async (noteValue: string, receiverId: string | number) => {
        const authToken = await getStorageData("authToken")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken,
        };
        let invitationBody = {
            "connection_request": {
                "receiver_id": receiverId,
                "note": noteValue
            }
        }
        const sendInvitationMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sendInvitationPostApiCallId = sendInvitationMessage.messageId;
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(invitationBody)
        );
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendInvitationEndpoint
        );
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(sendInvitationMessage.id, sendInvitationMessage);
    }
 
    updateDirectApproach(value: boolean) {
        const formData = new FormData();
        formData.append("profile[open_for_direct_approach]", value.toString())
        const authToken = localStorage.getItem("authToken")
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.updateDirectApproachApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserDetailsEndPoint}/:${this.state.userDetails.id}`
        );
        const header = {token: authToken};
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),formData)
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.putMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleDirectApproachChange = () => {
        this.updateDirectApproach(!this.state.userDetails.open_for_direct_approach)
    }

    handleGetUserData = (from: string, message: Message) => {
        if (
            this.userProfileGetApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (!apiResponse.errors) {
                this.setState({
                    userDetails: apiResponse.data.attributes
                })
            }
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            }
        }
    }
  
    handleResponseForDirectApproachChnage = async (
        from: string,
        message: Message
    ) => {
        if (
            this.updateDirectApproachApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            }else {
                this.setState({loaderComment: false})
                await this.getUserProfile();
            }
        }
    };
    handleResponseForCompany = async (
        from: string,
        message: Message
    ) => {
        if (
            this.companyId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            }else {
                this.setState({
                    companyDetails: {
                        company_name: apiResponse.data.attributes.company_name,
                        company_description: apiResponse.data.attributes.company_description,
                        company_website: apiResponse.data.attributes.company_website,
                        company_indusry: apiResponse.data.attributes.company_indusry,
                        company_size: apiResponse.data.attributes.company_size,
                        company_headquarters: apiResponse.data.attributes.company_headquarters,
                        company_founded_year: apiResponse.data.attributes.company_founded_year,
                        company_icon: apiResponse.data.attributes.company_icon
                    }
                })
            }
        }
    };
    handleFeedModalOpen = (post?: Post) => {
        if (this.state.openEditFeedPost) {
            this.setState({
                openEditFeedPost: !this.state.openEditFeedPost,
                currentPost: { id: '', description: '',
                    images: [], selectedTopicId: ''
                }
            })
        } else if (post) {
            this.setState({openEditFeedPost: !this.state.openEditFeedPost,
                currentPost: {description: this.handleCondition(post.attributes.body, post.attributes.body, ''),
                    images: this.handleCondition(post.attributes.images, post.attributes.images, ''),
                    id: this.handleCondition(post.attributes.id, post.attributes.id, ''),
                    selectedTopicId: this.handleCondition(post.attributes.sub_topic, post.attributes.sub_topic, '')
                }
            })
        }
    }
    handleShowAllPosts = () => {
        const containerDiv = document.getElementById('pageContainer')
        this.handleCondition(containerDiv, containerDiv?.scrollIntoView(), "")
        this.setState({
            showAllPosts: !this.state.showAllPosts
        })
    }

    handleUpdateProfile = () => {
        const toNavigate: Message = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "UserEditProfile");
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
        setStorageData("autofocus", " ");
    }

     handleUpdateBio=()=>{
        const toNavigate1: Message = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate1.addData(getName(MessageEnum.NavigationTargetMessage), "UserEditProfile");
        toNavigate1.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate1);
        setStorageData("autofocus","bio")
    }

    handleLeaderBoardNavigation = () => {
        const navigateToLeaderBoard: Message = new Message(getName(MessageEnum.NavigationMessage));
        navigateToLeaderBoard.addData(getName(MessageEnum.NavigationTargetMessage), "LeaderBoard");
        navigateToLeaderBoard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateToLeaderBoard);
    }

    handleCompanyProfile= ()=>{
        const toNavigate2: Message = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate2.addData(getName(MessageEnum.NavigationTargetMessage), "CompanyEditDetails");
        toNavigate2.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate2);
    }
 
    hanleTabs = (event: object, value: number) => {
        this.setState({ tabPanelNo: value });
    };
    handleResponse = (response: {error?: string, errors?: {"token": string}[]}) => {
        if(response.error) {
            toast.error(response.error);
        }else if(response.errors) {
            Object.values(response.errors[0]).forEach((item: string) => toast.error(item))
            if (Object.keys(response.errors[0]).includes("token")) {
                this.handleNavigate()
            }
        }
    }
    postContentHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            currentPost: {
                ...this.state.currentPost,
                description: event.target.value
            }
        })
    }
    handleDeleteImage = (index: number) => {
        const updatedImages = this.handleCondition(this.state.currentPost.images,
            this.state.currentPost?.images.filter((postImage: any, imageIndex: number) => imageIndex !== index),
            [])

        this.setState({
            currentPost: {
                ...this.state.currentPost,
                images: updatedImages,
            }
        });
    };
    handleUpdateActivityType = (type: string) => {
        this.setState({
            showComment: !this.state.showComment,
            postShowID: "",
            activityType: type
        })
    }
    handleNavigate = () => {
        const toNavigate: Message = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    }
    createComment = (comment: string = "", files: File[] = [], nested: boolean = false, commentId: number | string = "", postID: number | string = "") => {
        const authToken = localStorage.getItem("authToken")
        const startTime = Date.now();
        if (files.length > 0) { this.setState({ loaderComment: true })}
        this.setState({
            updateActivityType: this.state.activityType
        })
        let commentData = new FormData();
        commentData.append("comment[commentable_id]", `${commentId}`);
        commentData.append("comment[commentable_type]", !nested ? "BxBlockPosts::Post" : "BxBlockComments::Comment");
        commentData.append("comment[comment]", `${comment}`);
        commentData.append("comment[post_id]", `${postID}`);
        files.forEach((file, index) => {
            commentData.append(`comment[comment_images][]`, file);
        });
        const config: AxiosRequestConfig = {
            headers: { token: authToken ?? '' },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => 
            {
              const { loaded, total } = progressEvent;
              if (total) {
                const percentCompleted = Math.round((loaded * 100) / total);
                const currentTime = Date.now();
                const elapsedTime = (currentTime - startTime) / 1000;
                const uploadSpeed = loaded / elapsedTime;
                const remainingTime = (total - loaded) / uploadSpeed;
                this.setState({ 
                  progressComment: percentCompleted, 
                  remainingTimeComment: remainingTime 
                })
              }
            }
          };
        
          axios.post(`${configJSON.baseURL.baseURL}/${configJSON.getCommentsEndPoint}`, commentData, config)
            .then(response => { 
              this.getCommentsOfPost(this.state.postShowID) 
            })
            .catch(error => { })
            .finally(() => {
              this.setState({
                loaderComment: false, 
                remainingTimeComment: 0,
                progressComment: 0, 
              })
        
            });
    }
    handleDeletePostApi = async (postId: number | string) => {
        const authToken = await getStorageData('authToken')
        this.setState({postToDelete: postId})
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };
        const deletePost2 = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deletePostApiId = deletePost2.messageId;
        deletePost2.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deletePostApi}/${postId}`
        );
        deletePost2.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        deletePost2.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        runEngine.sendMessage(deletePost2.id, deletePost2);
    }
    handleEditPostResponse = () => {
        this.getAllUpdatedPosts()
        this.setState({
            loading: false,
            openEditFeedPost: false,
            currentPost: {
                id: '',
                description: '',
                images: [],
                selectedTopicId: ''
            }
        })
    }
    handleResponseForDownVoteApi =
    (from: string, message: Message) => {
        if (
            this.unlikeID ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } 
            this.handleNetworkTab();
        }
    };
    
handleResponseForAddCommentAPi =
    (from: string, message: Message) => {
        if (
            this.addCommentID ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } 
            this.setState({loaderComment: false})
            this.handleNetworkTab();
        }
    };
handleResponseForcommentLikeAPi =
    (from: string, message: Message) => {
        if (
            this.upvoteCommentID ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } 
            this.handleNetworkTab();
        }
    };
handleResponseForcommentDislike =
    (from: string, message: Message) => {
        if (
            this.downVoteCommentID ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            }
            this.handleNetworkTab();
        }
    };
handleResponseForUserPostActivity =
    (from: string, message: Message) => {
        if (
            this.userPostId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                this.setState({ activity: { ...this.state.activity, posts: apiResponse.data } });
                this.setState({loading: false})
            }
        }
    };

handleResponseForUserCommentActivity =
    (from: string, message: Message) => {
        if (
            this.commentId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                this.setState({ activity: { ...this.state.activity, comments: apiResponse.data } });
                this.setState({loading: false})
            }
        }
    };

handleResponseForUserReactiontActivity =
    (from: string, message: Message) => {
        if (
            this.reactionId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                this.setState({ activity: { ...this.state.activity, reactions: apiResponse.data } });
                this.setState({loading: false})
            }
        }
    };

    handleResponseForSentInvitation = async (
        from: string,
        message: Message
    ) => {
        if (
            this.sendInvitationPostApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                toast.success(apiResponse.message);
                this.getUserProfile();
                this.getPostList();

            }
        }
    };
    
    handleSavePostApi = (postId: number | string, isSaved?: boolean) => {
        const authToken = localStorage.getItem("authToken")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };
        const requestMessage1 = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.savePostApiId = requestMessage1.messageId;
        requestMessage1.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage1.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            isSaved ? `${configJSON.savePostApi}/${postId}/unsave_post` : `${configJSON.savePostApi}/${postId}/save_post`
        );
        requestMessage1.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
            isSaved ? configJSON.deleteMethod : configJSON.postMethod
        );
        runEngine.sendMessage(requestMessage1.id, requestMessage1);
    }

    openPostDeleteDialog = (postId: number | string) => {
        this.setState({
            openConfirmDialogNew: {
                open: true,
                onConfirm: () => this.handleDeletePostApi(postId),
                title1: "Are you sure you want to delete this",
                title2: "post?"
            }
        })
    }
    handlePostMenu = (receiverId: number | string, menuType?: 'connect' | 'following' | 'hidePost' | 'reportPost' | "", invitationId?: number | string | boolean) => {
        if (menuType) {
            if (menuType === 'connect') {
                if (invitationId && typeof invitationId !== 'boolean')
                    return this.removeInvitation(invitationId)
                this.handleInvitationDialogOpen(receiverId)
            } else if (menuType === 'hidePost') {
                this.handleHidePostApi(receiverId)
            } else if (menuType === 'following') {
                invitationId ? this.deleteFollowUsers(receiverId) : this.followUser(receiverId)
            }
        }else {
            this.openPostDeleteDialog(receiverId)
        }
    }

    toggleReadMore = (postId: string) => {
        this.setState((prevState) => {
          const { expandedPosts } = prevState;
          const isPostExpanded = expandedPosts.includes(postId);
    
          return {
            expandedPosts: isPostExpanded ? expandedPosts.filter(id => id !== postId) : [...expandedPosts, postId],
          };
        });
      };

    handleUserNavigation = (recruiterId: number | string) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), recruiterId === this.state.userDetails?.account_details?.id ? this.getProfileUrl() : 'Profile');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), recruiterId);
        this.send(message);
    }

    getProfileUrl = () => {
        const userRole = getUserRole();
        return handleConditionFunction(userRole === 'recruiter', 'RecruiterProfile', 'JobApplicantProfile');
    }

    handleInvitationDialogOpen = (receiverId: number | string) => {
        this.setState({
            openInvitationDialog: {
                open: true,
                receiverId: receiverId
            },
        })
    }
    followUser = async (accountId: number | string) => {
        const authToken = localStorage.getItem('authToken')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken,
        };
        let requestBody = {
            "data": {
                "type": "follow",
                "attributes": {
                    "account_id": accountId
                }
            }
        }
        const followUserMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.followUserPostApiCallId = followUserMessage.messageId;
        followUserMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        followUserMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        );
        followUserMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
        );
        followUserMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.followUserApiEndPoint}?token=${authToken}`
        );
        runEngine.sendMessage(followUserMessage.id, followUserMessage);
    }
   
    handleHidePostApi = async (postId: number | string) => {
        this.setState({
            selectedPostId: postId
        })
        const authToken = localStorage.getItem('authToken')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.hidePostApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.savePostEndpoint}/${postId}/hide_post`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    removeInvitation = async (connectionId: string | number, userId?: string | number) => {
        if (userId) {
            this.setState({
                openInvitationDialog: {
                    open: false,
                    receiverId: userId
                }
            })
        }
        const authToken = localStorage.getItem("authToken")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken,
        };
        const sendInvitationMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.removeInvitationPostApiCallId = sendInvitationMessage.messageId;
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.removeInvitationEndpoint}/${connectionId}/withdraw_connection_request`
        );
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        runEngine.sendMessage(sendInvitationMessage.id, sendInvitationMessage);
    }
    createFeedPostApi = () => {
        this.setState({ loading: true });
        let formData = new FormData();
        formData.append("data[attributes][name]", '');
        formData.append("data[attributes][description]", "");
        formData.append("data[attributes][location]", "");
        formData.append("data[attributes][body]", this.state.currentPost.description);
        formData.append("data[attributes][sub_topic]", this.state.currentPost.selectedTopicId);
        this.state.currentPost.images.forEach((file: File | { id: number | string, url: string }) => {
            if ('url' in file) {
                formData.append(`data[attributes][images][]`, file.url);
            } else {
                formData.append(`data[attributes][images][]`, file);
                this.setState({loaderComment: true})
            }
        });
        const headers = {
            "contentType": configJSON.createContentType,
            "token": localStorage.getItem("authToken"),
        };
        const careateFeedPost = new Message(getName(MessageEnum.RestAPIRequestMessage));
        careateFeedPost.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), formData
        );
        this.createFeedPostApiId = careateFeedPost.messageId;
        careateFeedPost.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        careateFeedPost.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editPostApi}/${this.state.currentPost.id}`
        );
        careateFeedPost.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );
        runEngine.sendMessage(careateFeedPost.id, careateFeedPost);
    }
    getPostList = async () => {
        const token = this.state.token;
        this.setState({loading: true})
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const userPostData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.userPostId = userPostData.messageId;
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        userPostData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userPostApi}`.replace(":connection_id", this.state.userID) + `filter=posts`
        );
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(userPostData.id, userPostData);
    };
    downvotePostComment = (postId: number | string, type: string) => {
        const authToken = localStorage.getItem("authToken")
        const header = {
            token: authToken,
        };
        let formData = new FormData();
        formData.append("dislikeable_type", type);
        formData.append("dislikeable_id", `${postId}`);
        const downvotePostMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.downVoteCommentID = downvotePostMessage.messageId;
        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.downvoteEndpoint
        );
        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
    }

    getReactionList = async () => {
        this.setState({loading: true})
        const token = this.state.token;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const userPostData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.reactionId = userPostData.messageId;
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        userPostData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userPostApi}`.replace(":connection_id", this.state.userID) + `filter=reactions`
        );
        runEngine.sendMessage(userPostData.id, userPostData);
    };

    getCommentList = async () => {
        const token = this.state.token;
        this.setState({loading: true})
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const userPostData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        this.commentId = userPostData.messageId;
        userPostData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userPostApi}`.replace(":connection_id", this.state.userID) + `filter=comments`
        );
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(userPostData.id, userPostData);
    };


    toggleComments = (post: Post) => {
        this.getCommentsOfPost(post.id);
        this.setState({ showComment: !this.state.showComment, postShowID: post.id !== this.state.postShowID ? post.id : "" });
    };

    upvotePost = (postId: number | string, type: string, activityType: string) => {
        this.setState({
            updateActivityType: activityType
        })
        const authToken = localStorage.getItem("authToken")
        const header = {
            token: authToken,
        };
        let formData = new FormData();
        formData.append("likeable_id", `${postId}`);
        formData.append("likeable_type", type);
        const upvotePostMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        this.upvotePostApiCallId = upvotePostMessage.messageId;
        upvotePostMessage.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        upvotePostMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.upvoteEndpoint
        );
        upvotePostMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        upvotePostMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
    }
    downvotePost = (postId: number | string, type: string, activityType: string) => {
        const authToken = localStorage.getItem("authToken")
        this.setState({
            updateActivityType: activityType
        })
        const header = {token: authToken,
        };
        let formData = new FormData();
        formData.append("dislikeable_id", `${postId}`);
        formData.append("dislikeable_type", type);
        const downvotePostMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        this.downvotePostApiCallId = downvotePostMessage.messageId;
        downvotePostMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header));
        downvotePostMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.downvoteEndpoint);
        downvotePostMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        downvotePostMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod);
        runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
    }

    upvotePostComment = (postId: number | string, type: string) => {
        const authToken = localStorage.getItem("authToken")
        const header = {
            token: authToken,
        };
        let formData = new FormData();
        formData.append("likeable_type", type);
        formData.append("likeable_id", `${postId}`);
        const upvotePostMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        this.upvotePostCommentApiCallId = upvotePostMessage.messageId;
        upvotePostMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        upvotePostMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        upvotePostMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.upvoteEndpoint
        );
        upvotePostMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
    }

    getPosts(filterValue: string) {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const authToken = localStorage.getItem("authToken")
        if (filterValue === 'posts') {this.getPostsApiCallId = requestMessage.messageId;
        }
        else if (filterValue === "reactions") { this.getReactionPostsApiCallId = requestMessage.messageId;
        }
        else { this.getCommentsPostsApiCallId = requestMessage.messageId;
        }
        const header = {
            "Content-Type": configJSON.contentTypeApiGetUserProfile, token: authToken
        };
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserDetailsEndPoint}/account_activity?filter=${filterValue}`
        );
        requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.methodTypeApiGetUserProfile);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getInitials = (input?: string) => {
        if (input) {
            const words = input.split(' ');
            const initials = words.map(word => word?.[0]?.toUpperCase()).join('');
            return initials;
        } else return ''
    }
    getCompanyList = async () => {
        const token = this.state.token;
        this.setState({loading: true})
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const userCompanyData = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.companyId = userCompanyData.messageId;
        userCompanyData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        userCompanyData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userCompanyApi}`
        );
        userCompanyData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(userCompanyData.id, userCompanyData);
    };


    // Customizable Area End
}