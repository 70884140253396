import { Box, Button, Divider, Grid, TextField, TextareaAutosize, Theme, Typography, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { logoModal } from '../assets'
import { Autocomplete } from '@material-ui/lab'
import AutoCompleteInputText from './AutoCompleteInputText'
import { S } from '../RecruiterProfileController.web'
import { getCommaSeparatedValue, handleKeyboardScroll, handleScroll } from '../../../../../packages/components/src/Utils.web'
interface Props {
    handleChangeChatBotField: (value: string, fieldName: string, cId?: string) => void;
    state: S;
    companyListHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCloseModal: (value: boolean) => void;
    handleLastQuestion: (value: boolean) => void;
    shouldShow: (condition: boolean) => boolean;
    addDealDetailsFromChat: () => void;
    handleKeyDown: (name: string, value: boolean) => void;
}

const stylesList = makeStyles((theme: Theme) =>
    createStyles({
        experienceBtnContainer: {
            borderRadius: "26px",
            border: "1px solid #1F497D",
            color: "#1F497D",
            padding: "6px 12px",
            fontWeight: "lighter",
            fontFamily: "Arial",
            margin: "5px",
            cursor: "pointer",
            fontSize: "14px"
        },
        root: {
            position: 'relative',
            borderRadius: "12px",
            alignItems: 'center',
            display: 'flex',
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.23) !important",
                border: "px solid #9C9C9C !important",
                "&:hover": {
                    border: "2px solid #9C9C9C !important",
                    borderColor: "#9C9C9C !important",
                },
                "&:focus": {
                    border: "2px solid #9C9C9C !important",
                    borderColor: "#9C9C9C !important",
                },
            },
            "& .MuiOutlinedInput-root": {
                height: "44px",
            },
            "& .MuiFormHelperText-contained": {
                marginLeft: '0px'
            },

            "& .MuiInputBase-input": {
                height: "10px"
            },
            "& .MuiOutlinedInput-input": {
                borderRadius: "12px",
                background: "#FFF",
                border: "0px solid #9C9C9C",
                color: "#6D97C1",
                "fontSize": "14px",
                "fontFamily": "Arial",
                "fontWeight": "lighter",
                "fontStyle": "normal",
                "lineHeight": "22px",
                "paddingLeft": "2px",
                "&:focus": {
                    border: "0px solid #9C9C9C !important",
                },
                "&::placeholder": {
                    "fontSize": "14px",
                    "color": "#64748B",
                    "fontFamily": "Arial",
                    "fontStyle": "normal",
                    "lineHeight": "22px",
                    "fontWeight": "lighter",
                },
                "&:hover": {
                    border: "0px solid #9C9C9C !important",
                },

            },
        },
        skillButton: {
            borderRadius: "26px",
            padding: "6px 12px",
            border: "1px solid #1F497D",
            color: "#FFFFFF",
            fontFamily: "Arial",
            background: "#1F497D",
            fontWeight: "lighter",
            fontSize: "14px",
            margin: "5px",
            cursor: "pointer",
        },
        experienceBtnContainerSelected: {
            borderRadius: "26px",
            color: "#FFFFFF",
            fontSize: "14px",
            margin: "5px",
            fontFamily: "Arial",
            fontWeight: "lighter",
            padding: "6px 12px",
            background: "#1F497D",
            cursor: "pointer",
            border: "1px solid #1F497D",
        },
        pressEnter: {
            fontSize: "12px",
            fontFamily: "Arial",
            color: "grey",
            padding: "14px 25px"
        }

    })
);

const ChatBotFormDeal = (props: Props) => {

    let {
        handleChangeChatBotField,
        companyListHandler,
        handleCloseModal,
        handleLastQuestion,
        shouldShow,
        addDealDetailsFromChat,
        handleKeyDown,
        state: {
            isLastQuestionEntered,
            companyList,
            chatBotDealFields: {
                type,
                involved,
                buyOrSell,
                targetName,
                buyerORInvestorName,
                briefSummory,
                enterPriceValue,
                stackPercentage,
                valuationMethod,
                valuationMultiple,
                mainContribution,
                persPerspectiveMarite,
                riskPerspective,
                debtBorrowing,
                debtBorrowsideORLendingSide,
                debtTargetName,
                debtLenderName,
                debtSummory,
                debtUSD,
                debtGross,
                debtInterest
            },
            chatBotDealFieldsToShow: {
                showbriefSummory,
                showenterPriceValue,
                showstackPercentage,
                showvaluationMethod,
                showvaluationMultiple,
                showmainContribution,
                showpersPerspectiveMarite,
                showriskPerspective,
                showdebtBorrowsideORLendingSide,
                showdebtTargetName,
                showdebtUSD,
                showdebtGross,
                showdebtInterest,
                showbuyOrSell,
                showdebtLenderName,
                showdebtSummory
            },
            loaderButton
        }
    } = props;

    let styles = stylesList()

    let component = (renderComponent: boolean, condition: boolean, component: JSX.Element, question: JSX.Element, answer: string) => {

        if (condition) {
            if (renderComponent) {
                return component
            }
            return <></>
        } else {
            return <>
                <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                    <span style={{ alignItems: "center", display: "flex", textAlign: "start", background: "#F8FAFC", width: "auto", padding: "18px", borderRadius: "12px 12px 12px 0px", }}>
                        <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>{question}</span>
                    </span>
                </Grid>
                <Grid item sm={12} style={{ justifyContent: "flex-end", padding: "14px 25px 0px 25px", display: "flex", "whiteSpace": "pre-wrap", "wordBreak": "break-all" }}>
                    <span style={{ textAlign: "start", background: "rgba(109, 151, 193, 0.2)", padding: "18px", borderRadius: "12px 12px 0px 12px", width: "auto", display: "flex", alignItems: "center", }}>
                        <span style={{ fontWeight: "lighter", color: "#1F497D", fontFamily: "Arial", fontSize: '14px' }}>{answer}</span>
                    </span >
                </Grid>
            </>
        }

    }

    let briefSummoryConditionOne = () => (showbriefSummory && !!buyerORInvestorName);
    let enterpriceConditionOne = () => (showenterPriceValue && !!briefSummory);
    let stackPercentageConditionOne = () => (showstackPercentage && !!enterPriceValue);
    let valuationMethodConditionOne = () => (showvaluationMethod && !!stackPercentage);
    let valuationMultipleConditionOne = () => (showvaluationMultiple && !!valuationMethod);
    let debtBorrowsideORLendingSideConditionOne = () => (showdebtBorrowsideORLendingSide && !!debtBorrowing);
    let debttargetNameConditionOne = () => (showdebtTargetName && !!debtBorrowsideORLendingSide);
    let debtUSDConditionOne = () => (showdebtUSD && !!debtSummory);
    let debtGrossConditionOne = () => (showdebtGross && !!debtUSD);
    let debtInterestConditionOne = () => (showdebtInterest && !!debtGross);
    let showpersPerspectiveMariteConditionone = () => (showmainContribution && (!!valuationMultiple || !!debtInterest));
    let showriskPerspectiveConditionOne = () => (showpersPerspectiveMarite && (mainContribution.length > 0));
    let riskConditionOne = () => (showriskPerspective && !!persPerspectiveMarite);

    let keyDownBriefSummory = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter'  && !event.shiftKey) {
            handleKeyDown("showenterPriceValue", true)
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("enterprice")!.focus();
            }, 1000);
        } 
    }

    let keyDownEnterprice = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleKeyDown("showstackPercentage", true)
            setTimeout(() => {
                document.getElementById("stackPercentage")!.focus();
            }, 1000);
        } 
    }

    let keyDownStackPercentage = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleKeyDown("showvaluationMethod", true)
        } 
    }


    let keyDownDebtSummory = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleKeyDown("showdebtUSD", true)
            setTimeout(() => {
                document.getElementById("debtUSD")!.focus();
            }, 1000);
        } 
    }

    let keyDownDebtUSD = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleKeyDown("showdebtGross", true)
            setTimeout(() => {
                document.getElementById("debtGross")!.focus();
            }, 1000);
        } 
    }

    let keyDownDebtGross = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleKeyDown("showdebtInterest", true)
            setTimeout(() => {
                document.getElementById("debtInterest")!.focus();
            }, 1000);
        } 
    }

    let keyDownDebtInterest = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleKeyDown("showmainContribution", true)
        } 
    }

    let keyDownPerspective = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleKeyDown("showriskPerspective", true)
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("risk")!.focus();
            }, 1000);
        } 
    }

    let keyDownRisk = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleLastQuestion(true)
        }
    }

    return (
        <div 
        id="chat-bot-one-modal"
        tabIndex={0}
        onKeyDown={(event) => handleKeyboardScroll(event, "chat-bot-one-modal")}
        style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh", margin: "0px", padding: "0px", width: "100%", }}>
            <style>
                {
                    `.MuiAutocomplete-option:hover {
                            border-radius: 8px !important;
                            background-color:  #1F497D !important;
                            color: #FFFFFF !important;
                            
                    }`
                }
            </style>
            <div>
                <Grid item sm={12} style={{ justifyContent: "space-between", width: "100%", display: "flex", alignItems: "center", padding: "14px 25px 0px 25px" }}>
                    <img src={logoModal} style={{ height: "55px", width: "55px" }} />
                    <svg width="14" xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }} height="14" viewBox="0 0 14 15" fill="none" id="close-modal" onClick={() => handleCloseModal(false)}>
                        <path fill="#334155" d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z" />
                    </svg>
                </Grid>
                <Grid item sm={12} style={{ display: "flex", flexDirection: "column", padding: "30px 25px 0px 25px" }}>
                    <span style={{ background: "#F8FAFC", padding: "18px 18px 0px 18px", borderRadius: "12px 12px 12px 0px", display: "flex", alignItems: "center", textAlign: "start", flexDirection: "column",}}>
                        <span data-test-id="prompt-one" style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>
                            Welcome to the Deal / Transaction Experience Survey! Whether you were directly involved in a transaction or have a deep understanding from an external perspective, your insights are valuable. Let's get started. You can say 'stop' at any time to pause the survey.
                        </span>
                        &nbsp;
                        &nbsp;
                        <span data-test-id="prompt-two" style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Recruiters and potential employers are likely to utilize this information in your future interviews, so we recommend providing accurate details only for transactions or deals about which you have extensive knowledge, as you will be assessed based on them.</span>
                    </span>
                </Grid>
                {
                    component(
                        true,
                        !(shouldShow(!!type)),
                        <>
                            <Grid item sm={12} style={{ padding: "30px 25px 0px 25px", display: "flex" }}>
                                <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px" }}>
                                    <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Were you involved on the Equity side (M&A, IPO, private placement, etc.) or the Debt side?</span>
                                </span>
                            </Grid>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                                {
                                    ["Equity", "Debt"].map(value => <Box id="type-btn" className={type === value ? styles.experienceBtnContainerSelected : styles.experienceBtnContainer} onClick={() => handleChangeChatBotField(value, "type")}>
                                        <Typography>{value}</Typography>
                                    </Box>)
                                }
                            </Grid>
                        </>,
                        <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Were you involved on the Equity side (M&A, IPO, private placement, etc.) or the Debt side?</span>,
                        type
                    )
                }
                {
                    component(
                        !!type,
                        !(shouldShow(!!involved)),
                        <>
                            <Grid item sm={12} style={{ padding: "30px 25px 0px 25px", display: "flex" }}>
                                <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px" }}>
                                    <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Were you <u>directly involved</u> in this transaction, or <u>highly familiar from an outside-in</u> perspective?</span>
                                </span>
                            </Grid>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                                {
                                    ["Directly involved", "Highly familiar from an outside perspective"].map(value => <Box id="involved-btn" className={involved === value ? styles.experienceBtnContainerSelected : styles.experienceBtnContainer} onClick={() => handleChangeChatBotField(value, "involved")}>
                                        <Typography>{value}</Typography>
                                    </Box>)
                                }
                            </Grid>
                        </>,
                        <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Were you <u>directly involved</u> in this transaction, or <u>highly familiar from an outside-in</u> perspective?</span>,
                        involved
                    )
                }
                {
                    type === "Equity" ? <>
                        {
                            component(
                                (!!involved),
                                !(shouldShow(!!buyOrSell)),
                                <>
                                    <Grid item sm={12} style={{ padding: "30px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Were you on the <span style={{ fontWeight: "bold" }}>Sell-side</span> (Target company and its advisors) or the <span style={{ fontWeight: "bold" }}>Buy-side</span> (Buyer/Investor/Bidder and its advisors)?</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                                        {
                                            ["Sell-side, working at the Target", "Sell-side, with a firm advising the Target", "Buy-side, working at the Buyer", "Buy-side, with a firm advising the Buyer"].map(value => <Box id="buyOrSell-btn" className={buyOrSell === value ? styles.experienceBtnContainerSelected : styles.experienceBtnContainer} onClick={() => {
                                                handleChangeChatBotField(value, "buyOrSell");
                                                setTimeout(() => {
                                                    document.getElementById("combo-box-demo")?.focus();
                                                }, 1000);
                                            }}>
                                                <Typography>{value}</Typography>
                                            </Box>)
                                        }
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Were you on the <span style={{ fontWeight: "bold" }}>Sell-side</span> (Target company and its advisors) or the <span style={{ fontWeight: "bold" }}>Buy-side</span> (Buyer/Investor/Bidder and its advisors)?</span>,
                                buyOrSell
                            )
                        }
                        {
                            component(
                                !!buyOrSell,
                                !(shouldShow(!!targetName)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please provide the <span style={{ fontWeight: "bold" }}>Target name</span> in this transaction.</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={companyList}
                                            getOptionLabel={(option) => option ? option : ""}
                                            value={targetName}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    handleScroll()
                                                    handleChangeChatBotField(value, "targetName")
                                                    handleKeyDown("showbuyOrSell", true);
                                                }
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    handleScroll()
                                                    handleKeyDown("showbuyOrSell", true);
                                                    setTimeout(() => {
                                                        document.getElementById("combo-box-demo-2")!.focus();
                                                    }, 1000);
                                                }
                                            }}
                                            renderInput={(params) => <AutoCompleteInputText
                                                errors={{}}
                                                title=""
                                                name="targetName"
                                                placeHolder="Company"
                                                params={params}
                                                touched={false}
                                                borderRadius={"12px"}
                                                onChange={(event) =>{
                                                    handleChangeChatBotField(event.target.value, "targetName");
                                                    companyListHandler(event)
                                                }}
                                            />
                                            }
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please provide the <span style={{ fontWeight: "bold" }}>Target name</span> in this transaction.</span>,
                                targetName
                            )
                        }
                        {
                            component(
                                (!!targetName && showbuyOrSell),
                                !(shouldShow(!!buyerORInvestorName)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please provide the <span style={{ fontWeight: "bold" }}>Buyer / Investor name</span> in this transaction.</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <Autocomplete
                                            id="combo-box-demo-2"
                                            options={companyList}
                                            getOptionLabel={(option) => option ? option : ""}
                                            value={buyerORInvestorName}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    handleScroll()
                                                    handleChangeChatBotField(value, "buyerORInvestorName")
                                                    handleKeyDown("showbriefSummory", true)
                                                }
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    handleScroll()
                                                    handleKeyDown("showbriefSummory", true)
                                                    setTimeout(() => {
                                                        document.getElementById("briefSummory")!.focus();
                                                    }, 1000);
                                                }
                                            }}
                                            renderInput={(params) => <AutoCompleteInputText
                                                errors={{}}
                                                title=""
                                                name="buyerORInvestorName"
                                                placeHolder="Company"
                                                params={params}
                                                touched={false}
                                                borderRadius={"12px"}
                                                onChange={(event) => {
                                                    handleChangeChatBotField(event.target.value, "buyerORInvestorName");
                                                    companyListHandler(event)
                                                }}
                                            />
                                            }
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please provide the <span style={{ fontWeight: "bold" }}>Buyer / Investor name</span> in this transaction.</span>,
                                buyerORInvestorName
                            )
                        }
                        {
                            component(
                                briefSummoryConditionOne(),
                                !(shouldShow(!!briefSummory)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please provide a brief summary based on your understanding of the transaction.</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <TextField
                                            fullWidth
                                            id="briefSummory"
                                            name="briefSummory"
                                            type='string'
                                            multiline
                                            variant="outlined"
                                            value={briefSummory}
                                            onKeyDown={keyDownBriefSummory}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ classes: { root: styles.root }, inputComponent: TextareaAutosize }}
                                            placeholder="Type Message Here"
                                            onChange={(event) => handleChangeChatBotField(event.target.value, "briefSummory")}
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please provide a brief summary based on your understanding of the transaction.</span>,
                                briefSummory
                            )
                        }
                        {
                            component(
                                enterpriceConditionOne(),
                                !(shouldShow(!!enterPriceValue)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the Enterprise Value/headline number of the Target in the transaction (in Thousand)?</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <TextField
                                            fullWidth
                                            id="enterprice"
                                            name="enterprice"
                                            type='string'
                                            variant="outlined"
                                            value={getCommaSeparatedValue(enterPriceValue)}
                                            onKeyDown={keyDownEnterprice}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: { root: styles.root },
                                                startAdornment: <Typography style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D", paddingRight: "4px" }}>USD</Typography>,
                                                endAdornment: <Typography style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Thousand(s)</Typography>
                                            }}
                                            placeholder="50000"
                                            onChange={(event) => handleChangeChatBotField(event.target.value, "enterPriceValue")}
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the Enterprise Value/headline number of the Target in the transaction (in Thousand)?</span>,
                                enterPriceValue
                            )
                        }
                        {
                            component(
                                stackPercentageConditionOne(),
                                !(shouldShow(!!stackPercentage)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the percentage stake of the Target that was transacted (in %)?</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <TextField
                                            fullWidth
                                            id="stackPercentage"
                                            name="stackPercentage"
                                            type='string'
                                            variant="outlined"
                                            value={stackPercentage}
                                            onKeyDown={keyDownStackPercentage}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: { root: styles.root },
                                                endAdornment: <Typography style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>%</Typography>
                                            }}
                                            placeholder="15"
                                            onChange={(event) => handleChangeChatBotField(event.target.value, "stackPercentage")}
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the percentage stake of the Target that was transacted (in %)?</span>,
                                stackPercentage
                            )
                        }
                        {
                            component(
                                valuationMethodConditionOne(),
                                !(shouldShow(!!valuationMethod)),
                                <>
                                    <Grid item sm={12} style={{ padding: "30px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the valuation method used in the transaction (please note that you will be asked to share the valuation multiple in the next question)?</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                                        {
                                            ["EV / Revenue", "EV / EBITDA", "P / B", "P / E", "I don't know"]
                                                .map(value => <Box id="valuationMethod-btn" className={valuationMethod === value ? styles.experienceBtnContainerSelected : styles.experienceBtnContainer} onClick={() => {
                                                    handleChangeChatBotField(value, "valuationMethod")
                                                    handleKeyDown("showvaluationMultiple", true)
                                                    setTimeout(() => {
                                                        document.getElementById("valuationMultiple")!.focus();
                                                    }, 1000);
                                                }}>
                                                    <Typography>{value}</Typography>
                                                </Box>)
                                        }
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the valuation method used in the transaction (please note that you will be asked to share the valuation multiple in the next question)?</span>,
                                valuationMethod
                            )
                        }
                        {
                            component(
                                valuationMultipleConditionOne(),
                                !(shouldShow(!!valuationMultiple)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Kindly share the valuation multiple.</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <TextField
                                            fullWidth
                                            id="valuationMultiple"
                                            name="valuationMultiple"
                                            type='string'
                                            variant="outlined"
                                            value={valuationMultiple}
                                            onKeyDown={keyDownDebtInterest}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: { root: styles.root },
                                                endAdornment: <Typography style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>X</Typography>
                                            }}
                                            placeholder="20"
                                            onChange={(event) => handleChangeChatBotField(event.target.value, "valuationMultiple")}
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Kindly share the valuation multiple.</span>,
                                valuationMultiple
                            )
                        }
                    </> : <>
                        {
                            component(
                                (!!involved),
                                !(shouldShow(!!debtBorrowing)),
                                <>
                                    <Grid item sm={12} style={{ padding: "30px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Was it a new debt/borrowing or a refinancing?</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                                        {
                                            ["New debt", "Refinancing"]
                                                .map(value => <Box id="debtBorrowing-btn" className={debtBorrowing === value ? styles.experienceBtnContainerSelected : styles.experienceBtnContainer} onClick={() => {
                                                    handleChangeChatBotField(value, "debtBorrowing")
                                                    handleKeyDown("showdebtBorrowsideORLendingSide", true)
                                                }}>
                                                    <Typography>{value}</Typography>
                                                </Box>)
                                        }
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Was it a new debt/borrowing or a refinancing?</span>,
                                debtBorrowing
                            )
                        }
                        {
                            component(
                                debtBorrowsideORLendingSideConditionOne(),
                                !(shouldShow(!!debtBorrowsideORLendingSide)),
                                <>
                                    <Grid item sm={12} style={{ padding: "30px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Were you on the <span style={{ fontWeight: "bold" }}>Borrowing-side</span> (Borrower/Target company and its advisors) or the <span style={{ fontWeight: "bold" }}>Lending-side</span> (Banks/Credit Funds/Special Situation Fund/other creditors and its advisors)?</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                                        {
                                            [
                                                "Borrowing-side, I was working at the Target directly",
                                                "Borrowing-side, I was with a firm that advised/supported the Target (e.g. financial advisor, vendor due diligence, other advisors, etc.)",
                                                "Lending-side, I was working at the Banks/Credit Funds/Special Situation Fund /other creditors",
                                                "Lending-side, I was with a firm that advised/supported the Banks/Credit Funds/Special Situation Fund/other creditors (e.g. financial advisor, due diligence, other advisors, etc.)"
                                            ]
                                                .map(value => <Box id="debtBorrowsideORLendingSide-btn" className={debtBorrowsideORLendingSide === value ? styles.experienceBtnContainerSelected : styles.experienceBtnContainer} onClick={() => {
                                                    handleChangeChatBotField(value, "debtBorrowsideORLendingSide")
                                                    handleKeyDown("showdebtTargetName", true)
                                                    setTimeout(() => {
                                                        document.getElementById("combo-box-demo-3")!.focus();
                                                    }, 1000);
                                                }}>
                                                    <Typography>{value}</Typography>
                                                </Box>)
                                        }
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Were you on the <span style={{ fontWeight: "bold" }}>Borrowing-side</span> (Borrower/Target company and its advisors) or the <span style={{ fontWeight: "bold" }}>Lending-side</span> (Banks/Credit Funds/Special Situation Fund/other creditors and its advisors)?</span>,
                                debtBorrowsideORLendingSide
                            )
                        }
                        {
                            component(
                                debttargetNameConditionOne(),
                                !(shouldShow(!!debtTargetName)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please provide the <span style={{ fontWeight: "bold" }}>Borrower / Target name</span> in this transaction.</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <Autocomplete
                                            id="combo-box-demo-3"
                                            options={companyList}
                                            getOptionLabel={(option) => option ? option : ""}
                                            value={debtTargetName}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    handleScroll()
                                                    handleChangeChatBotField(value, "debtTargetName")
                                                    handleKeyDown("showdebtLenderName", true)
                                                }
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    handleScroll()
                                                    handleKeyDown("showdebtLenderName", true)
                                                    setTimeout(() => {
                                                        document.getElementById("combo-box-demo-4")!.focus();
                                                    }, 1000);
                                                }
                                            }}
                                            renderInput={(params) => <AutoCompleteInputText
                                                errors={{}}
                                                title=""
                                                name="debtTargetName"
                                                placeHolder="Company"
                                                params={params}
                                                touched={false}
                                                borderRadius={"12px"}
                                                onChange={(event) => {
                                                    handleChangeChatBotField(event.target.value, "debtTargetName");
                                                    companyListHandler(event)
                                                }}
                                            />
                                            }
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please provide the <span style={{ fontWeight: "bold" }}>Borrower / Target name</span> in this transaction.</span>,
                                debtTargetName
                            )
                        }
                        {
                            component(
                                (!!debtTargetName && showdebtLenderName),
                                !(shouldShow(!!debtLenderName)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please provide the <span style={{ fontWeight: "bold" }}>Lender name</span> in this transaction.</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <Autocomplete
                                            id="combo-box-demo-4"
                                            options={companyList}
                                            getOptionLabel={(option) => option ? option : ""}
                                            value={debtLenderName}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    handleScroll()
                                                    handleChangeChatBotField(value, "debtLenderName")
                                                    handleKeyDown("showdebtSummory", true)
                                                }
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    handleScroll()
                                                    handleKeyDown("showdebtSummory", true)
                                                    setTimeout(() => {
                                                        document.getElementById("debtSummory")!.focus();
                                                    }, 1000);
                                                }
                                            }}
                                            renderInput={(params) => <AutoCompleteInputText
                                                errors={{}}
                                                title=""
                                                name="debtLenderName"
                                                placeHolder="Company"
                                                params={params}
                                                touched={false}
                                                borderRadius={"12px"}
                                                onChange={(event) => {
                                                    handleChangeChatBotField(event.target.value, "debtLenderName");
                                                    companyListHandler(event)}
                                                }
                                            />
                                            }
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please provide the <span style={{ fontWeight: "bold" }}>Lender name</span> in this transaction.</span>,
                                debtLenderName
                            )
                        }
                        {
                            component(
                                (!!debtLenderName && showdebtSummory),
                                !(shouldShow(!!debtSummory)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Could you please provide your transaction summary?</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <TextField
                                            fullWidth
                                            id="debtSummory"
                                            name="debtSummory"
                                            multiline
                                            type='string'
                                            variant="outlined"
                                            value={debtSummory}
                                            onKeyDown={keyDownDebtSummory}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: { root: styles.root },
                                            }}
                                            placeholder="Please enter your transaction summary"
                                            onChange={(event) => handleChangeChatBotField(event.target.value, "debtSummory")}
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Could you please provide your transaction summary?</span>,
                                debtSummory
                            )
                        }
                        {
                            component(
                                debtUSDConditionOne(),
                                !(shouldShow(!!debtUSD)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the debt size/headline number in the transaction (in Thousand)?</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <TextField
                                            fullWidth
                                            id="debtUSD"
                                            name="debtUSD"
                                            type='string'
                                            variant="outlined"
                                            value={getCommaSeparatedValue(debtUSD)}
                                            onKeyDown={keyDownDebtUSD}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: { root: styles.root },
                                                startAdornment: <Typography style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D", paddingRight: "4px" }}>USD</Typography>,
                                                endAdornment: <Typography style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Thousand(s)</Typography>
                                            }}
                                            placeholder="20"
                                            onChange={(event) => handleChangeChatBotField(event.target.value, "debtUSD")}
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the debt size/headline number in the transaction (in Thousand)?</span>,
                                debtUSD
                            )
                        }
                        {
                            component(
                                debtGrossConditionOne(),
                                !(shouldShow(!!debtGross)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the leverage level in terms of Gross Debt/EBITDA (in x)?</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <TextField
                                            fullWidth
                                            id="debtGross"
                                            name="debtGross"
                                            type='string'
                                            variant="outlined"
                                            value={debtGross}
                                            onKeyDown={keyDownDebtGross}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: { root: styles.root },
                                                endAdornment: <Typography style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>X</Typography>
                                            }}
                                            placeholder="20"
                                            onChange={(event) => handleChangeChatBotField(event.target.value, "debtGross")}
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the leverage level in terms of Gross Debt/EBITDA (in x)?</span>,
                                debtGross
                            )
                        }
                        {
                            component(
                                debtInterestConditionOne(),
                                !(shouldShow(!!debtInterest)),
                                <>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the all-in interest rate in the transaction?</span>
                                        </span>
                                    </Grid>
                                    <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                        <TextField
                                            fullWidth
                                            id="debtInterest"
                                            name="debtInterest"
                                            type='string'
                                            variant="outlined"
                                            value={debtInterest}
                                            onKeyDown={keyDownDebtInterest}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: { root: styles.root },
                                                endAdornment: <Typography style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>%</Typography>
                                            }}
                                            placeholder="20"
                                            onChange={(event) => handleChangeChatBotField(event.target.value, "debtInterest")}
                                        />
                                    </Grid>
                                </>,
                                <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What was the all-in interest rate in the transaction?</span>,
                                debtInterest
                            )
                        }
                    </>
                }
                {
                    component(
                        showpersPerspectiveMariteConditionone(),
                        !(shouldShow(!!mainContribution)),
                        <>
                            <Grid item sm={12} style={{ padding: "30px 25px 0px 25px", display: "flex" }}>
                                <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px" }}>
                                    <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please select your main contribution (s) in this transaction. Kindly note that recruiters and the hiring manager may ask for further details during the interviews.</span>
                                </span>
                            </Grid>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                                {
                                    ["Financial modelling", "Ratings/creditworthiness analysis", "Commercial due diligences", "Financial due diligence", "Tax due diligence", "Legal due diligence", "Transaction structuring", "Preparation of memorandum for investment committee approval", "Roadshow", "Other"]
                                        .map(value => <Box id="mainContribution-btn" className={mainContribution.includes(value) ? styles.experienceBtnContainerSelected : styles.experienceBtnContainer} onClick={() => {
                                            handleChangeChatBotField(value, "mainContribution")
                                            handleKeyDown("showpersPerspectiveMarite", true);
                                            setTimeout(() => {
                                                document.getElementById("perspective")!.focus();
                                            }, 1000);
                                        }}>
                                            <Typography>{value}</Typography>
                                        </Box>)
                                }
                            </Grid>
                        </>,
                        <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Please select your main contribution (s) in this transaction. Kindly note that recruiters and the hiring manager may ask for further details during the interviews.</span>,
                        mainContribution.join(", ")
                    )
                }
                {
                    component(
                        showriskPerspectiveConditionOne(),
                        !(shouldShow(!!persPerspectiveMarite)),
                        <>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                    <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What, in your perspective, are the merits/rationale of the transaction?</span>
                                </span>
                            </Grid>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                <TextField
                                    fullWidth
                                    id="perspective"
                                    name="perspective"
                                    type='string'
                                    variant="outlined"
                                    multiline
                                    value={persPerspectiveMarite}
                                    onKeyDown={keyDownPerspective}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ classes: { root: styles.root }, inputComponent: TextareaAutosize }}
                                    placeholder="Type Message Here"
                                    onChange={(event) => handleChangeChatBotField(event.target.value, "persPerspectiveMarite")}
                                />
                            </Grid>
                        </>,
                        <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What, in your perspective, are the merits/rationale of the transaction?</span>,
                        persPerspectiveMarite
                    )
                }
                {
                    component(
                        riskConditionOne(),
                        !(shouldShow(true)),
                        <>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                                <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                                    <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What are the potential risks of the transaction from your perspective?</span>
                                </span>
                            </Grid>
                            <Grid item sm={12} style={{ padding: "14px 25px 0px 25px" }}>
                                <TextField
                                    fullWidth
                                    id="risk"
                                    name="risk"
                                    type='string'
                                    variant="outlined"
                                    value={riskPerspective}
                                    multiline
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ classes: { root: styles.root }, inputComponent: TextareaAutosize }}
                                    placeholder="Type Message Here"
                                    onChange={(event) => {
                                        handleChangeChatBotField(event.target.value, "riskPerspective")
                                    }}
                                    onKeyDown={keyDownRisk}
                                />
                            </Grid>
                        </>,
                        <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>What are the potential risks of the transaction from your perspective?</span>,
                        riskPerspective
                    )
                }
                {riskPerspective && !shouldShow(true) && <Typography id="helper-text" className={styles.pressEnter}>Press Enter to complete</Typography>}
                {
                    (shouldShow(true)) && <Grid item sm={12} style={{ padding: "14px 25px 0px 25px", display: "flex" }}>
                        <span style={{ display: "flex", alignItems: "center", textAlign: "start", background: "#F8FAFC", padding: "18px", borderRadius: "12px 12px 12px 0px", width: "auto" }}>
                            <span style={{ fontFamily: "Arial", fontSize: '14px', fontWeight: "lighter", color: "#17365D" }}>Thank you for sharing your transaction experience. Your insights are invaluable and contribute to a comprehensive understanding of transaction dynamics. If you wish to add another transaction experience or have any other questions, you can still edit the submitted responses in your profile page!</span>
                        </span>
                    </Grid>
                }
            </div>
            <div>
                <Divider style={{ width: "100%", height: "1px", background: "#E2E8F0", marginTop: "35px" }} />
                <Grid container sm={12} style={{ padding: "15px 25px", justifyContent: "end", display: "flex" }}>
                    <Button
                        id="target-button"
                        disabled={(!riskPerspective || !isLastQuestionEntered || loaderButton)}
                        onClick={() => addDealDetailsFromChat()}
                        style={{
                            color: "#FFF",
                            background: "#1F497D",
                            padding: "12px 28px",
                            borderRadius: "8px",
                            textTransform: "none",
                            opacity: (!riskPerspective || !isLastQuestionEntered) ? 0.6 : 1
                        }}>
                        Save
                    </Button>
                </Grid>
            </div>
        </div >
    )
}

export default ChatBotFormDeal;