import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordVisible, invitationImage, connectionIcon, InvitationIcon, followersIcon } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { Platform } from "react-native";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import { handleTwoValues } from "../../../../packages/components/src/Utils.web";
export interface DataListItem {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    email: string;
    is_follow: string;
  };
}

export interface DataListItemTwo {
  id: string;
  attributes: {
    account_email: string;
    email: string;
    current_user_id: string;
    account_id: string;
  };
}

export interface DataListItemThree {
  id: string;
  attributes: {
    account_email: string;
    email: string;
    current_user_id: string;
    account_id: string;
    user_name: string;
    is_follow: boolean;
  };
}

export interface DataListItemForReceive {
  attributes: {
    id: number,
    note: string,
    mutual_connections: {
      data: {
        attributes: {
          profile_details: {
            data: {
              attributes: {
                photo: string, full_name: string, current_title: string, current_company: string
              }
            }
          }
        }
      }[]
    },
    sender_details: {
      data: {
        attributes: {
          role: string,
          profile_details: {
            data: {
              attributes: {
                photo: string, full_name: string, current_title: string, current_company: string, company_name: string, 
                account_details: { id: string, role: string }
              },
              id: string
            }
          }
        }
      }
    }
  }
}

export interface DataListItemForSent {
  attributes: {
    id: number,
    note: string,
    mutual_connections: {
      data: {
        attributes: {
          profile_details: {
            data: {
              attributes: {
                photo: string, full_name: string, current_title: string, current_company: string,
                account_details: { id: string, role: string }
              }
            }
          }
        }
      }[]
    }
    receiver_details: {
      data: {
        attributes: {
          role: string,
          profile_details: {
            data: {
              attributes: {
                photo: string, full_name: string, current_title: string, current_company: string, company_name: string,
                account_details: { id: string, role: string }
              },
              id: string
            }
          }
        }
      }
    }
  }
}

export interface DataListItemForFollower {
  attributes: {
    account_email: string;
    email: string;
    current_user_id: string;
    account_id: string;
  }
}

export interface DataListItemForFollowerForWeb {
  attributes: {
    posts_created_this_week: string,
    profile_details: {
      data: {
        attributes: {
          photo: string,
          full_name: string, 
          current_title: string, 
          current_company: string, 
          company_name: string,
          account_details: { id: string, role: string }
        }
      }
    }
  }
}

export interface DataListItemForFollowing {
  attributes: {
    account_email: string;
    email: string;
    current_user_id: string;
  }
}

export interface DataListItemForFollowingForWeb {
  attributes: {
    id: string,
    is_followed: boolean,
    posts_created_this_week: string,
    profile_details:
    {
      data: {
        attributes: {
          id: string,
          photo: string,
          full_name: string,
          current_title: string,
          company_name: string,
          current_company: string, account_details:
          { id: string, role: string }
        }
      }
    }
  }
}

export interface DataListItemForConnection {
  id: string, attributes: {
    id: number,
    current_user_id: string,
    mutual_connections: {
      data: {
        attributes: {
          profile_details: {
            data: {
              attributes: {
                photo: string, full_name: string, current_title: string, current_company: string,
                account_details: {
                  id: number
                }
              }
            }
          }
        }
      }[]
    },
    sender_details: {
      data: {
        id: string,
        attributes: {
          role: string,
          profile_details: {
            data: {
              attributes: {
                role: string,
                photo: string, full_name: string, current_title: string, current_company: string, company_name: string
                account_details: {
                  id: number
                }
              }
            }
          }
        }
      }
    },
    receiver_details: {
      data: {
        id: string,
        attributes: {
          role: string,
          profile_details: {
            data: {
              attributes: {
                photo: string, full_name: string, current_title: string, current_company: string, company_name: string
                account_details: {
                  id: number
                }
              }
            }
          }
        }
      }
    }
  }
}
export interface  profile_details {
  data: {
    attributes: {
      photo: string, full_name: string, current_title: string, current_company: string,
      account_details: {
        id: number
      }
    }
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabPanelNo: number;
  followTab: number;
  listData: DataListItem[];
  followingData: DataListItem[];
  openModal: boolean;
  errorMsg: string;
  openFollowerModel: boolean;
  postsCount: number;
  followersCount: string;
  userList: boolean;
  token: string;
  followerList: boolean;
  loder: boolean;
  followingList: boolean;
  searchTerm: string;
  profilePhoto: string;
  showFollowersScreen: boolean;
  userListData: DataListItemThree[];
  receivedInvitation: DataListItemForReceive[];
  sentInvitations: DataListItemForSent[];
  connectionList: DataListItemForConnection[];
  userFollowerListData: DataListItemForFollower[];
  userFollowerListDataForWeb: DataListItemForFollowerForWeb[];
  userFollowingListData: DataListItemForFollowing[];
  userFollowingListDataFroWeb: DataListItemForFollowingForWeb[];
  ImageArr:{
    image:string,
    name:string,
    text:string,
    position:string
  }[];
  networkNavigationArr: {
    name: string,
    image: string
  }[];
  activeNavTab: number;
  removeOpen: string;
  loading: boolean;
  redirectCahtUserInfo:profile_details | null
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FollowersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  followerCallId: string = "";
  userFollowerCallID: string = "";
  userFollowingCallID: string = "";
  addFollowerFromFollowingCallId: string = "";
  unFollowFromFollowingCallId: string = "";
  invitationReceivedId: string = "";
  connectionListId:string = "";
  invitationSentId: string = "";
  ignorerequestId: string = "";
  acceptFriendRequestId:string = "";
  withdrawRequestId:string = "";
  removeConnectionId:string = "";
  createChatApiCallId: string = "";
  getSubscriptionApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tabPanelNo: 0,
      followTab: 0,
      listData: [],
      followingData: [],
      openModal: false,
      errorMsg: "",
      searchTerm: "",
      showFollowersScreen: true,
      userList: true,
      followerList: false,
      followingList: false,
      loder: false,
      loading: false,
      userFollowerListData: [],
      userFollowerListDataForWeb: [],
      userFollowingListDataFroWeb: [],
      userListData: [],
      userFollowingListData: [],
      followersCount: imgPasswordVisible,
      token: "",
      postsCount: 20,
      profilePhoto: "https://via.placeholder.com/150",
      activeNavTab: 0,
      openFollowerModel: false,
      receivedInvitation: [],
      sentInvitations: [],
      connectionList: [],
      removeOpen:"",
      ImageArr:[
        {
          image:invitationImage,
          name:"Mark Antony",
          text:"Recruiter at X Company",
          position:"George and 2 others"
        },
        {
          image:invitationImage,
          name:"Mark Antony",
          text:"Recruiter at X Company",
          position:"George and 2 others"
        },
        {
          image:invitationImage,
          name:"Mark Antony",
          text:"Recruiter at X Company",
          position:"George and 2 othersk"
        },
        {
          image:invitationImage,
          name:"Mark Antony",
          text:"Recruiter at X Company",
          position:"George and 2 othersk"
        },
      ],
      networkNavigationArr: [
        {
          name: "Invitations",
          image: InvitationIcon
        },
        {
          name: "Connections",
          image: connectionIcon
        },
        {
          name: "Following & Followers",
          image: followersIcon
        }
      ],
      redirectCahtUserInfo:null
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const navBarTitleText = isNaN(+this.props.navigation.getParam("navigationBarTitleText")) ? 0 : +this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ activeNavTab: navBarTitleText });
    await this.getToken();
    this.handleNetworkTab();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForSuggestion(from, message);
    this.handleResForUnFollowFromFollowing(from, message);
    this.handleResponseForAddFromFollowing(from, message);
    this.handleResponseForSuggestionFollowers(from, message);
    this.handleResponseForSuggestionFollowing(from, message);
    this.handleResponseForInvitationRecived(from, message);
    this.handleResponseForInvitationSent(from, message);
    this.handleResponseForConnectionList(from, message);
    this.handleResponseForIgnoreRequest(from, message);
    this.handleResponseForAcceptRequest(from , message);
    this.handleResponseForWithdrawRequest(from, message);
    this.handleResponseForRemoveConnectionRequest(from, message);
    this.handleResponseForSubscriptionRequest(from, message);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if(prevState.activeNavTab !== this.state.activeNavTab ){
      this.handleNetworkTab();
    }
  }

  handleNavChange = (index:number) => {
    this.setState({activeNavTab: index});
  }
  handleremoveConnection =(removeID:string)=>{
   if(Number(removeID) !== Number(this.state.removeOpen)) {
    this.setState({removeOpen:removeID})
   }else {
    this.setState({removeOpen:""})
   }
  }

  renderCompanyName = (role: string, currentTitle: string = "", currentCompany: string = "", companyName: string = "") => {
    if(role === "recruiter") {
      return companyName
    } else if (role === "candidate" && handleTwoValues(currentTitle, currentCompany)) {
      return `${currentTitle} at ${currentCompany}`
    }
  }

  handleConditionRender = (condition: boolean, truePart: object | string | undefined, falsePart: object | string | React.CSSProperties | undefined) => {
    return condition ? truePart : falsePart
  }

  handleNavigate = (recruiterId: number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Profile');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), recruiterId);
    this.send(message);
}


handleMessageClick = (data:profile_details) => {
  this.setState({redirectCahtUserInfo:data});
  this.checkForSubscription()
}

  getToken = async () => {
    const token: string =
      Platform.OS == "android" || Platform.OS == "ios"
        ? await getStorageData("token-access")
        : localStorage.getItem("authToken");
    this.setState({ token: token });
  };

  getListOfSuggestionData = async () => {
    this.setState({ loder: true });
    const token = this.state.token;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const followerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followerCallId = followerDataMessage.messageId;

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListEndPoint
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
  };

  getInvitationRecievedList = async () => {
    const token = this.state.token;
    this.setState({ loder: true, loading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const invitationRecieveData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    invitationRecieveData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.invitationReceivedId = invitationRecieveData.messageId;
    invitationRecieveData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.receivedInvitations
    );
    invitationRecieveData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(invitationRecieveData.id, invitationRecieveData);
  };

  getInvitationSentList = async () => {
    const token = this.state.token;
    this.setState({ loder: true, loading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const invitationSentData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    invitationSentData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.invitationSentId = invitationSentData.messageId;
    invitationSentData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sentInvitations
    );
    invitationSentData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(invitationSentData.id, invitationSentData);
  };

  getConnectionList = async () => {
    const token = this.state.token;
    this.setState({ loder: true, loading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const connectionData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    connectionData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.connectionListId = connectionData.messageId;
    connectionData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.connectionEndPoint
    );
    connectionData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(connectionData.id, connectionData);
  };

  ignoreFriendRequestApiCall = async (connectionId: number) => {
    const token = this.state.token;
    this.setState({ loder: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const ignoreRquestData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    ignoreRquestData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.ignorerequestId = ignoreRquestData.messageId;
    ignoreRquestData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ignoreRquestEndPoint.replace(':connection_request_id', connectionId)
    );
    ignoreRquestData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );
    runEngine.sendMessage(ignoreRquestData.id, ignoreRquestData);
  };

  AcceptFriendRequest = async (acceptId: number) => {
    const token = this.state.token;
    this.setState({ loder: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const accepteRquestData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    accepteRquestData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.acceptFriendRequestId = accepteRquestData.messageId;
    accepteRquestData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accepteRquestEndPoint.replace(':connection_request_id', acceptId)
    );
    accepteRquestData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    runEngine.sendMessage(accepteRquestData.id, accepteRquestData);
  };

  withdrawSentRequest = async (withdrawId: number) => {
    const token = this.state.token;
    this.setState({ loder: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const withdrawRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    withdrawRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.withdrawRequestId = withdrawRequest.messageId;
    withdrawRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.withdrawRequestEndPoint.replace(':connection_request_id', withdrawId)
    );
    withdrawRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );
    runEngine.sendMessage(withdrawRequest.id, withdrawRequest);
  };
  removeConnection = async (removeID: number) => {
    const token = this.state.token;
    this.setState({ loder: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const removeConnection = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    removeConnection.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.removeConnectionId = removeConnection.messageId;
    removeConnection.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeConnectionRequest.replace(':connection_request_id', removeID)
    );
    removeConnection.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );
    runEngine.sendMessage(removeConnection.id, removeConnection);
  };

  getListOfSuggestionDataFollowers = async () => {
    this.setState({loading: true})
    const token = await this.state.token;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const followerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userFollowerCallID = followerDataMessage.messageId;

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListFollowersEndPoint
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
  };

  handleNetworkTab = async () => {
    if(this.state.activeNavTab === 0) {
      this.getInvitationRecievedList();
      this.getInvitationSentList();
    }else if(this.state.activeNavTab === 1) {
      this.getConnectionList();
    }else if(this.state.activeNavTab === 2) {
      await this.getListOfSuggestionDataFollowers();
      await this.getListOfSuggestionDataFollowing();
    }
  }

  getListOfSuggestionDataFollowing = async () => {
    const token = await this.state.token;
    this.setState({loading: true})
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const followerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userFollowingCallID = followerDataMessage.messageId;

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListFollowingEndPoint
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    followerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
  };

  addFromFollowingApiCall = async (account_id: string) => {
    this.setState({ loder: true });
    const token = this.state.token;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const httpBody = {
      "data": {
        "type": "follow",
        "attributes": {
          "account_id": 321
        }
      }
    };
    const addFollowerDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFollowerFromFollowingCallId = addFollowerDataMessage.messageId;
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addFollowersApiEndPoint
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    addFollowerDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);
  };

  unFollowFromFollowingApiCall = async (account_id: string) => {
    this.setState({ loder: true });
    const token = this.state.token;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const unFollowDataUserMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unFollowFromFollowingCallId = unFollowDataUserMessage.messageId;
    unFollowDataUserMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unFollowApisApiEndPoint}/${account_id}`
    );
    unFollowDataUserMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    unFollowDataUserMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.deleteApiMethodType}`
    );
    runEngine.sendMessage(unFollowDataUserMessage.id, unFollowDataUserMessage);
  };

  followingOnPress = () => {
    this.setState({
      userList: false,
      followerList: false,
      followingList: true,
    });
  };

  followerListOnPressClick = () => {
    this.setState({
      userList: false,
      followerList: true,
      followingList: false,
    });
  };

  userListOnPress = () => {
    this.setState({
      userList: true,
      followerList: false,
      followingList: false,
    });
  };

  handleResponseForSuggestion = (from: string, message: Message) => {
    if (
      this.followerCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });
      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse);
      } else {
        this.setState({ userListData: apiResponse.data });
      }
    }
  };
  handleResponseForInvitationRecived = (from: string, message: Message) => {
    if (
      this.invitationReceivedId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });
      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse)
      } else {
        this.setState({ receivedInvitation: apiResponse.data, loading: false });
        window.dispatchEvent(new CustomEvent("UPDATE_NETWORK_COUNT", {detail: {counts: apiResponse.data.length}}))
      }
    }
  };
  handleResponseForInvitationSent = (from: string, message: Message) => {
    if (
      this.invitationSentId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });
      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse)
      } else {
        this.setState({ sentInvitations: apiResponse.data, loading: false });
      }
    }
  };
  handleResponseForConnectionList = (from: string, message: Message) => {
    if (
      this.connectionListId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });
      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse)
      } else {
        this.setState({ connectionList: apiResponse.data, loading: false });
      }
    }
  };
  handleResponseForIgnoreRequest = (from: string, message: Message) => {
    if (
      this.ignorerequestId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });
      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse)
      } else {
        this.getInvitationRecievedList();
      }
    }
  };
  handleResponseForAcceptRequest = (from: string, message: Message) => {
    if (
      this.acceptFriendRequestId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });
      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse)
      } else {
        this.getInvitationRecievedList();
      }
    }
  };
  handleResponseForWithdrawRequest = (from: string, message: Message) => {
    if (
      this.withdrawRequestId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });
      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse)
      } else {
        this.getInvitationSentList();
      }
    }
  };
  handleResponseForRemoveConnectionRequest = (from: string, message: Message) => {
    if (
      this.removeConnectionId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });
      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse)
      } else {
        this.getConnectionList();
      }
    }
  };
  handleResponseForSuggestionFollowers = (from: string, message: Message) => {
    if (
      this.userFollowerCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });

      if (apiResponse.errors || apiResponse.error) {
        if (apiResponse.errors[0].message === "Not following to any user.") {
          this.setState({ userFollowerListDataForWeb: [], loading: false});
        }
      } else {
        this.setState({ userFollowerListDataForWeb: apiResponse.data, loading: false });
      }
    }
  };
  handleResponseForSuggestionFollowing = (from: string, message: Message) => {
    if (
      this.userFollowingCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });

      if (apiResponse.errors || apiResponse.error) {
        // this.handleApiResponse(apiResponse)
        this.setState({loading: false, userFollowingListDataFroWeb: []})
      } else {
        this.setState({ userFollowingListDataFroWeb: apiResponse.data, loading: false });
      }
    }
  };
  handleResponseForAddFromFollowing = async (
    from: string,
    message: Message
  ) => {
    if (
      this.addFollowerFromFollowingCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse)
      } else {
        await this.getListOfSuggestionData();
        await this.getListOfSuggestionDataFollowers();
        await this.getListOfSuggestionDataFollowing();
      }
    }
  };
  handleResForUnFollowFromFollowing = async (
    from: string,
    message: Message
  ) => {
    if (
      this.unFollowFromFollowingCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse)
      } else {
        await this.getListOfSuggestionData();
        await this.getListOfSuggestionDataFollowers();
        await this.getListOfSuggestionDataFollowing();
      }
    }
  };

  //for Web File
  hanleTabChange = (event: object, value: number) => {
    this.setState({ tabPanelNo: value });
  };

  handleFollowTab = (event: object, value: number) => {
    this.setState({followTab: value})
  }


  handleApiResponse = (response: {error?: string, errors?: {"token": string}[]}) => {
    if(response.error) {
        toast.error(response.error);
    }else if(response.errors) {
        Object.values(response.errors[0]).forEach((item: string) => toast.error(item))
        if (Object.keys(response.errors[0]).includes("token")) {
            this.handleNavigateTOLogin()
        }
    }
  }
  handleNavigateTOLogin = () => {
    const toNavigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  
  checkForSubscription = async () => {
    const token = this.state.token;

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checkForSubscriptionAPI}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResponseForSubscriptionRequest = async (
    from: string,
    message: Message
  ) => {
    if ( this.getSubscriptionApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.errors || apiResponse.error) {
        this.handleApiResponse(apiResponse)
      } else {
        if(apiResponse.user_subscription.data.attributes.status === "active"){
          return this.gotoMessage(this.state.redirectCahtUserInfo)
        }
        toast.error("You must have an active subscription to access chat section")
      }
    }
  };

  gotoMessage=(data:profile_details | null)=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Messaging');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage) );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {from: "Profile", accountId:data
         }) 
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  
  // Customizable Area End
}
