import React, { useRef,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {  Box, Button, Grid, IconButton, Typography,  Accordion,
    AccordionSummary,
    AccordionDetails,ClickAwayListener,MenuItem,withStyles,
    createStyles,Theme,TextField,InputAdornment,Checkbox,Paper,
    FormControl,RadioGroup,FormControlLabel,Popper,  Dialog,
    DialogActions, DialogContent,List,Card,ListItem, ListItemAvatar, 
    ListItemText,Avatar,Tooltip,CircularProgress} from "@material-ui/core";
import {  RouteComponentProps } from "react-router-dom";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditIcon from '@material-ui/icons/Edit';
import NotesIcon from '@material-ui/icons/Notes';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CustomRadio from './CustomRadio'
import { getLoginToken, handleNavigation } from "./Utils.web";
import { Message } from '../../framework/src/Message';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum'; 
import { useRunEngine } from '../../blocks/utilities/src/hooks/useRunEngine';
import { useBlockHelpers } from '../../blocks/utilities/src/hooks/useBlockHelpers';
import { v4 as uuidv4 } from 'uuid';
import {styled } from "@material-ui/core/styles";
import { runEngine } from "../../framework/src/RunEngine";
import { toast } from "react-toastify";
import ChatAttachments from '../../blocks/chat/src/components/ChatAttachments.web'
import ChatReportDialog from "./ChatReportDialogue";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import Uploadmedia3 from '../../blocks/uploadmedia3/src/Uploadmedia3.web';
import { setStorageData } from "../../framework/src/Utilities";
import StarIcon from '@material-ui/icons/Star';

const appleyyyy=require("./imageppp.png")
const search=require("./search.png")
const threeDotsIcon=require("./threeDots.svg")
const cancelIcon = require("./cancel.svg");
const deleteIcon = require("./delete.svg");
import sendMessageThroughChannels from './Utils.web'

interface Props extends RouteComponentProps {
    loading?: boolean;
}
interface CustomCheckboxProps {
    name: string
    onChange: (event:{stopPropagation:()=>void})=>void
    "data-test-id"?: string
    checked: boolean;
  }

export interface UserReasons {
  id: number,
  reason: string,
  created_at: string,
  updated_at: string
  }

  export interface ResponseObj{
    message?:string
    chat?:ChatRespObj
    data?:ChatObj | Array<ChatObj> | Array<AccountDetailsItemObj>
    user_subscription?: SubscriptionObj
    errors?:Array<TokenObject>
    error?:string
    flag_reasons?: UserReasons[]
    reported_entity?: {data: {attributes: {chat_id: number}}}
  }
  export interface TokenObject{
    token:string
  }
  
  export interface MessageObj{
      id: string,
      type: string,
      attributes: {
        id: number,
        message: string,
        account_id: number,
        chat_id: number,
        updated_at: string,
        is_mark_read: boolean,
        created_at: string,
        created_by: {
          account_id: number,
          full_name: string,
          profile_image: null 
        },
        attachments:  Array<AttachmentObj> |  null
      } 
  }
  export interface AttachmentObj{
  id:number,
  url:string
  }
  interface AccountDetailsObj{
    id: number,
    email: string,
    activated: boolean,
    full_phone_number: string,
    type: string,
    role: string
  }
  
  interface ProfileDetailsObj {
    data: {
      id: string,
      type: string,
      attributes: {
        id: number,
        full_name: string,
        location: null,
        current_title: null,
        full_phone_number: null,
        email: string,
        experience: null,
        currency: null,
        salary: null,
        future_self: null,
        current_company: null,
        company_indusry: null,
        bio: null,
        work_email_id: null,
        personal_email_id: null,
        city: null,
        country: null,
        skills: Array<Array<string>>,
        selected_group_context: string,
        open_for_direct_approach: boolean,
        potential_score: null,
        relevance_score: null,
        overall_rank: null,
        company_name: null,
        company_website: null,
        company_size: null,
        company_founded_year: null,
        added_to_pipeline: boolean,
        pipeline_id: null,
        photo: string,
        total_connections: number,
        mutual_connections: null,
        is_followed: boolean,
        connection_request_details: null,
        is_reported: boolean,
        account_details: AccountDetailsObj,
        work_experiences: {
          data: []
        },
        resume: null,
        cover_letter: null
      }
    }
  }

  export interface SelectedReason {
    id: number,
    reason: string,
    created_at: string,
    updated_at: string,
    otherReason: string
  }
  
  export interface  AccountDetailsItemObj
  {
    id: string,
    type: string,
    attributes: {
      activated: boolean,
      country_code: null,
      email: string,
      type:string,
      created_at: string,
      updated_at: string,
      device_id: null,
      unique_auth_id: string,
      phone_number: null,
      role: string,
      profile_details: ProfileDetailsObj,
      connection_request_details: null
    }
  }
  export interface ChatObj{
      id: string,
      type: string,
      attributes: {
        id: number,
        name: string,
        chat_type: string,
        created_at: string,
        current_user_id: number,
        starred: boolean,
        account_details: {
          data: Array<AccountDetailsItemObj> 
        },
        messages: Array<MessageObj>,
        unread_messages_count:number,
        last_message?:LastMessageObj,
        is_reported: boolean
      }
  }

  export interface SubscriptionObj{
    // user_subscription: {
      data: {
        attributes: {
          status: string,
          subscription_details: {
            chat_limits: number
          }
        }
      }
    // },
  }

  interface LastMessageObj {
    id: string,
    type: string,
    attributes: {
      id: number,
      message: string,
      account_id: number,
      chat_id: number,
      updated_at: string,
      is_mark_read: boolean,
      created_at: string,
      created_by: CreatedBy,
      attachments: null
    }
  }
  interface CreatedBy {
    account_id: number,
    full_name: string,
    profile_image: null
  }
  interface ChatRespObj{
  data:ChatObj
  }
  
  interface ChatDetailsObj{
   chat:ChatRespObj
    }

const useStyles = makeStyles(() => ({
  '@global': {
    '*': {
        scrollbarWidth: 'none', /* Firefox */
        '-ms-overflow-style': 'none', /* Internet Explorer 10+ */
    },
    '::-webkit-scrollbar': {
        display: 'none', /* Chrome, Safari, and Opera */
    },
},
    checkedBoxIcon: {
        border: '1px solid #9C9C9C',
        borderRadius: 6,
        width: 20,
        height: 20,
        '$root.Mui-focusVisible &': {
          outline: `1px auto ##9C9C9C`,
          outlineOffset: 2,
        },
      },
      tickIcon: {
        backgroundColor: "#1F497D",
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
          display: 'block',
          width: 20,
          height: 20,
          backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
          content: '""',
        },
        'input:hover ~ &': {
          backgroundColor: '#1F497D',
        },
      },  
    linkTabContainerSelected: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        height: "100%",
        borderBottom: "2px solid #1F497D",
        padding: "0px 20px",
        cursor: "pointer",
        '@media (max-width: 1080px)': {
            "padding": "0px 8px",
        },
        '@media (max-width: 960px)': {
            "padding": "0px 15px",
        },
        '@media (max-width: 460px)': {
            "padding": "0px 8px",
        },
        '@media (max-width: 400px)': {
            "padding": "0px 5px",
        }
    },
    accordianroot: {
        width: "412px",
        float: "right",
        position: 'fixed',
        bottom: 0,
        right: 1,
        borderRadius: "8px 8px 0px 0px",
        border: "1px solid #CBD5E1",
        boxShadow: "0px 8px 32px 0px #0000000F",
        fontFamily: 'Arial',
        maxHeight: "595px", // newly added
        "&::before":{
          height:'0px'
        },
        "&.Mui-expanded":{
          margin:"16px 0px 0px"
        }
    },

      msgExpandIcon:{
        color:"#1F497D",
      },
      accordianHeadImage:{
        width: "36px",
         height: "36px",
          marginRight: "15px",
          borderRadius: "60%",
          border: "2px solid #04CB00"
      },
      messageHeading:{
        flexGrow: 1,
        fontSize: "24px",
     fontWeight: 400,
     lineHeight: "32px",
     color:"#1F497D",
     fontFamily: 'Arial',
       },
       iconSize: {
        width: '24px',
        height: '24px',
        cursor: "pointer",
      },
      iconSizeCancel: {
        width: '24px',
        height: '24px',
        cursor: "pointer",

      },
      iconSizeCancelBtn:{
        marginLeft:'10px'
      },
      dialogIconSize: {
        width: '24px',
        height: '24px',
        cursor: "pointer",
        paddingTop:'25px',
        paddingRight:'25px'
      },
      iconSizeAccordian:{
        width: '24px',
        height: '24px',
        cursor: "pointer",
        marginRight:"3px"
      },
      messageHeading1:{
        flexGrow: 1,
        fontSize: "24px",
     fontWeight: 400,
     lineHeight: "32px",
     color:"#1F497D",
    fontFamily: 'Arial',
      },
      outerThreeDots: {
        background: "#ffffff",
        borderRadius: 16,
        zIndex: 99,
        border: " 1px solid #F4F4F4",
        boxShadow: "0px 2px 8px 0px #00000014"
      },
      InnerThreeDots: {
        padding: "15px 23px"
      },
      menuItemActive: {
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        color: " #1F497D",
        fontFamily: 'Arial',
      },
      accordianDetails:{
        padding: "0px",
        borderTop: "1px solid #F4F4F4",
        maxHeight: "max-content"
      },
      gridcontainer1: {
        height: "100%"
      },
      newMsgMessagegriditem1:{
        height:'195px'
      },
      msgadornment:{
        marginRight:'5px'
      },
      msgHeadSearchImg:{
        width: "16.04px",
        height: "16.04px"
      },
      searchMsgPopupOuter: {
        position: "absolute" as const,
        background: "#ffffff",
        borderRadius: 16,
        zIndex: 99,
        boxShadow: "0px 2px 8px 0px #00000014",
        width: '88%'
      },
      searchMsgInnerPopup: {
        padding: '8px 10px',
        borderRadius: '20px',
        borderBottom: "1px solid #F4F4F4",
      },
      msgSearchImage:{
        width:'39px',
        height:'39px',
        marginRight:'5px'
      },
      messagetypo1:{
        fontWeight: 400,
        fontSize: "16px",
        color: "#1F497D",
        lineHeight: "22px",
        fontFamily: 'Arial',
      },
      typo11: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: "14px",
        color: "#9C9C9C",
        lineHeight: "22px"
      },
      griditem1: {
        borderBottom: "4px solid #1F497D",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
      },
      messagebox1:{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        padding: "9.5px 16px 9.5px"
      },
      typo4: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: "20px",
        color: "#1F497D",
        lineHeight:'28px'
      },
      subscribeBtn:{
        backgroundColor: "#1F497D",
        color: "#FFFFFF",
        textTransform: "none" as "none",
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: "16px",
        padding: "8px 24px",
        borderRadius: "8px",
        "&:hover":{
          background: "#1F497D",
        }
      },
      griditem14: {
        padding: "4px 22px",
        marginTop:'10px',
        marginBottom:'10px'
      },
      box3: {
         paddingLeft: "10px",
        paddingRight: "12px"
      },
      msgSelectedUserImg: {
        borderRadius: "60%",
        height: "39px",
        width: "39px",
      },
      typo7: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: "14px",
        color: "#9C9C9C",
        lineHeight: "22px"
      },
      messageBoxexistUser:{
        paddingLeft:'58.85px',
        paddingRight:'40px',
        height:"252px",
        overflowY:"auto" as const
      },
      messageText: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        fontFamily: 'Arial',
      },
      messageTextOne: {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        fontFamily: 'Arial',
        whiteSpace: "pre-wrap"
      },
      themeColor:{
        color: "#1F497D"
      },
      textColor:{
        color: "#64748B",
      },
      messageGriditem5: {
        height: "36vh",
        overflowY: "auto",
        margin: "10px 22px 0px",
        scrollbarWidth: "none",
        "$::-webkit-scrollbar": { 
            display: "none" 
        }
      },
      griditem6: {
        paddingRight: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "21px",

      },
      messagegriditem7: {
        borderRadius: "50%",
        height: "39px",
        width: "39px"
      },
      messagegriditemImg: {
        borderRadius: "50%",
        height: "30px",
        width: "30px"
      },
      messagegriditem8:{
        flex: 1,
        paddingLeft: "12px",
        minWidth:"0px"
      },
      messagetypo5: {
        fontWeight: 400,
        fontSize: "16px",
        color: "#17365D",
        lineHeight: "22px",
        fontFamily: 'Arial',
        marginBottom:'2px'
      },
      messageTypo7: {
        fontWeight: 400,
        fontSize: "14px",
        color: "#9C9C9C",
        lineHeight: "22px",
        overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    fontFamily: 'Arial',
      },
      griditemTime: {
        paddingRight: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      },
      messagetypo8: {
        fontWeight: 400,
        fontSize: "12px",
        color: "#17365D",
        lineHeight: "18px",
        fontFamily: 'Arial',
      },
      gridMsgcontainer2: {
        padding: "17px 29px 17px 26px",
        borderBottom: "1px solid #d3d3d3",
        borderTop: "1px solid #d3d3d3"
      },
      messageBoxContainer:{
        position:'relative' as const
      },
      TextField3: {
        border: "none",
        width: "100%",
        backgroundColor: "#F4F4F4 !important",
        position:'relative' as  const,
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#e0f7fa', 
        },
      },
      documentIcon:{
        color: "#9C9C9C",
    backgroundColor:" #F4F4F4",
    padding: "12px",
    marginright: "24px"
      },
      griditem16: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start'
    
      },
      IconButton1: {
        color: "#1F497D",
        position: 'relative' as const,
        padding:"4px"
      },
      IconButtonPhoto:{
        color: "#1F497D",
        position: 'relative' as const,
        padding:"4px",
        marginRight:'7px'
      },
      IconButtonUpDown: {
        color: "#1F497D",
        position: 'relative' as const,
        padding:"8.75px 12px 12px 12px"
      },
      sendButton: {
        background: "#C7D9F0",
        textTransform: 'none',
        fontFamily: "Arial",
        fontSize: "16px",
        fontWeight: 700,
        borderRadius: '8px',
        padding: '7px 16px',
        color:'#17365D',
        "&:hover":{
          background: "#C7D9F0",
        }
        
      },
      outerSendThreeDots: {
        bottom: '40px',
        right: '-50%',
        position: "absolute",
        background: "#ffffff",
        borderRadius: 16,
        zIndex: 99,
        border: " 1px solid #F4F4F4",
        width: 'max-content',
        boxShadow: "0px 2px 8px 0px #00000014"
      },
      sendInnerDots: {
        padding: '16px 20px'
      },
      sendButton1: {
        fontFamily: "Arial",
        fontSize: "12px",
        color: "#1F497D"
      },
      sendButton2: {
        fontFamily: "Arial",
        fontSize: "12px",
        color: "#9C9C9C"
      },
      accordiansummary:{
        padding:'0px 21px 0px 0px',
        "& .MuiAccordionSummary-content":{
            margin:'0px'
        },
  
      },
      editIconBtn:{
        marginRight:'12px',
        "&:hover": {
          backgroundColor: "transparent"
        }
      },
      editIcon:{
       color: "#1F497D" 
      },
      uploadFileImage:{
        width:'56px',
        height:'56px',
        borderRadius:"8px"
      },
      typo2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: "20px",
        color: "#1F497D",
      },
      deleteContentText: {
        color: "#17365D",
        fontFamily: "Arial",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
      },
      dialogCoversastion: {
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "26px",
        textAlign: "left",
        color: "#9C9C9C",
        fontFamily: "Arial",
        cursor: "pointer"
      },
      dialogCoversastionTop: {
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "26px",
        textAlign: "left",
        color: "#9C9C9C",
        paddingTop: '20px',
        fontFamily: "Arial",
      },
      dialogActionStyles: {
        borderTop: "1px solid #F4F4F4",
        padding:'23px 40px 24px 40px'
      },
      cancelButton: {
        width: "120px",
        height: "56px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        background: "#F4F4F4",
        color: "#1F497D",
        fontWeight: 700,
        fontSize: '16px',
        textTransform: 'none' as const,
        fontFamily: "Arial",
        marginRight:'34px'
      },
      deleteButton: {
        width: "120px",
        height: "56px",
        gap: "8px",
        borderRadius: "8px",
        opacity: "0px",
        background: "#DC2626",
        color: "#ffffff",
        fontWeight: 700,
        fontSize: '16px',
        textTransform: 'none' as const,
        fontFamily: "Arial",
        "&:hover":{
          background: "#DC2626",
          color: "#ffffff",
        }
      },
      contentBox:{
        padding:'12px 40px 24px 40px'
      },
      menuDeleteItemActive: {
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "17365D",
        padding:'9px 20px 9px 16px',
        fontFamily: "Arial",
      },
      InnerThreeDotsPersonal: {
        padding: "4px"
      },
      menuDeleteItem: {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#17365D",
        padding:'9px 20px 9px 16px',
        fontFamily: "Arial",
      },
      messageBox:{
        paddingTop:'10px',
        paddingBottom:'10px'
      },
      searchNewMessagePopupOuter: {
        position: "absolute" as const,
        background: "#ffffff",
        zIndex: 99,
        width: '89%'
      },
      typo1: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: "16px",
        color: "#1F497D",
        lineHeight:'22px'
      },
      newUserImage:{
        width:'39px',
        height:'39px'
      },
      listItem:{
        padding:'8px 10px',
        cursor: "pointer"
      },
      fileName:{
fontFamily: "Arial",
fontSize: "16px",
fontWeight: 400,
lineHeight: "22px",
color:"#17365D",
wordBreak:'break-word'
      },
      fileSize:{
        fontFamily: "Arial",
fontSize: "16px",
fontWeight: 400,
lineHeight: "22px",
color:"#9C9C9C"
      },
      searchPopupOuter: {
        position: "absolute" as const,
        background: "#ffffff",
        borderRadius: 16,
        zIndex: 99,
        boxShadow: "0px 2px 8px 0px #00000014",
        border: "1px solid #CBD5E1",
        width: '80%'
      },
      existUsertypo1: {
        fontWeight: 400,
        fontFamily: "Arial",
        color: "#1F497D",
        fontSize: "16px",
        lineHeight: "22px",
      },
      serachExistUserImg:{
        width:'39px',
        height:'39px'
      },
      listItemText:{
        marginTop:"0px",
        marginBottom:'0px'
      },
      messageBox3: {
        display:'flex'
       },
         uploadImage:{
         borderRadius: "8px",
         height: "120px", 
         width: "180px"
       },
       mr10:{
        marginRight:'10px'
      },
      mrl:{
        marginLeft:'42px'
      },
      accordianSelectedUserHeadImage:{
        width: "48px",
         height: "48px",
          marginRight: "15px",
          borderRadius: "60px",
          border: "3px solid #04CB00"
      },
      messageHeadingUser:{
        fontSize: "16px",
     fontWeight: 400,
     lineHeight: "22px",
     color:"#1F497D",
     fontFamily: 'Arial',
       },
       headingUserTitel:{
        fontSize: "12px",
     fontWeight: 400,
     lineHeight: "18px",
     color:"#9C9C9C",
     fontFamily: 'Arial',
       },
       disabledIconSize: {
        width: '24px',
        height: '24px',
        pointerEvents: "none",
        cursor: "not-allowed"
      },
      wordBreakStyle:{
        wordBreak:'break-word'
      },
      messagetime:{
        fontWeight: 400,
        fontSize: "12px",
        color: "#17365D",
        lineHeight: "18px" ,
        fontFamily: "Arial"
      },
      uploadImage1:{
        borderRadius: "8px",
        height: "120px", 
        width: "180px",
        display: "block",
        marginTop: "5px",
        marginBottom: "8px",
        cursor:"`pointer`"
      },
      newUploadFileImage:{
        width:'40px', //56
        height:'40px', //56px
        borderRadius:"8px",
        marginRight:"10px"
      },
      documentIconUploadFile:{
        color: "#9C9C9C",
    backgroundColor:" #F4F4F4",
    padding: "3px", //12
    marginRight: "10px" //24
      },
      fileNameUploadFile:{
        fontFamily: "Arial",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        color:"#17365D",
        wordBreak:'break-word' as const
              },
              fileSizeUploadFile:{
                fontFamily: "Arial",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        color:"#9C9C9C"
              },
              dialogIconSizeUploadFile: {
                width: '24px',
                height: '24px',
                cursor: "pointer",
                paddingTop:'25px',
                paddingRight:'25px'
              },
              fileType:{
                fontFamily: "Arial",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "22px",
                color: "#fff",
                wordBreak: "break-word" as const,
                backgroundColor: '#17365D',
                padding: "10px 5px",
                marginRight: "10px"
              },
              fileTypeP:{
                padding: "10px 7px",
              },
              favouriteInfo:{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "5px"
              },
              typoCount: {
                borderRadius: "50%",
                backgroundColor: " #DC2626",
                color: "#fff",
                padding: "1px 4px",
                fontSize: "7px",
                textAlign: "center",
                fontFamily: "Arial"
              },


}));


export const CustomizedTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputBase-root": {
        borderRadius: 8,
        height: 34.85,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.black,
        background: "#1C1C1C0D"
      },
      "& .MuiOutlinedInput-notchedOutline": {
      },
      "& .MuiOutlinedInput-notchedOutline:hover": {
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1.23px solid #6D97C1"
      },
      "& .MuiOutlinedInput-root:hover": {

      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "white"
      },
      "& .MuiSvgIcon-root": {
        fontSize: "25px"
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline:hover": {
        borderColor: "white !important"
      },
      "& .MuiOutlinedInput-input":{
        color:"#17365D"
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent', 
        }
      },
      '& .MuiInputBase-input': {
        color: '#17365D',
        fontSize: "17.24px",
        fontWeight: "400",
        lineHeight: "24.63px",
        fontFamily: "Arial",
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#9C9C9C',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "22px",
        fontFamily: "Arial",

      },
    }
  }))(TextField);

 
  export const CustomCheckboxSecondary = withStyles({
    root: {
      "&$checked": {
        color: "#EA0C78",
      },
      padding: "0px",
    },
    checked: {},
  })((props: CustomCheckboxProps) => {
    const classes = useStyles();
    return (
      <Checkbox
        checkedIcon={<span className={clsx(classes.checkedBoxIcon, classes.tickIcon)} />}
        icon={<span className={classes.checkedBoxIcon} />}
        color="default"
        {...props}
      />
    );
  });

  export const CustomizedMsgTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputBase-root": {
        borderRadius: 8,
        fontFamily: "Arial",
        color: theme.palette.common.black,
        background: "#1C1C1C0D"
      },

      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1.23px solid #6D97C1"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "white"
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline:hover": {
        borderColor: "white !important"
      },
      '& .MuiInputBase-input': {
        color: '#1F497D',
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "22px",
        fontFamily: "Arial",
        overflow:"auto !important"
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#6D97C1',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "22px",
        fontFamily: "Arial"

      },
      "& .MuiOutlinedInput-multiline":{
        padding: "10px 20px"
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'transparent', 
        },
        '&:hover fieldset': {
          borderColor: 'transparent', 
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent', 
        }
      },
    }
  }))(TextField);

  const ScrollableMessageBox = styled(Box)(({ theme }) => ({
    padding: "0px 40px 0px 27px",
    maxHeight:'252px',
  overflowY: "auto",
  scrollbarWidth: 'none', // For Firefox
  '&::-webkit-scrollbar': {
    display: 'none', // For Chrome, Safari, and Opera
  },
  }));


  const ScrollableFilesBox = styled(Box)(({ theme }) => ({
    maxHeight: "calc(-512px + 100vh)",
  overflowY: "auto",
  scrollbarWidth: 'none', // For Firefox
  '&::-webkit-scrollbar': {
    display: 'none', // For Chrome, Safari, and Opera
  },
  }));

  const subscribedMessages = [
    MessageEnum.RestAPIResponceMessage,
    MessageEnum.SessionResponseMessage,
  ];
  let focuedUsersFlow:number=1
  let chatIdValue:string=''
  let isNewMsgMessageValue:boolean=false
  let isSingleDeleteFlow:boolean=false
 const baseURL = require('../../framework/src/config')
  let existingUserListData: ChatObj[] = []

function FloatingChat() {
    const classes = useStyles();
    const fileInputRef = useRef<HTMLInputElement>(null)
    const anchorRef = useRef(null);
    const anchorUserRef = useRef(null);

    const getAllNewUsersApiCallId = useRef<string>('');
    let sendMessageApiCallId = useRef<string>('');
    const getChatDetailsApiCallId = useRef<string>('');
    const deleteChatApiCallId = useRef<string>('');
    const getSubscriptionApiCallId = useRef<string>('');
    const getFousedUsersApiCallId = useRef<string>('');
    const createChatApiCallId = useRef<string>('');
    const markChatMessagesUnreadApiCallId = useRef<string>('');
    const starUnstarChatApiCallId = useRef<string>('');
    const reasonApiCallId = useRef<string>('');
    const addReasonsApiCallId = useRef<string>('');
    const getUserProfileApiId = useRef<string>('');
    const sendMessageOptionSetApiCallId = useRef<string>('');
    
    

    const { sendBlockMessage, sendNetworkRequest, setReceiveCallback, subscribe, unsubscribeFromMessage } =
		useRunEngine();

    const { extractNetworkResponse } = useBlockHelpers();

    const [defaultMsgMessageflow, setDefaultMsgMessageflow] = React.useState(false);
    const [viewThreeDotsFlotMessageStatus, setViewThreeDotsFlotMessageStatus] = React.useState(false);
    const [isNewMsgMessage, setIsNewMsgMessage] = React.useState(false);
    const [isConversationStartWithExistUser, setIsConversationStartWithExistUser] = React.useState(false);
    const [isUserSelectedInMsgFlow, setIsUserSelectedInMsgFlow] = React.useState(false);
    const [searchMsgReceptient, setSearchMsgReceptient] = React.useState('');
    const [searchMsgPopUpStatus, setSearchMsgPopUpStatus] = React.useState(false);
    const [selectedVisible, setSelectedVisible] = React.useState(false);
    const [hoveredMsgItemId, setHoveredMsgItemId] = React.useState(0);
    const [manageConversationClicked, setManageConversationClicked] = React.useState(false);
    const [maxRows, setMaxRows] = React.useState(4);
    const [checkedItems, setCheckedItems] = React.useState<number[]>([]);
    const [selectedFiles, setSelectedFiles] = React.useState<Array<File>>([]);
    const [selectedImage, setSelectedImage] = React.useState('');
    const [toggleMessageBoxOpenClose, setToggleMessageBoxOpenClose] = React.useState(false);
    const [massgeMaxRows, setmMssgeMaxRows] = React.useState(4);
    const [sendButtonPopupStatus, setSendButtonPopupStatus] = React.useState(false);
    const [sendMessageOption, setSendMessageOption] = React.useState('');
    const [viewThreeDotsStatus, setViewThreeDotsStatus] = React.useState(false);
    const [viewChatThreeDotsStatus, setViewChatThreeDotsStatus] = React.useState(false);
    const [reportClick, setReportClick] = React.useState(false);
    const [reportedSuccessfully, setReportedSuccessfully] = React.useState(false);
    const [acceptanceCriteria, setAcceptanceCriteria] = React.useState('.gif,.jpg,.png');
    const [enterMessage, setEnterMessage] = React.useState('');
    const [dialogOpen, setDialogOpens] = React.useState(false);
    const [deleteMultiple, setDeleteMultiple] = React.useState(false);
    const [viewThreeDotsSelectedUser, setViewThreeDotsSelectedUser] = React.useState(false);
    const [hoveredNewUserItemId, setHoveredNewUserItemId] = React.useState(0);
    const [newMessageFlow, setNewMessageFlow] = React.useState(false);
    const [searchReceptient, setSearchReceptient] = React.useState('');
    const [hoveredMsgExistUserItemId, setHoveredMsgExistUserItemId] = React.useState(0);
    const [chatId, setchatId] = React.useState('');
    const [userAppAuthenticationToken, setUserAppAuthenticationToken] = React.useState<string | undefined>(undefined);
    const [newUsersList, setNewUsersList] = React.useState<Array<AccountDetailsItemObj>>([]);
    const [existSearchUsersList, setExistSearchUsersList] = React.useState<Array<ChatObj>>([]);
    const [createdChatName, setCreatedChatName] = React.useState('');
    const [messageListInfo, setMessageListInfo] = React.useState<ChatDetailsObj | null>(null);
    const [existUsersList, setExistUsersList] = React.useState<Array<ChatObj>>([]);
    const [selectedUser, setSelectedUser] = React.useState<AccountDetailsItemObj | null>(null);
    const [accountId, setAccountId] = React.useState('')
    const [lastMessageTime, setLastMessageTime] = React.useState<string | null>(null)
    const [selectedNewUserInfo, setSelectedNewUserInfo] = React.useState<AccountDetailsItemObj | null>(null);
    const [imageModalOpen, setImageModalOpen] = React.useState(false);
    const [previewImageUrl, setPreviewImageUrl] = React.useState('')
    const [chatLimit, setChatLimit] = React.useState(10000)
    const [isSubscribed, setIsSubscribed] = React.useState(false)
    const [userReasons, setUserReasons] = React.useState<UserReasons[]>([{
      id: 1,
      reason: "",
      created_at: "",
      updated_at: ""
  }])
    const [selectedReason, setSelectedReason] = React.useState<SelectedReason>({
        id: 0,
        reason: "",
        created_at: "",
        updated_at: "",
        otherReason: ""
    })
    
    const [select, setSelect] = React.useState('')
    const [isProfileDetailsAvailble, setIsProfileDetailsAvailble] = React.useState(false)
    const [profileDetails, setProfileDetails] = React.useState({ current_company: "", account_details: { role: "" }, full_name: '',photo:'' })
    const [loaderMessage, setLoaderMessage] = React.useState(false)
    const [progressMessage, setProgressMessage] = React.useState(0)
    const [remainingTimeMessage, setRemainingTimeMessage] = React.useState(0)
    const [existUsersListAbly, setExistUsersListAbly] = React.useState<Array<any>>([]);

    const [loader, setLoader] = React.useState(false)
    

    const checkForBlur = (index: number) => {
      return (index + 1) > chatLimit
    }
  
    const handleCondition = (condition: boolean, truePart: string, falsePart: string) => {
      return condition ? truePart : falsePart
    }  

    const checkForNewUser = () => {
      let existingUsers: AccountDetailsItemObj[] = []
      let chatId = ""
  
      existUsersList.forEach((item: ChatObj) => {
        const userInfo = item.attributes.account_details.data.find((selectItem: AccountDetailsItemObj) => item.attributes.current_user_id != Number(selectItem.id))
        if (userInfo) {
          existingUsers.push(userInfo)
          if (selectedNewUserInfo?.id === userInfo.id) {
            chatId = item.id
            setchatId(chatId)
          }
        }
  
      })
      if (selectedNewUserInfo?.id && (!existingUsers.some((user) => user.id === selectedNewUserInfo?.id))) {
        createChat(selectedNewUserInfo?.id)
      } else {
        sendMessageToSelectedUser(chatId)
      }
    }
  
    const  openViewThreeDotsFlotMessage = (event: {stopPropagation:()=>void}) => {
        event.stopPropagation()
        setViewThreeDotsFlotMessageStatus(!viewThreeDotsFlotMessageStatus)
      }
      const  closeViewThreeDots = () => {
        setViewThreeDotsFlotMessageStatus(false)
        setSendButtonPopupStatus(false)
        setViewThreeDotsStatus(false)
        setViewChatThreeDotsStatus(false)
      }


    const  handleToggleSelectedVisibility = () => {
      setSelectedVisible(!selectedVisible);
      setManageConversationClicked(!manageConversationClicked);
      };
    const  handelNewMessage=(event: {stopPropagation:()=>void})=>{
      event.stopPropagation()
      if (!isSubscribed) {
        return toast.error("You don't have subscription plan to start chat")
      }
        if(existUsersList.length > chatLimit) {
          return toast.error("You have reached the limit of chats for your subscription plan")
        }
        setIsNewMsgMessage(true)
        setDefaultMsgMessageflow(true)
        setSearchMsgReceptient('')
        setSearchMsgPopUpStatus(false) 
        isNewMsgMessageValue=true
      }
     const handelMessageSearch = (event: {target:{value:string}},msgFlowType:string) => {
        const searchValue = event.target.value;
        setSearchMsgReceptient(searchValue)
        setSearchMsgPopUpStatus(searchValue !== '')
        if(chatLimit < 10000) {
          const filteredList= existUsersList.filter((item, index) => !checkForBlur(index))
          setExistSearchUsersList(filteredList)
        }
        else if(msgFlowType=='defaultMsg'){
          focuedUsersFlow=3
          getFocusedUserList(event.target.value)
        }else{
          getAllUserList(event.target.value)
        }
      
      }

      const clearUploadImage=()=>{
        setSelectedImage('')
        setSelectedFiles([])
      }
     const openFile=(fileType:string)=>{
        setAcceptanceCriteria(fileType=='image'?'.gif,.jpg,.png,.jpeg':'.docx,.doc,.pdf')
        if(fileInputRef.current){
            fileInputRef.current?.click()
        }
    
      }
    
   const  renderViewThreeDots = () => {
        return (
          <ClickAwayListener onClickAway={closeViewThreeDots}>
                <Popper
          id="popper"
          open={true}
          anchorEl={anchorRef.current}
          placement='bottom-end'
          modifiers={{
            flip: {
              enabled: true,
            },
          }}
        >
            <Box className={classes.outerThreeDots}>
              <Box className={classes.InnerThreeDots}>
                <MenuItem className={classes.menuItemActive} onClick={() =>handleToggleSelectedVisibility()}>Manage Conversation</MenuItem>
              </Box>
            </Box>
            </Popper>
          </ClickAwayListener>
        )
      }

      const  renderChatViewThreeDots = () => {
        return (
          <ClickAwayListener data-test-id="selectedUserActions" onClickAway={closeViewThreeDotsSelectedUser}>
                     <Popper
          id="popper"
          open={true}
          anchorEl={anchorUserRef.current}
          placement='bottom-end'
          modifiers={{
            flip: {
              enabled: true,
            },
          }}
          >
            <Box className={classes.outerThreeDots} minWidth={"192px"} >
              <Box className={classes.InnerThreeDotsPersonal}>
                <MenuItem className={classes.menuDeleteItemActive} data-test-id="menuDeleteItemActive" onClick={(event)=>handleDeleteDialogOpen(event)}>Delete</MenuItem>
                <MenuItem className={classes.menuDeleteItem} onClick={(event) =>handleMarkUnread(event)}>Mark Unread</MenuItem>
                <MenuItem className={classes.menuDeleteItem} onClick={(event) => starUnstarChat(event)}>{handleCondition(checkForStar(), "Remove Star", "Star")}</MenuItem>
                <MenuItem className={classes.menuDeleteItem} onClick={(event) => handleReportDialogue(event)}>{handleCondition(checkForReport(), "Reported", "Report")}</MenuItem>
              </Box>
            </Box>
            </Popper>
          </ClickAwayListener>
        )
      }

   const  renderMsgSearchBox=(msgFlowType:string)=>{
        return(
    <>
          <CustomizedTextField
            value={searchMsgReceptient}
            onChange={(event)=>handelMessageSearch(event,msgFlowType)}
            variant="outlined"
            placeholder={msgFlowType=='defaultMsg'?"Search messages":'New message'}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.msgadornment}>
                  <IconButton size="small">
                   <img src={search}  className={classes.msgHeadSearchImg}/>
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  ⌘/
                </InputAdornment>
              ),
            }}
            data-test-id="messageSearh"
          />
          </>
        )
      }

     const renderNewUserSearchPopup = (userType:string) => {
        return (
          <ClickAwayListener onClickAway={closeViewThreeDots}>
            <Box className={classes.searchNewMessagePopupOuter}>
               <List>
          {newUsersList.map((itemObj:AccountDetailsItemObj, index) => (
            <Card
              key={'newuser_'+index}
              elevation={0}
              style={{
                marginBottom: hoveredNewUserItemId==Number(itemObj.id)?0:2,
                borderBottom: "1px solid #F4F4F4",
                backgroundColor: hoveredNewUserItemId==Number(itemObj.id)?"#6D97C133":"#fff" 
              }}
              onMouseEnter={() => handleExistUserMouseEnter(Number(itemObj.id))}
              onMouseLeave={handleExistUserMouseLeave}
              onClick={() => startConversastion(userType,itemObj)}
            >
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar src={itemObj.attributes.profile_details.data.attributes.photo} alt={'Photo'}  className={classes.newUserImage}/>
                </ListItemAvatar>
                <ListItemText 
                  primary={
                    <Typography className={classes.typo1}>{itemObj.attributes.profile_details.data.attributes.full_name}</Typography>
                  }
                  secondary={  <Typography className={classes.typo11}>{itemObj.attributes.role === "recruiter" ? `Recruiter at ${itemObj.attributes.profile_details.data.attributes.company_name}` : itemObj.attributes.profile_details.data.attributes.current_title}</Typography>}
                />
              </ListItem>
            </Card>
          ))}
        </List>
            </Box>
          </ClickAwayListener>
        )
      }

    const  renderMsgSearchPopup = (userType:string) => {
        return (
          <ClickAwayListener onClickAway={closeViewThreeDots}>
            <Box className={classes.searchPopupOuter}>
               <List style={{padding:"8px 10px"}}>
          {existSearchUsersList.map((itemObj:ChatObj, index) => {
                 const userInfo = itemObj.attributes.account_details.data.find((selectItem:AccountDetailsItemObj)=>itemObj.attributes.current_user_id != Number(selectItem.id))
                 return(
                   <>
                   {userInfo && 
            <Card
              key={index}
              style={{
                marginBottom: 2,
                borderRadius:"20px",
                borderBottom: "1px solid #F4F4F4",
                backgroundColor: hoveredMsgExistUserItemId==Number(userInfo.id)?"#6D97C133":"#fff" ,
                cursor: "pointer"
              }}
              onMouseEnter={() => handleSearchExistUserMouseEnter(Number(userInfo.id))}
              onMouseLeave={handleSearchExistUserMouseLeave}
              onClick={() => startSearchConversastion(userType,userInfo,itemObj)}
              elevation={0}
              data-test-id="userList"
            >
              <ListItem>
                <ListItemAvatar className={classes.serachExistUserImg}>
                  <Avatar src={userInfo.attributes.profile_details.data.attributes.photo} alt={userInfo.attributes.profile_details.data.attributes.full_name} />
                </ListItemAvatar>
                <ListItemText className={classes.listItemText}
                  primary={
                    <Typography className={classes.existUsertypo1}>{userInfo.attributes.profile_details.data.attributes.full_name}</Typography>
                  }
                  secondary={  <Typography className={classes.typo11}>{userInfo.attributes.profile_details.data.attributes.current_title}</Typography>}
                />
              </ListItem>
            </Card>
          }
          </>
          )
    })}
        </List>
            </Box>
          </ClickAwayListener>
        )
      }


    const  handleMsgMouseEnter = (itemId: number) => {
        setHoveredMsgItemId(itemId)
      };
    const  handleMsgMouseLeave = () => {
        setHoveredMsgItemId(0)
      };
  const handleMsgMessageConversation = (event: { stopPropagation: () => void, preventDefault: () => void }, userInfo:AccountDetailsItemObj, chatInfo:ChatObj) => {
    event.stopPropagation();
    event.preventDefault();
    if (!manageConversationClicked) {
      setDefaultMsgMessageflow(true)
      setIsConversationStartWithExistUser(true)
      setSelectedUser(userInfo)
      setchatId(chatInfo.id)
      setCreatedChatName(chatInfo.attributes.name)
      setAccountId(userInfo.id)
      chatIdValue=chatInfo.id

      if(chatInfo.attributes && chatInfo.attributes.last_message && chatInfo.attributes.last_message.attributes.created_at){
   setLastMessageTime(chatInfo.attributes.last_message?.attributes?.created_at)
      }
        getChatDetails(chatInfo.id, true)
        if (chatInfo.attributes.unread_messages_count && (chatInfo.attributes.unread_messages_count > 0)) {
          const updatedChatList = existUsersList.filter((chat: ChatObj) => !chat.attributes.is_reported).map((chat: ChatObj) => { return (chatInfo.id === chat.id) ? { ...chat, attributes: { ...chat.attributes, unread_messages_count: 0 } } : chat })
          setUnreadCount(updatedChatList)
        }
        if(chatInfo && chatInfo.attributes && chatInfo.attributes.unread_messages_count  && chatInfo.attributes.unread_messages_count > 0){
          getFocusedUserList('')
          }
    }
  }



       const  handleCheckboxChange = (event:{stopPropagation:()=>void}, userId: number) => {
        event.stopPropagation()
        const checkedItem = checkedItems.includes(userId)?checkedItems.filter((itemId) => itemId !== userId):[...checkedItems, userId]
        setCheckedItems(checkedItem)
          };

        const messageToggleUp=()=>{
       
            const textMessageBox= document.getElementById('textarea'); 
            if(textMessageBox){
              if(isNewMsgMessage){
                setMaxRows(10)
              }
              else{
                setMaxRows(6)
              }
          
            }
            
            const messageBox=document.getElementById('messageBoxexistUser');
            if(messageBox){
              messageBox.style.maxHeight="208px"
            }
         
          const newMessageBox= document.getElementById('newMsgMessagegriditem1'); 
          if(newMessageBox){
          newMessageBox.style.height="151px"
          }
          const fileBox=document.getElementById('uploadfilebox');
          if(fileBox && selectedFiles.length>0){
            fileBox.style.display="none"
          }
            

          setToggleMessageBoxOpenClose(true)
          }
          const messageToggleDown=()=>{
     
            const textMessageBox= document.getElementById('textarea'); 
            if(textMessageBox){
              setMaxRows(4)
            }
            const messageBox=document.getElementById('messageBoxexistUser');
            if (messageBox) {
              const fileBox = document.getElementById('uploadfilebox');
              if (fileBox && selectedFiles.length > 0) {
                let height = 0
                if (selectedFiles.length <= 2) {
                  height = selectedFiles.length * 67 // 73
                }
                else {
                  height = 134
                }
                messageBox.style.maxHeight = `${252 - height}px`
                fileBox.style.display = "block"
              }
              else {
                messageBox.style.maxHeight = "252px"
              }
            }
       
          const newMessageBox= document.getElementById('newMsgMessagegriditem1'); 
            if(newMessageBox){
              const fileBox=document.getElementById('uploadfilebox');
              if(fileBox && selectedFiles.length>0){
                let height=0
                if(selectedFiles.length<=2){
                  height=selectedFiles.length*67 // 73
                }
                else{
                  height=134
                } 
                newMessageBox.style.maxHeight=`${195-height}px`
                fileBox.style.display="block"
              }
              else{
                newMessageBox.style.height="195px"
              }
            }

            const fileBox=document.getElementById('uploadfilebox');
            if(fileBox && selectedFiles.length>0){
              fileBox.style.display="block"
            }
          
          }

         const handleSendButtonPopup = () => {
            setSendButtonPopupStatus(true) 
          }
         const handleChangeRadio = (event:  {target:{value:string}}) => {
          setSendMessageOption(event.target.value)
          setsendMessageOptionInfo(event.target.value)
          };
        const handleFileSelect=(event: React.ChangeEvent<HTMLInputElement>)=>{
            if(event.target.files){
              const parts = (event.target.files[0].name).split('.');
              if(parts[1] && ![ "gif","jpg","png","jpeg","docx","doc","pdf"].includes(parts[1].toLowerCase())){
                toast.error("File type not supported", { toastId: 'apiError'})
                return 
              }
              const newFiles = Array.from(event.target.files);
            let fileslist=[...selectedFiles, ...newFiles]
            if(fileslist.length>3){
              toast.error("You have reached the maximum file limit", { toastId: 'apiError'})
              return 
            }
            setSelectedFiles(fileslist)
              if(fileslist.length<=2){
                let height=fileslist.length*67  // 73
                const messageBox=document.getElementById('messageBoxexistUser');
                if(messageBox){
                  messageBox.style.maxHeight=`${252-height}px`
                }
        
                const newMessageBox= document.getElementById('newMsgMessagegriditem1'); 
                if(newMessageBox){
                  newMessageBox.style.height=` ${195-height}px` 
                }
                const emptyBox= document.getElementById('emptyBox'); 
                if(emptyBox){
                  emptyBox.style.height=`calc(100vh - 453px - ${height}px)`
                }       
              } 
              scrollToLastFileUpload()
          }
        }

        const scrollToLastFileUpload=()=>{
          let element=document.getElementById("uploadfileboxList")
          if(element){
            const toplast = element.lastElementChild;
            if(toplast){
              setTimeout(() => {
                toplast.scrollIntoView();
              }, 500);
            }
          }
        }

          const handelMessage=(e:{target:{value:string}})=>{
            setEnterMessage(e.target.value)
          }
         const handleDialogOpen = () => {
          setDialogOpens(true)
          setDeleteMultiple(true)
          };
        
        const handleDeleteDialogOpen = (event:{stopPropagation:()=>void}) => {
          event.stopPropagation()
         setDialogOpens(true)
         setDeleteMultiple(false)
         setViewThreeDotsSelectedUser(false);
         isSingleDeleteFlow=true
         };
         const handleMarkUnread=(event:{stopPropagation:()=>void})=>{
          event.stopPropagation()
          setViewThreeDotsSelectedUser(false);
          markMessagesUnread()
         }

         const handleCloseReportMessage = () => {
            setReportedSuccessfully(false)
          }

        const handleReportDialogue = (event:{stopPropagation:()=>void}) => {
          event.stopPropagation()
          if(checkForReport()) {
            return
          }
          setReportClick(!reportClick)
          setViewChatThreeDotsStatus(false)

          if (!reportClick) {
              getReasonsList()
          }
        }

        const handleReportDialogueClose = () => {
          setReportClick(false)
        }

        const handleSelect = (reason: {
          id: number,
          reason: string,
          created_at: string,
          updated_at: string,
          otherReason: string
        }) => {
          setSelectedReason(reason)
        }

        const checkForReport = () => {
          return existUsersList.length > 0 && existUsersList.find((item) => item.id === chatId)!.attributes.is_reported
        }      

        const checkForStar = () => {
          return existUsersList.length > 0 && existUsersList.find((item) => item.id === chatId)!.attributes.starred
        }    

       const closeViewThreeDotsSelectedUser=()=>{
        setViewThreeDotsSelectedUser(false);
       }
          
          const handleCloseDeleteDialog = () => {
            setDialogOpens(false)
            isSingleDeleteFlow=false
          };
         const handledeleteDialogClose=()=>{
          setDialogOpens(false)
          setCheckedItems([])
          setSelectedVisible(false);
          setManageConversationClicked(false);
         }
        const  handleDeleteConfirm = () => {
            setDialogOpens(false)
            setDeleteMultiple(false)
            setSelectedVisible(false)
            setManageConversationClicked(false)
            deleteChat()
          };
       const showDefaultFlow=(event:{stopPropagation:()=>void})=>{
              event.stopPropagation()
            setDefaultMsgMessageflow(false)
            setIsConversationStartWithExistUser(false)
            setManageConversationClicked(false)
            setSearchMsgReceptient('')
            setSearchMsgPopUpStatus(false)
            setSelectedVisible(false)
            setMaxRows(4)
            setSelectedFiles([])
          }
          const showDefaultFlowFromNewMessageFlow=(event:{stopPropagation:()=>void})=>{
            event.stopPropagation()
          setDefaultMsgMessageflow(false)
          setIsConversationStartWithExistUser(false)
          setManageConversationClicked(false)
          setIsNewMsgMessage(false)
          setSearchMsgReceptient('')
          setSearchMsgPopUpStatus(false)
          // setmMssgeMaxRows(4)
          setEnterMessage('')
          setMaxRows(4)
          setIsUserSelectedInMsgFlow(false)
          isNewMsgMessageValue=false
          setSelectedFiles([])
        }
        const  toggleSelectedUerActions=(event:{stopPropagation:()=>void})=>{
          event.stopPropagation()
          setViewThreeDotsSelectedUser(!viewThreeDotsSelectedUser)
          }

          const handleExistUserMouseEnter = (itemId: number) => {
            setHoveredNewUserItemId(itemId)

          };
         const handleExistUserMouseLeave = () => {
          setHoveredNewUserItemId(0)

          };
        const  startConversastion=(userType:string,userInfo:AccountDetailsItemObj)=>{
          setSearchMsgPopUpStatus(false)
          setNewMessageFlow(true)
          setSearchReceptient('')  
          setIsUserSelectedInMsgFlow(true)
          setmMssgeMaxRows(10)
          setSelectedNewUserInfo(userInfo)
          }

          const handleSearchExistUserMouseEnter = (itemId: number) => {
            setHoveredMsgExistUserItemId(itemId)

          };
         const handleSearchExistUserMouseLeave = () => {
          setHoveredMsgExistUserItemId(0)

          };

          const  startSearchConversastion = (userType:string,userInfo:AccountDetailsItemObj,chatInfo:ChatObj)=>{

            setDefaultMsgMessageflow(true)
            setIsConversationStartWithExistUser(true)
           setSelectedUser(userInfo)
        setchatId(chatInfo.id)
        setCreatedChatName(chatInfo.attributes.name)
        setAccountId(userInfo.id)
        chatIdValue=chatInfo.id
           if(chatInfo.attributes && chatInfo.attributes.last_message && chatInfo.attributes.last_message.attributes.created_at){
     setLastMessageTime(chatInfo.attributes.last_message?.attributes?.created_at)
        }
       getChatDetails(chatInfo.id, true)
          if(chatInfo && chatInfo.attributes && chatInfo.attributes.unread_messages_count  && chatInfo.attributes.unread_messages_count > 0){
            getFocusedUserList('')
            }
            }

        const toggleMessageBox=()=>{
            return(
              <Box>
              <IconButton className={classes.IconButtonUpDown} disableRipple>
                {!toggleMessageBoxOpenClose &&  
                   <KeyboardArrowUpIcon onClick={()=>messageToggleUp()}/>
                }
                   {toggleMessageBoxOpenClose &&  
                   <KeyboardArrowDownIcon onClick={()=>messageToggleDown()} />
                }
             
              </IconButton>
            </Box>
            )
          }
          const  fileAttachmentBlock=()=>{
            return(
              <Box>
              <IconButton className={classes.IconButtonPhoto} onClick={() => openFile('image')}>
                <PhotoLibraryIcon />
              </IconButton>
                <input
                  ref={fileInputRef}
                  hidden
                  type="file"
                  accept={acceptanceCriteria}
                  onChange={(e) =>handleFileSelect(e)}
                  data-test-id="chooseFile"
                />
              <IconButton className={classes.IconButton1} data-test-id="openFile" onClick={() => openFile('doc')}>
                <AttachFileIcon />
              </IconButton>
            </Box>
            )
          }

          const sendButtonsBlock=()=>{
            return(
              <Box display={'flex'} alignItems={'center'} style={{ gap: '15px' }}>
              <Box position={'relative'}>
                <Button variant="contained" className={classes.sendButton} disabled={disbableSendButton()} onClick={()=>checkForNewUser()}>Send</Button>
        
                {sendButtonPopupStatus && renderEnterButtonThreeDots()}
              </Box>
              <Box onClick={handleSendButtonPopup}>
                <img src={threeDotsIcon} className={classes.iconSize} />
              </Box>
            </Box>
            )
          }

          const  renderEnterButtonThreeDots = () => {
            return (
              <ClickAwayListener onClickAway={closeViewThreeDots}>
                <Box className={classes.outerSendThreeDots} >
                  <Box className={classes.sendInnerDots}>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={sendMessageOption}
                        onChange={handleChangeRadio}>
                        <FormControlLabel
                          value="Press Enter to Send"
                          control={<CustomRadio />}
                          label={
                            <Box>
                              <Typography variant="h6" className={classes.sendButton1}>Press Enter to Send</Typography>
                              <Typography variant="body2" className={classes.sendButton2}>
                                Pressing enter will send message
                              </Typography>
                            </Box>
                          }
                        />
                        <FormControlLabel
                              value="Click To Send"
                          control={<CustomRadio />}
                          label={
                            <Box>
                              <Typography variant="h6" className={classes.sendButton1}>Click Send</Typography>
                              <Typography variant="body2" className={classes.sendButton2}>
                                Clicking send will send message
                              </Typography>
                            </Box>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
        
              </ClickAwayListener>
            )
          }

          const renderReportModal = () => {
            if (reportClick)
              return <ChatReportDialog
                handleClose={() => handleReportDialogueClose()}
                selectedReason={selectedReason}
                handleSelect={handleSelect}
                handleSubmit={() => addReasons1()}
                list={userReasons}
              />
          }

          const renderReportMessageModal = () => {
            if(reportedSuccessfully)
            return (
              <>
                <Dialog open={reportedSuccessfully} data-test-id="deleteModal" onClose={handleCloseReportMessage}>
                  <Grid item xs={12} style={{width: "600px"}}>
                    <Grid container direction="row" alignItems="center">
                      <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                        <img src={cancelIcon}
                          onClick={handleCloseReportMessage}
                          style={{ 
                            width: '24px',
                            height: '24px',
                            cursor: "pointer",
                            paddingTop:'25px',
                            paddingRight:'25px'
                           }}
                          data-test-id="deleteDialogCancelIcon"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <DialogContent style={{padding:'10px 40px 60px'}}>
                    <Grid item xs={12}>
                      <Grid container direction="row" spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <Typography variant="h6" style={{
                            color: "#17365D",
                            fontFamily: "Arial",
                            fontSize: "24px",
                            fontWeight: 700,
                            lineHeight: "32px",
                          }}>
                          This user has been reported successfully. 
                          <br></br>
                          Our team will soon connect with you.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </Dialog>
              </>
            )
          }

          const renderDeleteModel = () => {
            return (
              <>
                <Dialog open={dialogOpen} data-test-id="deleteModal" onClose={handleCloseDeleteDialog}>
                <Grid item xs={12}>
                      <Grid container direction="row"  alignItems="center">
                        <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                          <img src={cancelIcon}
                            onClick={handleCloseDeleteDialog}
                            className={classes.dialogIconSize}
                            data-test-id="deleteDialogCancelIcon"
                          />
                        </Grid>
                        </Grid>
                        </Grid>
                  <DialogContent className={classes.contentBox} >
                    <Grid item xs={12}>
                      <Grid container direction="row" spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <Typography variant="h6" className={classes.deleteContentText}>
                           {deleteMultiple ? "Are you sure you want to delete these conversation?" : "Are you sure you want to delete this conversation?"}
                          </Typography>
                        </Grid>
                        {deleteMultiple &&
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.dialogCoversastion }>
                              {checkedItems.length} {checkedItems.length>0?"conversations":"conversation"}, including all history will be permanently removed from Workcentivo.
                            </Typography>
                            <Typography variant="h6" className={classes.dialogCoversastionTop}>
                              This action cannot be undone.
                            </Typography>
                          </Grid>
                        }
        
        
                      </Grid>
        
                    </Grid>
                  </DialogContent>
                  <DialogActions className={classes.dialogActionStyles}>
                    <Box display={'flex'} alignItems={'center'}
                     >
                      <Box>
                        <Button onClick={handleCloseDeleteDialog}
                          className={classes.cancelButton}
                        data-test-id="closeDeleteDialogBtn"
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Box>
                        <Button onClick={handleDeleteConfirm}
                          className={classes.deleteButton}
                          data-test-id="deleteDialogButton"
                        >
                          {deleteMultiple ? "Yes, Delete" : "Delete"}
                        </Button>
                      </Box>
                    </Box>
                  </DialogActions>
                </Dialog>
              </>
            )    
          }
        const  uploadAttachmentBlock=()=>{
            return(
               <ScrollableFilesBox id="uploadfilebox">
               <List style={{paddingTop:'0px',paddingBottom:'0px'}} id="uploadfileboxList">
          {selectedFiles.map((file, index) => (
          <ListItem key={index}  style={{borderTop:'1px solid #CBD5E1', paddingTop:'5px',paddingBottom:'5px'}}>
             {selectedFiles.length>0 && ["image/jpeg", "image/png", "image/gif"].includes(file.type) && URL.createObjectURL(file) ?
          <img src={URL.createObjectURL(file)} alt={file.name} className={`${classes.newUploadFileImage}`} />
          : <>{file.type=="application/pdf" && <Typography className={`${classes.fileType} ${classes.fileTypeP}`}>PDF</Typography>}
          {file.type=="application/msword" && <Typography className={classes.fileType}>DOC</Typography>}
          {file.type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" && <NotesIcon fontSize="large" className={classes.documentIconUploadFile} />}
          </>
          
          
          }
           <ListItemText primary={
                <Typography className={classes.fileNameUploadFile}> { file.name}</Typography>
            
             } secondary= {
               <Typography className={classes.fileSizeUploadFile}> 
               { `${(file.size / 1024).toFixed(2)}KB`}
             </Typography>
             }
             />
           <IconButton edge="end" aria-label="delete" onClick={() => handleFileDelete(index)}>
             <CloseIcon />
           </IconButton>
          </ListItem>
          ))}
          </List>
          </ScrollableFilesBox>
            )
          }
          useEffect(() => {
            setReceiveCallback(receive);
            subscribedMessages.forEach((message) => subscribe(message));
            getToken();    
            return () => {
              subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
            };
          }, []);
         
          const getToken = () => {
            const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
            sendBlockMessage(message);
          };

      
        
          useEffect(() => {
            if (getLoginToken()) {
              window.addEventListener("USER_PROFILE_DETAILS", getProfileDetails as EventListener);
              getFocusedUserList('');
              getSubscriptionDetails()
              if(!isProfileDetailsAvailble){
                getUserProfile()
              }
         
            }
          }, []);

          const receive = (from: string, message: Message) => {
            if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
              const { apiRequestCallId, responseJson } = extractNetworkResponse(message);
              if (apiRequestCallId && responseJson) {
                handleApiResponse(responseJson,apiRequestCallId)
              }
            }
          };

        const  handleApiResponse=(responseJson:ResponseObj,apiRequestCallId:string)=>{
            if(responseJson && (responseJson.data || responseJson.chat || responseJson.user_subscription) || responseJson.flag_reasons || responseJson.reported_entity){
              handleResponseWithData(apiRequestCallId,responseJson)
            }
            if(apiRequestCallId === deleteChatApiCallId.current){
              handleDeleteAPIResp(responseJson)  
            }
        
            if(responseJson && !responseJson.errors){
              handleStarUnstarMarkAsUnreadResponse(apiRequestCallId, responseJson)
            }

            if(apiRequestCallId === deleteChatApiCallId.current || apiRequestCallId === getAllNewUsersApiCallId.current
              || apiRequestCallId === createChatApiCallId.current ||
              apiRequestCallId === sendMessageApiCallId.current || apiRequestCallId === getChatDetailsApiCallId.current || 
              apiRequestCallId === markChatMessagesUnreadApiCallId.current ||  apiRequestCallId === starUnstarChatApiCallId.current ||
              apiRequestCallId === addReasonsApiCallId.current || apiRequestCallId === getSubscriptionApiCallId.current 
              ){
                setLoader(false)
                if(responseJson && responseJson.errors){
                  handleTokenError(responseJson,apiRequestCallId)
                }
                if(responseJson && responseJson.error){
                  handleError(responseJson,apiRequestCallId)
                }
                if(responseJson && responseJson.message){
                  handleMessageError(responseJson,apiRequestCallId)
                }
            }
          

          }

         const handleTokenError = (responseJson : ResponseObj, webApiRequestCallId : string) => {
            if(responseJson.errors && responseJson.errors[0].token) {
              setLoader(false)
              toast.error("Token expired, please login", { toastId: 'apiError'})
            }
          }
        const  handleError=(responseJson : ResponseObj, webApiRequestCallId : string)=>{
          if(responseJson.error) {
            setLoader(false)
            toast.error(responseJson.error, { toastId: 'apiError'})
          }
          if(webApiRequestCallId === getSubscriptionApiCallId.current) {
            setChatLimit(0)
            setIsSubscribed(false)
          }
        }
        const  handleMessageError=(responseJson : ResponseObj, webApiRequestCallId : string)=>{
          if(responseJson.message && webApiRequestCallId === sendMessageApiCallId.current) {
            toast.error(responseJson.message, { toastId: 'apiError'})
          }
        }

        const handleResponseWithData=(apiRequestCallId:string,responseJson:ResponseObj)=>{
          if(apiRequestCallId === getSubscriptionApiCallId.current ){
            if(responseJson.user_subscription) {
              setChatLimit(responseJson.user_subscription.data.attributes.status === "active" ? responseJson.user_subscription?.data.attributes.subscription_details.chat_limits : 0)
              setIsSubscribed(responseJson.user_subscription.data.attributes.status === "active")
            }
            else {
              setChatLimit(0)
              setIsSubscribed(false)
            }
          }
          if(apiRequestCallId === reasonApiCallId.current ){
            if(responseJson.flag_reasons) {
              setUserReasons(responseJson.flag_reasons)
            }
          }
          if(apiRequestCallId === addReasonsApiCallId.current ){
              if ('message' in responseJson) {
              setReportClick(false)
              setReportedSuccessfully(true)
              setSelectedReason({
                id: 0,
                reason: "",
                created_at: "",
                updated_at: "",
                otherReason: ""
              })
              if(existingUserListData.length > 0 && responseJson.reported_entity) {
                const reportedChatId = responseJson.reported_entity.data.attributes.chat_id
                let updatedUserList = existingUserListData.map((item) => (Number(item.id) === reportedChatId!) ? {...item, attributes: {...item.attributes, is_reported: true}} : item)
                setExistUsersList(updatedUserList)        
              }
            }
          }
            if(apiRequestCallId === getAllNewUsersApiCallId.current ){
              let data=JSON.parse(JSON.stringify(responseJson.data))
              setNewUsersList(data)
          }
          if(apiRequestCallId === getFousedUsersApiCallId.current && responseJson.data  && (responseJson.data as Array<ChatObj>)){
            let data=JSON.parse(JSON.stringify(responseJson.data))
            handleFousedUserResp(data)  
        }
        if(apiRequestCallId === createChatApiCallId.current && responseJson && responseJson.data && !Array.isArray(responseJson.data) ){
          setCreatedChatName(responseJson.data.attributes.name)
          setchatId(responseJson.data.id)
          chatIdValue=responseJson.data.id
          sendMessageToSelectedUser(responseJson.data.id)
        } 
        if(apiRequestCallId === sendMessageApiCallId.current && responseJson && responseJson.data ){
          setSelectedFiles([])
          setEnterMessage('')   
       if(!isNewMsgMessageValue){
        getChatDetails(chatIdValue, true)
       }
        
        if(isNewMsgMessageValue){
          getFocusedUserList('')
        }
        } 
        
        if(apiRequestCallId === getChatDetailsApiCallId.current && responseJson && responseJson.chat){
          let data=JSON.parse(JSON.stringify(responseJson))
          setLoader(false)
          setMessageListInfo(data)
          scrollToLastMessage()
        }
        if(apiRequestCallId === getUserProfileApiId.current && responseJson && responseJson.data ){
          let data=JSON.parse(JSON.stringify(responseJson.data))
          setProfileDetails(data.attributes)
          setSendMessageOption(data.attributes.send_message_option)
          setStorageData('send_message_option',data.attributes.send_message_option)
        }

        if(apiRequestCallId === sendMessageOptionSetApiCallId.current  && responseJson && responseJson.data){
          let data=JSON.parse(JSON.stringify(responseJson.data))
          setSendMessageOption(data.attributes.send_message_option)   
          setStorageData('send_message_option',data.attributes.send_message_option)  
        }
          }

       const handleFousedUserResp=(data:Array<ChatObj>)=>{
        setUnreadCount(data.filter((chat: ChatObj) => !chat.attributes.is_reported))
        for (const element of data) {
          const {sendMessageToAbly}=sendMessageThroughChannels(element.attributes.name,callGetFetchUserList);
        }
            if(focuedUsersFlow == 3){
              setExistSearchUsersList(data)
            }
            else{
              if(isNewMsgMessageValue && data){
                handleFousedUserRespInNewUserFlow(data)
              }
              else{
                handleFousedUserRespInExistUser(data)
              }   
            }
          }

        const setUnreadCount = (data: Array<ChatObj>) => {
          let unreadCount = data.filter((chat) => chat?.attributes?.unread_messages_count > 0).length
          window.dispatchEvent(new CustomEvent("GET_CHAT_COUNT", {detail: {unreadCount}}))
        }

        const handleFousedUserRespInNewUserFlow=(data:Array<ChatObj>)=>{
               if(data.length>0){
            let existChat=data.find((element:ChatObj)=>element.id==chatIdValue)
                if(existChat){
                  let fistUser=existChat.attributes.account_details.data.find((element:AccountDetailsItemObj)=>existChat?.attributes.current_user_id != Number(element.id))
                  if(existChat.attributes.last_message){
                    setLastMessageTime(existChat.attributes.last_message.attributes.created_at)
                  }
                  if(fistUser){
                    setIsNewMsgMessage(false)
                    setDefaultMsgMessageflow(true)
                    setIsConversationStartWithExistUser(true)
                    setCreatedChatName(existChat.attributes.name)
                    setchatId(existChat.id)
                    setExistUsersList(data)
                    setSelectedUser(fistUser)
                    setAccountId(fistUser.id)
                    setNewMessageFlow(false)
                    chatIdValue=existChat.id
                    setIsUserSelectedInMsgFlow(false)
                    messageToggleDown()
                    getChatDetails(existChat.id, true)
                  }
                }
                else{
                  setExistUsersList(data)
                  setIsNewMsgMessage(false)
                  setIsNewMsgMessage(false)
                  setDefaultMsgMessageflow(false)
                  setIsConversationStartWithExistUser(false)
                  setCreatedChatName('')
                  setchatId('')
                  setSelectedUser(null)
                  setAccountId('')
                  setSelectedVisible(false)
                  setManageConversationClicked(false)
                  setSearchMsgReceptient('')
                }
              }
                if(data.length==0){
                  setExistUsersList([])
                  setIsNewMsgMessage(false)
                  setDefaultMsgMessageflow(false)
                  setIsConversationStartWithExistUser(false)
                  setCreatedChatName('')
                  setchatId('')
                  setSelectedUser(null)
                  setAccountId('')
                  setSelectedVisible(false)
                  setManageConversationClicked(false)
                  setSearchMsgReceptient('')
                }
          }
        
       const handleFousedUserRespInExistUser=(data:Array<ChatObj>)=>{
        if(!isSingleDeleteFlow){
          if(data.length>0){
            let fistUser=data[0].attributes.account_details.data.find((element:AccountDetailsItemObj)=>data[0].attributes.current_user_id != Number(element.id))
            if(data[0].attributes.last_message){
              setLastMessageTime(data[0].attributes.last_message.attributes.created_at)
            }
                if(fistUser){
                  setExistUsersList(data)
                  setSelectedUser(fistUser)
                  setchatId(data[0].id)
                  setCreatedChatName(data[0].attributes.name)
                  setAccountId(fistUser.id)
                  chatIdValue=data[0].id  
                }
          }
          else{
            setExistUsersList([])
            setSelectedUser(null)
            setchatId("")
            setCreatedChatName('')
            setAccountId('')
            chatIdValue=''
          }
       
        }
  if(isSingleDeleteFlow){
    setIsNewMsgMessage(false)
    setDefaultMsgMessageflow(false)
    setManageConversationClicked(false)
    setSelectedVisible(false)
    setIsConversationStartWithExistUser(false) 
    setNewMessageFlow(false) 
    setIsUserSelectedInMsgFlow(false)
    setExistUsersList(data)
    isSingleDeleteFlow=false    
  }        
              }

        const  handleDeleteAPIResp=(responseJson:ResponseObj)=>{
            if(responseJson && responseJson.message=="Chat conversations removed successfully"){
              setCheckedItems([])
              setchatId('')
              chatIdValue=''
              getFocusedUserList('')
            }
          }

  const handleStarUnstarMarkAsUnreadResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === markChatMessagesUnreadApiCallId.current) {
      getFocusedUserList('')
    }
    if (apiRequestCallId === starUnstarChatApiCallId.current) {
      setLoader(false)
      if (existingUserListData.length > 0 && responseJson.chat.data) {
        const reportedChatId = responseJson.chat.data.id

        let updatedUserList = existingUserListData.map(
          (item) => (Number(item.id) === Number(reportedChatId!)) ?
            { ...item, attributes: { ...item.attributes, starred: responseJson.chat.data.attributes.starred } } :
            item)

        setExistUsersList(updatedUserList)
      }

      getChatDetails(chatId, true)
    }
  }
          
  const getReasonsList = () => {
    let token = getLoginToken();
    const header = {
      'Content-Type': "application/json",
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    sendNetworkRequest(
      reasonApiCallId,
      "GET",
      `bx_block_contentflag/contents/get_flag_reasons`,
      header,
    );
  };

  const addReasons1 = () => {
    existingUserListData = existUsersList
    let token = getLoginToken();
    const header = {
      'Accept': 'application/json',
      token: token
    };
 
   let formData = new FormData();
   formData.append("chat_id", chatId);
   selectedReason.otherReason ? formData.append("other_reason", selectedReason.otherReason) : formData.append("reason_id", `${selectedReason.id}`)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    addReasonsApiCallId.current = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_contentflag/contents"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  const callGetFetchUserList=(message:any)=>{
    getFocusedUserList('')
  }

 const getAllUserList = (searchValue:string) => {
    let token = getLoginToken();
    const header = {
      'Content-Type': "application/json",
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    sendNetworkRequest(
			getAllNewUsersApiCallId,
      "GET",
      `account_block/accounts?search_query=${searchValue}`,
			header,
		);
  };
 const sendMessageToSelectedUser = async(chatIdValue?: string) => {
      let token = getLoginToken();
      const header = {
        'Accept': 'application/json',
        token: token
      };
   
     let formData = new FormData();
     if(enterMessage){
      formData.append("message[message]", enterMessage);
     }else{
      formData.append("message[message]", '');
     }
     if(selectedFiles.length){
      setLoaderMessage(true)
      selectedFiles.forEach((element)=>{
        formData.append("message[attachments][]", element);
      })
      
     }

    
    

     const startTime = Date.now();

     const config: AxiosRequestConfig = {
      headers: { token: token ?? '' },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => 
      {
        
        const { loaded, total } = progressEvent;
  
        if (total) {
          const percentCompleted = Math.round((loaded * 100) / total);
          const currentTime = Date.now();
          const elapsedTime = (currentTime - startTime) / 1000;
  
          const uploadSpeed = loaded / elapsedTime;
          const remainingTime = (total - loaded) / uploadSpeed;
  
          setProgressMessage(percentCompleted)
          setRemainingTimeMessage(remainingTime)
        }
      }
    };
  
  
    axios.post(`${baseURL.baseURL}/bx_block_chat/messages?chat_id=${chatId || chatIdValue}`, formData, config)
    .then(response => { 
      const {sendMessageToAbly}=sendMessageThroughChannels(createdChatName,callGetFetchUserList);
      sendMessageToAbly(enterMessage)
      setSelectedFiles([])
      setEnterMessage('')   
   if(!isNewMsgMessageValue ){
    getChatDetails(chatId, true)
   }
    if(isNewMsgMessageValue){
      getFocusedUserList('')
    }

    })
    .catch(error => { 
  if(error.response?.data?.errors[0]?.token){
    toast.error("Token expired, please login", { toastId: 'apiError'})
  }
    })
    .finally(() => {
      setLoaderMessage(false)
      setProgressMessage(0)
      setRemainingTimeMessage(0)
    });
  };

  const getSubscriptionDetails=()=>{
    let token = getLoginToken();
    const header = {
      'Content-Type': "application/json",
      token: token
    };

    sendNetworkRequest(
      getSubscriptionApiCallId,
      "GET",
      `bx_block_subscriptions/subscriptions/user_subscription_details`,
      header,
    );
  }


  const getChatDetails=(chatId:string, markRead: boolean | null = null)=>{
    let token = getLoginToken();
    const header = {
      'Content-Type': "application/json",
      token: token
    };
    setLoader(true)
    sendNetworkRequest(
      getChatDetailsApiCallId,
      "GET",
      `bx_block_chat/chats/${chatId}?is_mark_read=${markRead}`,
      header,
    );

  }
  const deleteChat=()=>{
    let token = getLoginToken();
    const header = {
      'Content-Type': "application/json",
      token: token
    };
    let formData = new FormData();
    if(deleteMultiple){
      formData.append("chat_ids", JSON.stringify(checkedItems));
    }
   else{
    formData.append("chat_ids", JSON.stringify([chatId]))
   }
   const jsonObject:any = {};
   formData.forEach((value, key) => {
     if (jsonObject[key]) {
       if (!Array.isArray(jsonObject[key])) {
         jsonObject[key] = [jsonObject[key]];
       }
       jsonObject[key].push(value);
     } else {
       jsonObject[key] = value;
     }
   });


    sendNetworkRequest(
      deleteChatApiCallId,
      "DELETE",
      `bx_block_chat/chats/delete_chats`,
      header,
      jsonObject  // formData
    );

  }
 const  getFocusedUserList = (searchValue:string) => {

    let token = getLoginToken();
    const header = {
      'Content-Type': "application/json",
      token: token
    };

    sendNetworkRequest(
      getFousedUsersApiCallId,
      "GET",
      searchValue?`bx_block_chat/chats?search_query=${searchValue}`:`bx_block_chat/chats`,
      header,
    ); 
  };
const  createChat=(accountId:string)=>{
    let token = getLoginToken();
    const header = {
      'Content-Type': "application/json",
      token: token
    };
 
   let formData = new FormData();
   formData.append("chat[name]", uuidv4());

   const jsonObject:any = {};

   formData.forEach((value, key) => {
     const keys = key.split('[').map(k => k.replace(']', ''));
     keys.reduce((acc, curr, index) => {
       if (index === keys.length - 1) {
         acc[curr] = value;
       } else {
         acc[curr] = acc[curr] || {};
       }
       return acc[curr];
     }, jsonObject);
   });

   sendNetworkRequest(
    createChatApiCallId,
    "POST",
    `bx_block_chat/chats?account_id=${accountId}`,
    header,
    jsonObject
  );
  }
  
 const markMessagesUnread=()=>{
    let token = getLoginToken();
    const header = {
      'Content-Type': "application/json",
      token: token
    };
 
    sendNetworkRequest(
      markChatMessagesUnreadApiCallId,
      "PUT",
      `bx_block_chat/chats/${chatId}/mark_unread`,
      header,
    );
  }

  const starUnstarChat=(event: {stopPropagation: () => void})=>{
    event.stopPropagation();
    setViewThreeDotsSelectedUser(false);
    existingUserListData = existUsersList

    let token = getLoginToken();
    const header = {
      'Content-Type': "application/json",
      token: token
    };

    let formData = new FormData();
    formData.append("account_id", accountId.toString()); 

    const jsonObject: any = {};

    formData.forEach((value, key) => {
      jsonObject[key] = value;
    });
 
    sendNetworkRequest(
      starUnstarChatApiCallId,
      "PUT",
      checkForStar() ? `bx_block_chat/chats/${chatId}/unstar_chat` : `bx_block_chat/chats/${chatId}/star_chat`,
      header,
      jsonObject
    );
  }

  const getUserProfile=()=>{
    let token = getLoginToken();
    if(!token){
      return false;
    }
    const header = {
      'Content-Type': "application/json",
      token: token
    };
 
    sendNetworkRequest(
      getUserProfileApiId,
      "GET",
      `bx_block_profile/profiles`,
      header,
    );
  }

  const setsendMessageOptionInfo = (option:string) => {
    let token = getLoginToken();
    const header = {
    'Content-Type': "application/json",
    token: token
    };
    
    let formData = new FormData();
    formData.append("send_message_option",option);
    const jsonObject:any = {};
    formData.forEach((value, key) => {
      jsonObject[key] = value;
    });

    sendNetworkRequest(
      sendMessageOptionSetApiCallId,
      "PUT",
      `bx_block_profile/profiles/set_send_message_option`,
      header,
      jsonObject
    );
  
    };
    


 const handleOpenImageModal=(imageUrl:string)=>{
  setImageModalOpen(true)
  setPreviewImageUrl(imageUrl)
  }

  const handleImageModalClose=()=>{
    setImageModalOpen(false)
  }

  const handleNavigationToSubscription = () => {
    handleNavigation("/Customisableusersubscriptions")
  }
     

 const disbableSendButton=()=>{
  let enterMessageText = enterMessage.trim();
    if(chatId){
      if(enterMessageText && selectedFiles.length>0){
        return false
      }
      else if(enterMessageText=='' && selectedFiles.length>0){
        return false
      }
      else if(enterMessageText && selectedFiles.length>0){
  return false
      }
      else if(enterMessageText=="" && selectedFiles.length==0){
        return true
      }
    }
    else{
      return true;
    }
  }
 const downloadFile=(url:string, fileName:string)=>{
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.target="_blank"
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

 const handleFileDelete = (index:number) => {
   let newFiles=selectedFiles.filter((file, i) => i !== index)
   setSelectedFiles(newFiles)
   if(newFiles.length<=2){
    let height=newFiles.length*67 // 73
    const messageBox=document.getElementById('messageBoxexistUser');
    if(messageBox){
      messageBox.style.maxHeight=`${252-height}px`
    }

    const newMessageBox= document.getElementById('newMsgMessagegriditem1'); 
    if(newMessageBox){
      newMessageBox.style.height=`${195-height}px` 
    }

    const emptyBox= document.getElementById('emptyBox'); 
    if(emptyBox){
      emptyBox.style.height=`calc(100vh  - ${height}px - 453px)`
    }

  }
  };
  const getProfileDetails = (event: CustomEvent<any>) => {
    setIsProfileDetailsAvailble(true)
    setProfileDetails(event.detail.attributes)
    setSendMessageOption(event.detail.attributes.send_message_option)
}

const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>)=>{
  const target = event.target as HTMLInputElement;
  setEnterMessage(target.value)
  let checksendbtnStatus=disbableSendButton()
    if (event.key === 'Enter' && sendMessageOption=="Press Enter to Send" && !checksendbtnStatus) {
      event.preventDefault();
      if(target.value.trim()!='' || selectedFiles.length>0){
        checkForNewUser()
      }
   
  }
 }

 const scrollToLastMessage=()=>{
  let element=document.getElementById("messageBoxexistUser")
  if(element){
    const toplast = element.lastElementChild;
    if(toplast){
      setTimeout(() => {
        toplast.scrollIntoView();
      }, 500);
    }
  }
}
const isUnreadMessagesPresent=(item:ChatObj)=>{
  if (item.attributes.unread_messages_count && item.attributes.unread_messages_count > 0) {
    return true
  }
  else {
    return false
  }
}        

    return (
        <>   
         <Accordion className={classes.accordianroot}>
      <AccordionSummary
        expandIcon={<ExpandLessIcon  className={classes.msgExpandIcon}/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordiansummary}
      >
       
            {!defaultMsgMessageflow &&
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', padding: '14px 0px 14px 21px' }}>
                {!isConversationStartWithExistUser &&
                  <>
                    <Avatar src={profileDetails.photo || '' } alt={'Photo'}  className={classes.accordianHeadImage}/>

                    <Typography className={classes.messageHeading}>
                      Messaging
                    </Typography>
                    <Tooltip title="New Message">
                    <IconButton size="small" className={classes.editIconBtn}>
                      <EditIcon className={classes.editIcon}  data-test-id="msgStartNewmessageFlow" onClick={(e) => handelNewMessage(e)} />
                    </IconButton>
                    </Tooltip>
                    {(isSubscribed || existUsersList.filter((chat: ChatObj) => !chat.attributes.is_reported).length > 0) && <Box onClick={(e) => openViewThreeDotsFlotMessage(e)} position={'relative'}>
                      <img src={threeDotsIcon} className={classes.iconSizeAccordian} ref={anchorRef} />
                      {viewThreeDotsFlotMessageStatus && renderViewThreeDots()}
                    </Box>}
                  </>
                }
              </Box>
            }
            {defaultMsgMessageflow && isConversationStartWithExistUser && 
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', padding: '14px 0px 14px 21px' }}>
  <>
  {selectedUser && 
            <Avatar src={selectedUser?.attributes?.profile_details?.data?.attributes?.photo}  alt={"Profile photo"}  className={classes.accordianSelectedUserHeadImage} />
  }
       <Box style={{flex:1}}>
       <Typography data-test-id="startCoversastion" className={classes.messageHeadingUser}>
         {selectedUser?.attributes?.profile_details?.data?.attributes?.full_name}
       </Typography>
       <Typography className={classes.headingUserTitel}>{selectedUser?.attributes.profile_details.data.attributes.current_title}</Typography>
       </Box>
     
       <IconButton size="small">
         <img src={threeDotsIcon} className={classes.iconSize} onClick={(event)=>toggleSelectedUerActions(event)}  ref={anchorUserRef}/>
         {viewThreeDotsSelectedUser && renderChatViewThreeDots()}
       </IconButton>
       <IconButton size="small" className={classes.iconSizeCancelBtn}>
       <img src={cancelIcon} data-test-id="cancelIcon" className={classes.iconSizeCancel} onClick={(event)=>showDefaultFlow(event)} />
       </IconButton>
     </>
  </Box>
            }
             

  {isNewMsgMessage && 
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', padding: '19px 0px 19px 21px' }}>
                <>
                  <Typography className={classes.messageHeading1}>
                    New Message
                  </Typography>
                  <Box>
                  <img src={cancelIcon} data-test-id="cancelIcon" className={classes.iconSize} onClick={(event)=>showDefaultFlowFromNewMessageFlow(event)} />
                </Box>
                </>
                {
                  isConversationStartWithExistUser &&
                  <>
                  {selectedNewUserInfo && selectedNewUserInfo.attributes.profile_details.data.attributes.photo && 
                   <img src={selectedNewUserInfo.attributes.profile_details.data.attributes.photo} alt="png" className={classes.accordianHeadImage} />
                  }
                   {selectedNewUserInfo && 
                       <Typography data-test-id="startCoversastion" className={classes.messageHeading}>
                       {selectedNewUserInfo.attributes.profile_details.data.attributes.full_name}
                      </Typography>
                   }
                    <IconButton size="small">
                      <img src={threeDotsIcon} className={classes.iconSize} />
                    </IconButton>
                  </>
                }
              </Box>
  }
       
   
      </AccordionSummary>
      <AccordionDetails className={classes.accordianDetails}>
      <Grid container item md={12} className={classes.gridcontainer1}>
              <Grid item md={12} xs={12}>
                {isNewMsgMessage && !isUserSelectedInMsgFlow && 
                 <Box id="newMsgMessagegriditem1" className={classes.newMsgMessagegriditem1}>
                        <Box position={'relative'} padding={'10px 21px'}>
                        {renderMsgSearchBox('newMsg')} 
                        {searchMsgPopUpStatus && renderNewUserSearchPopup('newMsg')}
                        </Box>
           
                 </Box>
                }
                  {!defaultMsgMessageflow &&
                    <Box className={classes.griditem1}>
                      {!selectedVisible &&
                       <Box position={'relative'} padding={'10px 40px'}>
                       {renderMsgSearchBox("defaultMsg")}
                       {searchMsgPopUpStatus && renderMsgSearchPopup('defaultMsg')}
                        </Box>
                      }
                      {selectedVisible && manageConversationClicked && (
                    <Box margin={'10px'}>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}
                        padding={'10px 20px'}

                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <Box display={'flex'} alignItems={'center'} style={{ gap: '10px' }}>
                          <Box>
                            <img src={cancelIcon} data-test-id="cancelIcon" className={classes.iconSize} onClick={handledeleteDialogClose} />
                          </Box>
                          <Box>
                            <Typography variant="h6" className={classes.typo2}>{`${checkedItems.length} Selected`}</Typography>
                          </Box>
                        </Box>
                        <Box>
                          <img src={deleteIcon}  className={checkedItems.length>0?classes.iconSize:classes.disabledIconSize} data-test-id="deleteIcon" onClick={handleDialogOpen} />
                        </Box>
                      </Box>
                    </Box>
                  )}
                      <Box className={classes.messagebox1}>
                        <Typography className={classes.typo4}>Focused</Typography>
                      </Box>
                    </Box>
                }
                {isUserSelectedInMsgFlow &&
                <>
                       <Grid  data-test-id="selectedUser" id="selectedUser" container className={classes.griditem14} >
                       <Grid item>
                         <Box className={classes.box3}>
                          {selectedNewUserInfo &&
                              <Avatar src={selectedNewUserInfo.attributes.profile_details.data.attributes.photo || '' } alt="png" className={classes.msgSelectedUserImg}  />
                          }
                        
                         </Box>
                       </Grid>
                       <Grid item style={{ flex: 1 }}>
                        {selectedNewUserInfo && 
                        <>
                       <Typography className={classes.messagetypo1}>{selectedNewUserInfo.attributes.profile_details.data.attributes.full_name}</Typography>
                         <Typography className={classes.typo7}>{selectedNewUserInfo.attributes.profile_details.data.attributes.current_title}</Typography>
                        </>}

                       </Grid>
                     </Grid>
                     {loaderMessage ?
                                    <div style={{ zIndex: 100, position: 'absolute', top: '50%', transform: 'translateY(-50%)',right:'25%',backgroundColor:'rgb(203, 213, 225,0.5)', borderRadius:'5px' }}>
                                        <Uploadmedia3 navigation="navigation" id="id" progress={progressMessage} time={remainingTimeMessage} />
                                    </div> 
                                    : null 
                                 } 
                     </>
                }
           
           
              </Grid>
              { defaultMsgMessageflow && !isNewMsgMessage &&
                <Grid item md={12} xs={12} className={classes.messageBox}>
                      {loaderMessage ?
                                    <div style={{ zIndex: 100, position: 'absolute', top: '50%', transform: 'translateY(-50%)',right:'25%',backgroundColor:'rgb(203, 213, 225,0.5)', borderRadius:'5px' }}>
                                        <Uploadmedia3 navigation="navigation" id="id" progress={progressMessage} time={remainingTimeMessage} />
                                    </div> 
                                    : null 
                                 } 
                                      {loader &&
                      <div style={{ zIndex: 100, position: 'absolute', top: '50%', transform: 'translateY(-50%)',right:'35%', borderRadius:'5px' }}>
                         <CircularProgress />
                      </div> 
                   } 
                    <ScrollableMessageBox id="messageBoxexistUser">
                  {messageListInfo?.chat?.data?.attributes?.messages.map((message:MessageObj)=>
                  <>

            <Grid item key={message.id} style={{paddingTop:'14px'}}>
            <Box display="flex" alignItems="flex-start">
              <Avatar alt={message.attributes.created_by.full_name} src={message.attributes.created_by.profile_image || ''} className={`${classes.messagegriditemImg} ${classes.mr10}`} />
              <Paper elevation={0} style={{flex:1}}>
                <Grid container >
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="body2" className={`${classes.messageText}  ${classes.wordBreakStyle} ${classes.themeColor}`}  >
                        {message.attributes.created_by.full_name}
                      </Typography>
                      <Typography variant="caption" className={classes.messagetime}>
                        {message.attributes.created_at}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={11}>
                   {message?.attributes?.message && message?.attributes?.message !="''" && <Typography variant="body1" className={`${classes.messageTextOne} ${classes.textColor}`}>{message?.attributes?.message}</Typography>} 
                    {message.attributes.attachments && Array.isArray(message.attributes.attachments) && message.attributes.attachments.map((attachment:AttachmentObj)=>
                         <> 
                         {
                          (attachment.url.includes('.jpg') || attachment.url.includes('.png') || attachment.url.includes('.gif') ||  attachment.url.includes('.jpeg')) && 
                          <img src={attachment.url} alt="png" className={classes.uploadImage1} data-test-id="openImageModal" onClick={()=>handleOpenImageModal(attachment.url)}/>
                         }
                        {
                          (attachment.url.includes('.docx') || attachment.url.includes('.doc') || attachment.url.includes('.pdf')) && 
                          <ChatAttachments url={attachment.url} downloadFile={(url:string, filename:string)=>downloadFile(url,filename)}/>
                        }

                         </>
                                                   
                        )}
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
                  </>
                  )}
                        </ScrollableMessageBox>
                    </Grid>
                  }
            
              {
                !defaultMsgMessageflow && 
                <Grid item md={12} xs={12}>
                <Box className={classes.messageGriditem5}>
                { (!isSubscribed || (existUsersList.filter((chat: ChatObj) => !chat.attributes.is_reported).length <= 0)) && 
                  <Box style={{height: "100%", display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', textAlign: 'center',}}>
                    {isSubscribed && <Typography  className={classes.typo4} style={{textAlign: "center"}}>No messages</Typography>}
                    {!isSubscribed && <Button variant="contained" className={classes.subscribeBtn} onClick={() => handleNavigationToSubscription()}>Subscribe Now</Button>}
                  </Box>
                  }
                  {isSubscribed && existUsersList.filter((chat: ChatObj) => !chat.attributes.is_reported).map((item:ChatObj, index) => {
                      const userInfo= item.attributes.account_details.data.find((selectItem:AccountDetailsItemObj)=>item.attributes.current_user_id != Number(selectItem.id))
                      return(
                        <>
                        {userInfo &&
                    <Grid
                      container
                      style={{
                        height: "auto",
                        borderBottom: "1px solid #d3d3d3",
                        padding: "8px 0px",
                        display: "flex",
                        flexDirection: "row",
                        transition: "background-color 0.3s, border-color 0.3s",
                        backgroundColor: !checkForBlur(index) && hoveredMsgItemId === Number(item.id) ? '#d3d3d3' : 'inherit',
                        borderLeft: !checkForBlur(index) && hoveredMsgItemId === Number(item.id) ? '4px solid #6D97C1' : 'inherit',
                        minWidth:"0px",
                        cursor: "pointer",
                        filter: handleCondition(checkForBlur(index), "blur(4px)", "none"),
                        userSelect: "none"
                      }}
                      onMouseEnter={() => handleMsgMouseEnter(Number(item.id))}
                      onMouseLeave={handleMsgMouseLeave}
                      onClick={(event)=> !checkForBlur(index) && handleMsgMessageConversation(event,userInfo,item)}
                      key={item.id}
                      data-test-id="existUsers"
                      >
                      {manageConversationClicked && (
                        <Grid item className={classes.griditem6}>
                          <CustomCheckboxSecondary
                            onChange={(event:{stopPropagation:()=>void}) => handleCheckboxChange(event,Number(item.id))}
                            name={""} checked={checkedItems.includes(Number(item.id))} />
                        </Grid>
                      )}
                      <Grid item>
                        <Box>
                          <Avatar src={userInfo.attributes.profile_details.data.attributes.photo}  alt={"Profile photo"} className={classes.messagegriditem7} />
                        </Box>
                      </Grid>
                      <Grid item className={classes.messagegriditem8}>
                        <Typography className={classes.messagetypo5}>{userInfo.attributes.profile_details.data.attributes.full_name}</Typography>
                        {item.attributes.last_message?.attributes?.message && 
                        <Typography className={classes.messageTypo7}>{item.attributes.last_message?.attributes?.message}</Typography>   
                  }           
                      </Grid>
                      <Grid item className={classes.griditemTime }>
                        <Typography className={classes.messagetypo8}>{item.attributes.last_message?.attributes?.created_at}</Typography>
                        <div className={classes.favouriteInfo}>
                        {isUnreadMessagesPresent(item) &&  
                           <Typography component="span" className={classes.typoCount} data-test-id="unreadMessagesCount" >{item.attributes.unread_messages_count}</Typography>
                        }
                        {item.attributes.starred &&  <StarIcon style={{color:"#1F497D",width:"0.8rem",height:'0.8rem'}}  data-test-id="starIcon"
                                   onClick={(e) => {
                                    e.stopPropagation(); 
                                    starUnstarChat(e); 
                                  }}
                        
                        />}
                     
                      </div>
                      </Grid>
                    </Grid>
                  }
                  </>
                      )
})}
                </Box>
              </Grid>
              }

              {
                (isNewMsgMessage || defaultMsgMessageflow) &&
                  <Grid item xs={12}>
                    {selectedFiles.length>0 && uploadAttachmentBlock()}
                    <Grid container className={classes.gridMsgcontainer2}>
                      <Grid item xs={12} sm={10} md={10} lg={11}>
                        <Box className={classes.messageBoxContainer}>
                          <CustomizedMsgTextField
                            className={classes.TextField3}
                            variant="outlined"
                            placeholder="Write a message ..."
                            multiline
                            fullWidth
                            value={enterMessage}
                            onChange={(e)=>handelMessage(e)}
                            maxRows={maxRows}
                            minRows={maxRows}
                            id="textarea"
                            onKeyPress={(event)=>handleKeyPress(event)}
                          />

                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={2} md={2} lg={1} className={classes.griditem16}>
                    {toggleMessageBox()}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ padding: "17px 40px" }}>
                     {fileAttachmentBlock()}
                     {sendButtonsBlock()}
                    </Grid>
                  </Grid>
              }
           
            </Grid>
      </AccordionDetails>
    </Accordion>
    {renderDeleteModel()}
    {renderReportModal()}
    {renderReportMessageModal()}
    <Dialog   data-test-id="imageModal" open={imageModalOpen} onClose={()=>handleImageModalClose()} maxWidth="lg" fullWidth>
                    <IconButton  data-test-id="modalCloseIcon" onClick={()=>handleImageModalClose()} style={{ position: 'absolute', right: 0, top: 0 }}>
                        <CloseIcon />
                    </IconButton>
                    <img src={previewImageUrl} style={{ width: '100%', height: '100%'  }}  />
                </Dialog>
        </>
    )
}

export default FloatingChat;