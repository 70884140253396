import React from "react";

// Customizable Area Start
import { Helmet } from 'react-helmet';
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
  TextField,
  FormControlLabel,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  ClickAwayListener,
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import OtpInput from 'react-otp-input';
import { createStyles, createTheme, makeStyles, styled, Theme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import * as Yup from 'yup';
import { Formik } from "formik";
import AppTimmer from "./AppTimer.web";
import { Image } from "@material-ui/icons";
import { ToastContainer } from "react-toastify";
import { dropdown } from "./assets";
import { META_TAGS, PasswordScema } from "../../../../packages/components/src/Utils.web";


const SignUpBlock = styled(Box)(({ }) => ({
  '& .heading': {
    '@media (max-width: 960px)': {
      marginLeft: '0 !important',
      marginTop: '30px',
      textAlign: 'center'
    },
  },
  '& .bgImage': {
    '@media (max-width: 960px)': {
      display: 'none'
    }
  },
  '& .formContainer': {
    paddingLeft: '20px', 
    paddingRight: '20px', 
    flexGrow: 0, 
    maxWidth: '37%', 
    flexBasis: "37%", 
    display: "flex", 
    alignItems: "center",
    '@media (max-width: 960px)': {
      flexGrow: 0,
      maxWidth: '83.333333%',
      flexBasis: '83.333333%',
    }
  },
  "& .roleInput": {
    "& .MuiOutlinedInput-inputAdornedEnd": {
      cursor: "pointer",
      caretColor: "transparent"
    }
  }
}))
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { backgroundImg } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  validationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter correct mail id').required('Email is required'),
    password: PasswordScema,
    confirmPass: Yup.string().oneOf([Yup.ref('password'), null], 'Password must match').required('Confirm Password is required')
  });
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
                <Helmet>
          <meta
            name="description"
            content={`
            Find Venture Capital Experts: Join Our Recruiting Platform 
            Infrastructure Investment Opportunities on Our Platform 
            Connect with Venture Capital Leaders on Our Platform 
            Recruit for Infrastructure Investment: Join Our Platform 
          `}
          />
          <meta
            name="keywords"
            content={META_TAGS}
          />
        </Helmet>
        <Container maxWidth={false} style={{ width: '100%', padding: '0px' }}>
          <SignUpBlock 
            sx={{ 
              bgcolor: '#6D97C1', 
              height: '100vh', 
              display: { md: 'flex', lg: 'flex', xl: 'flex' }, 
              justifyContent: 'center', 
              alignItems: 'center' 
              }}>
            <Grid container justifyContent="center" style={{ background: '#6D97C1', flexWrap: 'wrap-reverse' }}>
              <Grid item xs={10} className="formContainer">
                <Formik
                  enableReinitialize={true}
                  data-testid='formik'
                  initialValues={{
                    email: '',
                    password: '',
                    confirmPass: '',
                    role: this?.state?.avilableRoles?.[0]?.id,
                  }}
                  validationSchema={this.validationSchema}
                  validateOnBlur={true}
                  validateOnChange={true}
                  onSubmit={(values, actions) => {
                    if (this.state.emailVarified) {
                      this.handleCreateAccount(values);
                    }
                  }}
                >
                  {({ setFieldValue, values, errors, touched, handleChange, handleSubmit,handleBlur}) => (
                    <form onSubmit={handleSubmit}>
                      {this.handleCondition(this.state.showVerifiyEmail, <Box
                          sx={{
                            bgcolor: '#EEECE1',
                            padding: '40px',
                            borderRadius: '20px',
                            color: "#1F497D"
                          }}
                        >
                          <Typography style={{
                            color: "#1F497D",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontFamily: "Arial",
                            cursor: "pointer"
                          }}
                            data-test-id={"btnGoToLogin"}
                            onClick={() => this.goToLoginPage()}
                          >
                            Back to Login
                          </Typography>
                          <Typography style={{
                            color: "#1F497D",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            marginTop: "42px",
                            fontFamily: "Arial"
                          }}>
                            Check your email
                          </Typography>
                          <Typography style={{
                            color: "#1F497D",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            marginTop: "10px",
                            marginBottom: "20px",
                            fontFamily: "Arial",
                            textOverflow: "ellipsis",
                            flexWrap: "wrap",
                            overflow: "hidden",
                          }}>
                            We have {this.handleCondition(this.state.resend, 'resend', 'sent')} an OTP to {values.email}
                          </Typography>
                          {this.handleCondition(this.state.otpError, <Box sx={{
                            marginTop: "10px",
                            marginBottom: '10px',
                            bgcolor: '#FEE2E2',
                            borderLeft: '4px solid #DC2626',
                            padding: '15px 10px 15px 10px'
                          }}
                          >
                            Invalid OTP entered
                          </Box>, "")}
                          <Box style={{marginTop: "0px"}}>
                            <Grid container spacing={4} style={{ margin: "0px"}}>
                              <OtpInput
                                value={this.state.otp}
                                data-test-id = {"handleChangeOTP"}
                                onChange={(e) => this.handleChangeOTP(e)}
                                numInputs={6}
                                shouldAutoFocus={true}
                                inputStyle={{
                                  border: `1px solid ${this.handleCondition(this.state.otpError, '#DC2626', '#9C9C9C')}`,
                                  borderRadius: "5px",
                                  width: "44px",
                                  height: "44px",
                                  fontSize: '0.85rem',
                                  color: "#000",
                                  gap: "8px"
                                }}
                                containerStyle={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  width: '100%',
                                  // marginTop: '30px',
                                  paddingLeft: '10px',
                                  paddingRight: '10px'
                                }}
                                renderInput={(props) => <Grid item xs={1}><input {...props} /></Grid>}
                              />
                            </Grid>
                          </Box>
                          <Box style={{ marginTop: "20px" }}>
                            <AppTimmer handleShowVerifyEmail={() => { this.handleShowVerifyEmail(values.email, true) }} handleOTPTimmer={(val: boolean) => this.handleOTPTimmer(val)} />
                            <Grid container justifyContent="flex-end">
                              <Grid item md={4}>
                                <Button
                                  data-test-id={"btnVerifyOTP"}
                                  variant="contained"
                                  style={{
                                    background: `${this.handleCondition(this.state.otp.length < 6 || !this.state.timmer,"#CBD5E1", "#6D97C1")}`,
                                    color: "white",
                                    textTransform: 'capitalize',
                                    fontSize: "16px",
                                    marginTop: '10px',
                                    fontWeight: 700,
                                    fontFamily: "Arial"
                                  }}
                                  fullWidth
                                  disabled={this.state.otp.length < 6 || !this.state.timmer}
                                  onClick={() => this.doEmailVarification(values.email)}
                                >
                                  Verify {this.handleCondition(this.state.verifyloader, <CircularProgress style={{ color: 'white', marginLeft: '2px' }} size="1rem" />, "")}
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>, <Box
                          sx={{
                            bgcolor: '#EEECE1',
                            padding: '40px',
                            borderRadius: '20px',
                            color: "#1F497D"
                          }}
                        >
                          <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                              <Typography style={{
                                color: "#1F497D",
                                fontSize: "24px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontFamily: "Arial"
                              }}>
                                {this.labelTitle}
                              </Typography>
                            </Grid>
                            <Grid item >
                              <Typography style={{
                                color: "#A6B0B",
                                fontSize: "13px",
                                fontFamily:"Arial",
                                fontStyle: "normal",
                                fontWeight: 400,
                              }}>

                                <span
                                  data-test-id={"btnGoToLoginPage"}
                                  onClick={() => this.goToLoginPage()}
                                  style={{
                                    color: "#1F497D",
                                    fontStyle: "Arial",
                                    fontWeight: 500,
                                    cursor: "pointer",
                                    fontSize: "15px"
                                  }}>Login</span>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box sx={{ width: "100%", paddingTop: "22px" }}>
                            <Box sx={{ width: "100%", paddingTop: "20px" }}>
                              <Typography style={{ fontSize: "14px", fontWeight:"bold",fontFamily:"Arial", marginBottom: "4px"}}>Email</Typography>
                              <FormControl variant="outlined" fullWidth size="small" style={{ background: '#FFF',borderRadius:"8px",border:"1px solid  #CBD5E1" }}>
                                <OutlinedInput
                                  id="outlined-adornment-password21"
                                  placeholder="Enter your id here"
                                  data-testId = {"OutlinedInputPassword21"} 
                                  type="text"
                                  name="email"
                                  value={values.email}
                                  onBlur={handleBlur("email")}
                                  disabled={this.state.emailVarified}
                                  onChange={(e) => {
                                    setFieldValue("email", e.target.value);
                                    this.handleOnChangeEmail();
                                  }}
                                  error={!!(touched?.email && errors.email)}
                                  inputProps={{
                                    style: {
                                      fontSize: '14px',
                                    }
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      {values.email && !errors.email && !this.state.emailVarified && (
                                        <span
                                          data-test-id={"btnEmailVerification"}
                                          style={{
                                            color: 'white',
                                            textTransform: 'capitalize',
                                            fontSize: "14px",
                                            cursor: 'pointer',
                                            fontWeight: 700,
                                            backgroundColor: '#6D97C1',
                                            borderRadius: '8px',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: "5px, 20px",
                                            width: '67px',
                                            height: '28px',
                                            fontFamily: 'Arial'
                                          }}
                                          onClick={() => this.handleShowVerifyEmail(values.email, false)}
                                        >
                                          Verify
                                        </span>)}
                                        {this.handleCondition(this.state.emailVarified, <span
                                        data-test-id={"btnEmailVerification"}
                                        style={{
                                          color: "#6D97C1",
                                          textTransform: 'capitalize',
                                          fontSize: "0.8rem",
                                          cursor: 'pointer',
                                          fontWeight: 700,
                                        }}
                                      >
                                        Verified
                                      </span>, "")}
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              {!!(touched?.email && errors.email) && <Typography style={{ fontFamily: "Arial", color: 'red', fontSize: "0.8rem", textAlign: 'right', display: 'block ruby', fontWeight: 400 }}>{errors.email}</Typography>}
                              {this.state.emailExist !== '' && values.email && !errors.email && <Typography style={{ fontFamily: "Arial", color: 'red', fontSize: "0.8rem", textAlign: 'right', display: 'block ruby', fontWeight: 400 }}>{this.state.emailExist}</Typography>}
                              {!this.state.emailExist && !this.state.emailVarified && values.email && !errors.email && <Typography style={{ fontFamily: "Arial", color: 'red', fontSize: "0.8rem", textAlign: 'right', display: 'block ruby', fontWeight: 400 }}>Please verify your email</Typography>}
                            </Box>
                            <Box sx={{ width: "100%", paddingTop: "20px" }}>
                              <Typography style={{ fontSize: "14px", fontWeight:"bold",fontFamily:"Arial", marginBottom: "4px" }}>Password</Typography>
                              <FormControl variant="outlined" fullWidth size="small" style={{ background: '#FFF',borderRadius:"8px",border:"1px solid  #CBD5E1" }}>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  placeholder="*************"
                                  type={this.handleCondition(this.state.showPassword, 'text', 'password')}
                                  name="password"
                                  value={values.password}
                                  error={!!(touched?.password && errors.password)}
                                  onChange={handleChange("password")}
                                  inputProps={{
                                    style: {
                                      fontSize: '12.5px',
                                    }
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        data-test-id={"btnPassEyeVisibility"}
                                        aria-label="toggle password visibility"
                                        onClick={() => this.handleShowPassword(!this.state.showPassword)}
                                        edge="end"
                                      >
                                        {this.handleCondition(this.state.showPassword, <VisibilityOff />, <Visibility />)}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              {this.handleCondition(touched?.password && errors.password, <Typography style={{fontFamily: "Arial", color: 'red', fontSize: "0.8rem", textAlign: 'right', fontWeight: 400, wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal', maxWidth: '100%', }}>{errors.password}</Typography>, "")}
                            </Box>
                            <Box sx={{ width: "100%", paddingTop: "20px" }}>
                              <Typography style={{ fontSize: "14px", fontWeight: 700, fontFamily: "Arial", marginBottom: "4px" }}>Confirm Password</Typography>
                              <FormControl variant="outlined" fullWidth size="small" style={{ background: '#FFF',borderRadius:"8px",border:"1px solid  #CBD5E1" }}>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  placeholder="*************"
                                  type={this.state.showConfPass ? 'text' : 'password'}
                                  name="confirmPass"
                                  error={!!(touched?.confirmPass && errors.confirmPass)}
                                  value={values.confirmPass}
                                  onChange={handleChange("confirmPass")}
                                  inputProps={{
                                    style: {
                                      fontSize: '12.5px',
                                    }
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        data-test-id={"btnConPassEyeVisibility"}
                                        aria-label="toggle password visibility"
                                        onClick={() => this.handleShowConfirmPassword(!this.state.showConfPass)}
                                        edge="end"
                                      >
                                        {this.handleCondition(this.state.showConfPass, <VisibilityOff />, <Visibility />)}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              {this.handleCondition((touched?.confirmPass && errors.confirmPass), 
                              <Typography style={{ color: 'red', 
                              fontSize: "0.8rem", 
                              textAlign: 'right', 
                              display: 'block ruby', fontFamily: "Arial",
                              fontWeight: 400 }}>{errors.confirmPass}</Typography>, 
                              "")}
                            </Box>
                            <Box sx={{ width: "100%", paddingTop: "20px" }}>
                            <Typography style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Arial", marginBottom: "4px" }} >Roles</Typography>
                            <ClickAwayListener onClickAway={this.handleRoleClose}>
                            <TextField variant="outlined"
                              className="roleInput"
                              fullWidth size="small"
                              onClick={this.handleRoleClick}
                              name="role"
                              value={this.state.avilableRoles.filter((item) => item.id === values.role)[0]?.attributes?.name === "Candidate" ? "Job Applicant" : this.state.avilableRoles.filter((item) => item.id === values.role)[0]?.attributes?.name}
                              style={{ background: '#FFF', borderRadius: "8px",border:"1px solid  #CBD5E1"}}
                              error={this.handleCondition(touched?.role , errors.role, "")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                    <img src={dropdown} alt="dropdown" onClick={this.handleRoleClick}/>
                                </InputAdornment>
                              ),
                              style: {
                                cursor: "pointer",
                                fontFamily: 'Arial',
                                fontWeight: '400',
                                color: this.handleCondition(values.role, '#64748B', '#A6B0BF')
                              }
                            }}>
                            </TextField>
                            </ClickAwayListener>
                            <Box style={{ position: "relative",
                            paddingTop:"2px" }}>
                              {this.handleCondition(this.state.rolesOpen, <Box style={{ position: "absolute", 
                                width: "100%", zIndex: 2, 
                                background: "#fff",
                                border:"1px solid #CBD5E1",
                                borderRadius:"8px" }}>
                                  {this.state.avilableRoles.map((item: any) => {
                                    return <MenuItem
                                      value={values.role}
                                      data-testId ={"roles"}
                                      onClick={() => {
                                        setFieldValue("roleId", item.id);
                                        setFieldValue("role", item.id);

                                        this.setState({ rolesOpen: false });
                                      }}
                                      style={{ fontSize: '14px', 
                                      fontFamily: 'Arial',
                                       color: "#64748B", 
                                       fontWeight: '400' }}>
                                        {this.handleCondition(item?.attributes?.name === 'Candidate', 'Job Applicant', item?.attributes?.name)}
                                    </MenuItem>
                                  })}
                                </Box>, "")}
                            </Box>
                          </Box>
                            <Box style={{ marginTop: '20px' }}>
                              <Grid item md={9} style={{ width: "calc(100% - 113px)" }}>
                                <Typography style={{ color: "#6D97C1", fontSize: '14px',  marginRight: "25px", fontFamily: 'Arial' }}>
                                  Create your account or login. By continuing, you agree to our <span style={{ color: "#1F497D", cursor: 'pointer' }} data-test-id ={"terms"} onClick={() => this.goToTermsAndCondition('terms')}>Terms of Use</span> and <span style={{ color: "#1F497D", cursor: 'pointer' }} data-test-id ={"privacy"} onClick={() => this.goToTermsAndCondition('privacy')}>Privacy Policy.</span>
                                </Typography>
                              </Grid>
                              <Grid item md={3} style={{ display: 'flex', justifyContent: 'end', maxWidth: 'none', marginTop: "10px" }}>
                                <Button
                                  disabled={this.state.signUpLoader}
                                  data-test-id={"btnSingUp"}
                                  type="submit"
                                  variant="contained"
                                  style={{
                                    background: this.handleCondition(!this.state.signUpLoader, "#6D97C1", "#CBD5E1"),
                                    color: "white",
                                    textTransform: 'capitalize',
                                    fontSize: "16px",
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    fontWeight: 700,
                                    padding: "16px",
                                    width: '130px',
                                    fontFamily: 'Arial'
                                  }}
                                >
                                  Sign Up {this.handleCondition(this.state.signUpLoader, <CircularProgress style={{ color: 'white', marginLeft: '8px', width: "16px", height: undefined }}  />, "")}
                                </Button>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>)}
                    </form>
                  )}
                </Formik>
              </Grid>
              <Grid item xs={10} style={{ marginLeft: '5%', flexGrow: 0, maxWidth: '37%', flexBasis: "37%" }} className="heading">
                <Typography
                  style={{
                    color: '#FFFFFF',
                    fontSize: '54px',
                    fontWeight: 600,
                    fontFamily: 'Arial'
                  }}
                > 
                  Work Centivo
                </Typography>
                <img 
                  src={backgroundImg} 
                  alt="login"
                  height="530px"
                  className="bgImage" 
                />
              </Grid>
            </Grid>
          </SignUpBlock>
        </Container>
        <ToastContainer/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

