import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";
import { Platform } from "react-native";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';



// Customizable Area Start
import { buySideIcon, careerDevelopmentIcon, sellSideIcon } from "./assets";
import { toast } from "react-toastify";
import { getUserRole, handleConditionFunction } from "../../../../packages/components/src/Utils.web";

export interface UserReasons {
    id: number,
    reason: string,
    created_at: string,
    updated_at: string
    }

export interface Activity {
    posts: Post,
    comments: Comment,
    reactions: [],
}
export interface ImageOrVideo {
    id: number;
    url: string;
}
export interface Account {
    account_name: string;
    account_profile: string;
}
export interface Post {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        description: string;
        body: string;
        location: string;
        sub_category_id: string;
        sub_topic: string;
        account_id: number;
        category_id: number;
        created_at: string;
        updated_at: string;
        model_name: string;
        images: ImageOrVideo[];
        isliked: boolean;
        isdisliked: boolean;
        likes_count: number;
        dislikes_count: number;
        comment_count: number;
        account: Account;
        comment: Comment[];
    };
}
export interface Comment {
    id: number;
    commentable_type: string;
    commentable_id: number;
    parent_id: number | null;
    comment: string;
    created_at: string;
    updated_at: string;
    likes_count: number;
    dislikes_count: number;
    isliked: boolean;
    isdisliked: boolean;
    comment_images: { id: number | string, url: string }[];
    account: Account;
    reply_count: number;
    reply_to: number | null;
    replies: Reply[];
}
export interface Reply {
    id: number;
    commentable_type: string;
    commentable_id: number;
    parent_id: number | null;
    comment: string;
    created_at: string;
    updated_at: string;
    likes_count: number;
    dislikes_count: number;
    isliked: boolean;
    isdisliked: boolean;
    comment_images: { id: number | string, url: string }[];
    account: Account;
    reply_count: number;
    reply_to: number | null;
    replies: Reply[];
}
export interface UserDetails {
    id?: string,
    bio?: string,
    city?: string,
    email?: string,
    experience?: string,
    full_name?: string,
    resume?: {
        url: string,
        name: string,
        size: number
    } | null,
    photo?: string,
    skills: [string][],
    cover_letter?: {
        url: string,
        name: string,
        size: number,
    } | null,
    full_phone_number?: string,
    open_for_direct_approach: boolean,
    work_experiences: {
        data: []
    },
    account_details: {
        id?: number,
        role: string
    },
}
export interface DealExperience {
    attributes: {
        created_at: string,
        target_name: string,
        buyer_investor_name: string,
        enterprise_value: string,
        deal_type: string,
        borrower_name: string,
        lender_name: string,
        debt_size: string,
    }

}
export interface Deal {
    deal_experiences: {
        data: {
            attributes: {
                created_at: string,
                target_name: string,
                buyer_investor_name: string,
                enterprise_value: string,
                deal_type: string,
                borrower_name: string,
                lender_name: string,
                debt_size: string,
            }

        }[]

    }
}
export interface WorkExperience {
    attributes: {
        position: string,
        specialization: string,
        started_time: string,
        ended_time: string,
        company_name: string,
        id: string,
        currently_working: boolean,
        image: string,
        company_icon_url: string

    }

}
export interface UserReasons {
    id: number,
    reason: string,
    created_at: string,
    updated_at: string
}

interface CurrentPost {
    id: string | number;
    description: string;
    images: any[];
    selectedTopicId: string
}
export interface UserProfileInterface {
    user_profile: {
        data: {
            attributes: {
                company_name: string,
                company_description: string,
                company_website: string,
                company_indusry: string,
                company_size: number,
                company_headquarters: string
                company_founded_year: number,
                company_icon: string,
                photo: string,
                full_name: string,
                current_title: string,
                total_connections: string,
                is_followed: boolean,
                is_reported: boolean,
                connection_request_details: {
                    status: string,
                    id: number,
                    sender_id:number
                },
                skills: string[][],
                id: string,
                account_details: {
                    id: string,
                    role: string
                },
                work_experiences: {
                    data: {
                        attributes: {
                            position: string,
                            specialization: string,
                            started_time: string,
                            ended_time: string,
                            company_name: string,
                            id: string,
                            currently_working: boolean,
                            image: string,
                            company_icon_url: string
                        }

                    }[]
                },
                open_for_direct_approach: boolean
            },
        }
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    loggedId: string | null;
    tabPanelNo: number,
    openConfirmDialogNew: {
        open: boolean,
        onConfirm?: () => void,
        title1: string,
        title2: string
    },
    openEditFeedPost: boolean,
    activeNavTab: number;
    imageList: any[],
    showComment: boolean,
    loading: boolean,
    isReportOpen: boolean,
    isPostImageUploaded: boolean,
    userProfile: UserProfileInterface;
    userDeal: Deal;
    currentPost: CurrentPost,
    showAllPosts: boolean,
    postShowID: string;
    userDetails: UserDetails,
    activityType: string,
    activity: {
        posts: [],
        comments: [],
        reactions: [],
    },
    selectTopicsList: {
        [category: string]: {
            icon: string,
            topics: string[]
        };
    },
    openInvitationDialog: {
        open: boolean;
        receiverId: number | string
    };
    loder: boolean;
    selectedPostId: string | number,
    userLoader: boolean;
    token: string;
    errorMsg: string;
    userID: string;
    loaderComment: boolean;
    expandedPosts: string[];
    progressComment: number,
    remainingTimeComment: number,
    followClick: boolean,
    removeConnectionPopup: boolean,
    withdrawConnection: boolean,
    reportType: string,
    reportClick: boolean,
    openReason: boolean,
    postToDelete: string | number,
    userReasons: UserReasons[]
    selectedReason: {
        id: number,
        reason: string,
        created_at: string,
        updated_at: string,
        otherReason: string
    },
    receiverId: string | number,
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class RecruiterProfileController1 extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    userReceivedId: string = " ";
    deletePostApiId: string = "";
    userLoggedInID: string = "";
    userPostId: string = "";
    commentId: string = "";
    reactionId: string = "";
    userDealId: string = "";
    createFeedPostApiId: string = "";
    handleSavePostApiId: string = "";
    likeID: string = "";
    unlikeID: string = "";
    addCommentID: string = "";
    ignorerequestId: string = "";
    upvoteCommentID: string = "";
    downVoteCommentID: string = "";
    unFollowFromFollowingCallId: string = "";
    removeInvitationPostApiCallId: string = "";
    addFollowerFromFollowingCallId: string = "";
    addConnectionId: string = "";
    withdrawConnectionId: string = "";
    sendInvitationPostApiCallId: string = "";
    removeConnectionId: string = "";
    getAllCommentsForApiId: string = "";
    deleteCommentApiId: string = "";
    reasondId: string = "";
    addReasonsId: string = "";
    followUserPostApiCallId: string = "";
    hidePostApiId: string = "";
    viewProfileApiCallId: string="";

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            // Customizable Area Start
            loggedId:null,
            openEditFeedPost: false,
            isPostImageUploaded: false,
            imageList: [],
            openConfirmDialogNew: {
                open: false,
                title1: "",
                title2: ""
            },
            selectedPostId: 0,
            tabPanelNo: 0,
            loading: false,
            activeNavTab: 0,
            removeConnectionPopup: false,
            withdrawConnection: false,
            openInvitationDialog: {
                open: false,
                receiverId: ""
            },
            isReportOpen: false,
            currentPost: {
                id: '',
                description: '',
                images: [],
                selectedTopicId: ''
            },
            selectTopicsList: this.topicsList,
            userDetails: {} as UserDetails,
            postToDelete: "",
            userProfile: {} as UserProfileInterface,
            userDeal: {
                deal_experiences: {
                    data: []
                }
            },
            loder: false,
            userLoader: false,
            token: "",
            errorMsg: "",
            postShowID: "",
            activityType: "posts",
            showComment: false,
            showAllPosts: false,
            userID: "",
            activity: {
                posts: [],
                comments: [],
                reactions: [],
            },
            loaderComment: false,
            progressComment: 0,
            remainingTimeComment: 0,
            followClick: false,
            reportType: "",
            reportClick: false,
            openReason: false,
            userReasons: [{
                id: 1,
                reason: "string",
                created_at: "string",
                updated_at: "string"
            }],
            selectedReason: {
                id: 0,
                reason: "",
                created_at: "",
                updated_at: "",
                otherReason: ""
            },
            expandedPosts: [],
            receiverId:'',
            // Customizable Area End
        };
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
        ];
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForUserProfile(from, message);
        this.handleResponseForLoggedUserProfile(from, message);
        this.handleResponseForUserPostActivity(from, message);
        this.handleResponseForUserCommentActivity(from, message);
        this.handleResponseForUserReactiontActivity(from, message);
        this.handleResponseForUserDeal(from, message);
        this.handleResponseSavePostApi(from, message);
        this.handleResponseForUpVoteApi(from, message);
        this.handleResponseForDownVoteApi(from, message);
        this.handleResponseForIgnoreRequest(from , message);
        this.handleResponseForAddCommentAPi(from, message);
        this.handleResponseForcommentLikeAPi(from, message);
        this.handleResponseForcommentDislike(from, message);
        this.handleResForUnFollowFromFollowing(from, message);
        this.handleResponseForAddFromFollowing(from, message);
        this.handleResponseForAddConnection(from, message);
        this.handleResponseForWithdrawConnection(from, message);
        this.handleResponseForRemoveConnectionRequest(from, message);
        this.handleResponseForReasonsRequest(from, message);
        this.handleResponseForAddReasons(from, message);
        this.handleResponseForSentInvitation(from, message);
        this.handleResponseForRemoveConnection(from, message);
        this.handleResponseForFollowunfollow(from, message);
        this.handleResponseForHidePost(from, message);
        this.handleResponseForReport(from, message);
        this.handleDeleteComments(from, message);
        this.handleEditComment(from, message);
        this.handleCreateFeedResposne(from ,message);
        // Customizable Area End
    }
    // Customizable Area Start
    handleCondition = (condition: boolean | string | object, truePart: object | string | undefined, falsePart: object | string | undefined) => {
        return condition ? truePart : falsePart
    }

    handleReportPoupOpen = () => {
        this.setState({isReportOpen: !this.state.isReportOpen})
    }

    handleSelect = (reason: {
        id: number,
        reason: string,
        created_at: string,
        updated_at: string,
        otherReason: string
    }) => {
        this.setState({ selectedReason: reason })
    }

    topicsList = {
        "Buy-Side": { icon: buySideIcon, topics: ["Private Equity", "Growth Equity", "Venture Capital", "Infrastructure Investment", "Real Estate Investment", "Investor Relations and Capital Raising", "Corporate Development"] },
        "Sell-Side": { icon: sellSideIcon, topics: ["Investment Banking", "Equity Research", "Sales and Trading", "Market Analysis"] },
        "Career Development": { icon: careerDevelopmentIcon, topics: ["Breaking into Buy-side", "Breaking into Sell Side", "Career Advancement", "Work-Life Balance", "Networking and Mentorship"] }
    }

    getToken = async () => {
        const token: string =
            Platform.OS == "android" || Platform.OS == "ios"
                ? await getStorageData("token-access")
                : localStorage.getItem("authToken");
        this.setState({ token: token });
    };

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const loggedId = localStorage.getItem('userID');
        this.setState({ loggedId });
            const containerDiv = document.getElementById('pageContainer')
        if (containerDiv)
            containerDiv.scrollIntoView();
        await this.getToken();
        const userID = this.props.navigation.getParam("navigationBarTitleText");
        this.setState({ userID });
        this.handleNetworkTab();
        this.getLoggedInUser();
        // Customizable Area End
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        if (prevState.tabPanelNo !== this.state.tabPanelNo || prevState.activityType !== this.state.activityType) {
            this.handleNetworkTab();
        }
    }
    handleFollowClick = () => {
        this.setState({ followClick: !this.state.followClick })
    }
    handleReportOpen = () => {
        this.setState({ reportClick: !this.state.reportClick })
        if (!this.state.reportClick) {
            this.setState({ reportType: "profile", reportClick: !this.state.reportClick })
            this.getReasonsList()
        } else {
            this.setState({
                selectedReason: {
                    id: 0,
                    reason: "",
                    created_at: "",
                    updated_at: "",
                    otherReason: ""
                },
            })
        }
    }
    handleOpenReason = () => {
        this.setState({ openReason: !this.state.openReason })
    }
    handleResponse = (response: {errors: [{"token": "expired"}], error: string}) => {
        if(response.error) {
            toast.error(response.error)
            return
        }
        Object.values(response.errors[0]).forEach((item: any) => toast.error(item))
        if (Object.keys(response.errors[0]).includes("token")) {
            this.handleNavigate()
        }
    }
    handleResponseForUserProfile =
        (from: string, message: Message) => {
            if (
                this.userReceivedId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                } else {
                    this.setState({ userProfile: apiResponse });
                    this.viewProfile(apiResponse.user_profile.data.id)
                }
            }
        };
    handleResponseForLoggedUserProfile =
        (from: string, message: Message) => {
            if (
                this.userLoggedInID ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                } else {
                    this.setState({ userDetails: apiResponse.user_profile.data.attributes });
                }
            }
        };
    handleResponseForUserDeal =
        (from: string, message: Message) => {
            if (
                this.userDealId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                } else {
                    this.setState({ userDeal: apiResponse });
                }
            }
        };
          handleMessageClick = () => {
            const message: Message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), 'Messaging');
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage) );
            raiseMessage.addData(getName(MessageEnum.SessionResponseData), {from: "Profile", accountId:this.state.userProfile.user_profile
                 })
            message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
            this.send(message);
          }
    handleResponseSavePostApi =
        (from: string, message: Message) => {
            if (
                this.handleSavePostApiId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                }
                this.handleNetworkTab();
            }
        };
    handleResponseForUpVoteApi =
        (from: string, message: Message) => {
            if (
                this.likeID ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                }
                this.handleNetworkTab();
            }
        };
        handleRemoveConnection = () => {
            this.setState({removeConnectionPopup: !this.state.removeConnectionPopup})
        }
        handleWithdraw = () => {
            this.setState({withdrawConnection: !this.state.withdrawConnection})
        }


  ignoreFriendRequestApiCall =  () => {
    const connectionId= this.state.userProfile.user_profile?.data?.attributes?.connection_request_details?.id;
    const token = this.state.token;
    this.setState({ loder: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const ignoreRquestData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    ignoreRquestData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.ignorerequestId = ignoreRquestData.messageId;
    ignoreRquestData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ignoreRquestEndPoint.replace(':connection_request_id', connectionId)
    );
    ignoreRquestData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(ignoreRquestData.id, ignoreRquestData);
  };

  handleResponseForIgnoreRequest = (from: string, message: Message) => {
    if (
      this.ignorerequestId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loder: false });
      if (apiResponse.errors || apiResponse.error) {
        this.handleResponse(apiResponse)
      } else {
        toast.success(apiResponse.message)
        this.getUserProfile();;
      }
    }
  };
    handleResponseForDownVoteApi =
        (from: string, message: Message) => {
            if (
                this.unlikeID ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                }
                this.handleNetworkTab();
            }
        };
    handleResponseForAddCommentAPi =
        (from: string, message: Message) => {
            if (
                this.addCommentID ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                }
                this.setState({loaderComment: false})
                this.handleNetworkTab();
            }
        };
    handleResponseForcommentLikeAPi =
        (from: string, message: Message) => {
            if (
                this.upvoteCommentID ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                }
                this.handleNetworkTab();
            }
        };
    handleResponseForcommentDislike =
        (from: string, message: Message) => {
            if (
                this.downVoteCommentID ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                }
                this.handleNetworkTab();
            }
        };
    handleResponseForUserPostActivity =
        (from: string, message: Message) => {
            if (
                this.userPostId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                } else {
                    const activityType  = this.state.activityType
                    this.setState({ activity: { ...this.state.activity, [activityType]: apiResponse.data } });
                    this.setState({loading: false})
                }
            }
        };

    handleResponseForUserCommentActivity =
        (from: string, message: Message) => {
            if (
                this.commentId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                } else {
                    this.setState({ activity: { ...this.state.activity, comments: apiResponse.data } });
                    this.setState({loading: false})
                }
            }
        };

    handleResponseForUserReactiontActivity =
        (from: string, message: Message) => {
            if (
                this.reactionId ===
                message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
                const apiResponse = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if (apiResponse.errors) {
                    this.handleResponse(apiResponse);
                } else {
                    this.setState({ activity: { ...this.state.activity, reactions: apiResponse.data } });
                    this.setState({loading: false})
                }
            }
        };
    handleResponseForAddFromFollowing = async (
        from: string,
        message: Message
    ) => {
        if (
            this.addFollowerFromFollowingCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            } else {
                toast.success(apiResponse.meta.message)
                await this.getUserProfile();

            }
        }
    };
    handleResForUnFollowFromFollowing = async (
        from: string,
        message: Message
    ) => {
        if (
            this.unFollowFromFollowingCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            } else {
                this.handleFollowClick();
                toast.success(apiResponse.message)
                await this.getUserProfile();

            }
        }
    };

    checkForMessage = (user: UserProfileInterface) => {
        return user.user_profile.data.attributes.connection_request_details?.status === "accepted" || user.user_profile.data.attributes.open_for_direct_approach
    }

    handleResponseForAddConnection = async (
        from: string,
        message: Message
    ) => {
        if (
            this.addConnectionId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            } else {
                await this.getUserProfile();

            }
        }
    };

    handleResponseForWithdrawConnection = (from: string, message: Message) => {
        if (
            this.withdrawConnectionId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.setState({
                withdrawConnection: false
            })
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            } else {
                toast.success(apiResponse.message)
                this.getUserProfile();
            }
        }
    };

    handleResponseForRemoveConnectionRequest = (from: string, message: Message) => {
        if (
            this.removeConnectionId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({
                removeConnectionPopup: false
            })
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            } else {
                this.getUserProfile();
            }
        }
    };
    handleResponseForReasonsRequest = (from: string, message: Message) => {
        if (
            this.reasondId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            } else {
                this.setState({ userReasons: apiResponse.flag_reasons })
            }
        }
    };
    handleResponseForAddReasons = async (
        from: string,
        message: Message
    ) => {
        if (
            this.addConnectionId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            } else {
                await this.getReasonsList();

            }
        }
    };
    handleResponseForSentInvitation = async (
        from: string,
        message: Message
    ) => {
        if (
            this.sendInvitationPostApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors || apiResponse.error) {
                this.handleResponse(apiResponse);
            } else {
                if(this.state.currentPost) {
                    this.followUserApiCall(this.state.receiverId)
                }
                toast.success(apiResponse.message);
                this.getUserProfile();
                await this.getPostList();

            }
        }
    };
    handleResponseForRemoveConnection = async (
        from: string,
        message: Message
    ) => {
        if (
            this.removeInvitationPostApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({
                withdrawConnection: false
            })
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            } else {
                toast.success(apiResponse.message)
                await this.getPostList();

            }
        }
    };
    handleResponseForFollowunfollow = async (
        from: string,
        message: Message
    ) => {
        if (
            this.followUserPostApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            await this.getPostList();
        }
    };
    handleResponseForHidePost = async (
        from: string,
        message: Message
    ) => {
        if (
            this.hidePostApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            } else {
                toast.success(apiResponse.message);
                await this.getPostList();

            }
        }
    };
    handleResponseForReport = async (
        from: string,
        message: Message
    ) => {
        if (
            this.addReasonsId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            }else if(apiResponse.error) {
                toast.error(apiResponse.error)
            } else {
                this.handleReportPoupOpen();
                this.getUserProfile()
                await this.getPostList();
                this.setState({ reportClick: false })

            }
        }
    };
    handleDeleteComments = async (
        from: string,
        message: Message
    ) => {
        if (
            this.deleteCommentApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            }else if(apiResponse.error) {
                toast.error(apiResponse.error)
            } else {
                await this.getPostList();

            }
        }
    };
    handleEditComment = async (
        from: string,
        message: Message
    ) => {
        if (
            this.getAllCommentsForApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            }else if(apiResponse.error) {
                toast.error(apiResponse.error)
            } else {
                await this.getPostList();

            }
        }
    };
    handleCreateFeedResposne = async (
        from: string,
        message: Message
    ) => {
        if (
            this.createFeedPostApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.handleResponse(apiResponse);
            }else if(apiResponse.error) {
                toast.error(apiResponse.error)
            } else {
                this.setState({loaderComment: false})
                await this.getPostList();

            }
        }
    };
    selectTopicsValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        this.setState({
            currentPost: {
                ...this.state.currentPost,
                selectedTopicId: value
            }
        })
    }
    createFeedPost = () => {
        this.setState({ loading: true });
        let formData = new FormData();
        formData.append("data[attributes][name]", '');
        formData.append("data[attributes][description]", "");
        formData.append("data[attributes][location]", "");
        formData.append("data[attributes][body]", this.state.currentPost.description);
        formData.append("data[attributes][sub_topic]", this.state.currentPost.selectedTopicId);
        this.state.currentPost.images.forEach((file: File | { id: number | string, url: string }) => {
            if ('url' in file) {
                formData.append(`data[attributes][images][]`, file.url);
            } else {
                formData.append(`data[attributes][images][]`, file);
                this.setState({loaderComment: true})
            }
        });
        const headers = {
            "contentType": configJSON.createContentType,
            "token": localStorage.getItem("authToken"),
        };
        const careateFeedPost = new Message(getName(MessageEnum.RestAPIRequestMessage));
        careateFeedPost.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), formData
        );
        this.createFeedPostApiId = careateFeedPost.messageId;
        careateFeedPost.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editPostApi}/${this.state.currentPost.id}`
        );
        careateFeedPost.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        careateFeedPost.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );
        runEngine.sendMessage(careateFeedPost.id, careateFeedPost);
    }
    handleNetworkTab = async () => {
        if (this.state.tabPanelNo === 0) {
            this.getUserProfile();
            this.getUserDealExperience();
        } else if (this.state.tabPanelNo === 1) {
            this.state.activityType === "posts" && this.getPostList();
            this.state.activityType === "comments" && this.getCommentList();
            this.state.activityType === "reactions" && this.getReactionList();
        }
    }

    getCommentsOfPost = (commentId: number | string) => {
        const authToken = localStorage.getItem("authToken")
        const header = { 'Content-Type': configJSON.ApiContentType, token: authToken };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getAllCommentsForApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCommentOfPostEndPoint.replace(":comment_id", commentId)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleTabChange = (event: object, value: number) => {
        this.setState({ tabPanelNo: value });
    };

    updateComment = async (comment: string = "", files: (File | { url: string })[] = [], nested: boolean = false, commentId: number | string = "", postID: number | string = "") => {
        const startTime = Date.now();
        if (files.length > 0) {
            this.setState({ loaderComment: true })
        }
        const authToken = await getStorageData("authToken")
        let commentData = new FormData();
        commentData.append("comment[commentable_type]", !nested ? "BxBlockPosts::Post" : "BxBlockComments::Comment");
        commentData.append("comment[comment]", `${comment}`);
        files.forEach((file: File | { url: string }, _index: number) => {
            commentData.append(`comment[comment_images][]`, (file as { url: string })?.url ? (file as { url: string }).url : file as File);
        });
        const config: AxiosRequestConfig = {
            headers: { token: authToken ?? '' },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                const { loaded, total } = progressEvent;
                if (total) {
                    const percentCompleted = Math.round((loaded * 100) / total)
                    const currentTime = Date.now()
                    const elapsedTime = (currentTime - startTime) / 1000;
                    const uploadSpeed = loaded / elapsedTime
                    const remainingTime = (total - loaded) / uploadSpeed;
                    this.setState({
                        progressComment: percentCompleted, remainingTimeComment: remainingTime
                    })
                }
            }
        };
        axios.patch(`${configJSON.baseURL.baseURL}/${configJSON.getCommentsEndPoint}/${commentId}`, commentData, config)
            .then(response => {
                this.getCommentsOfPost(this.state.postShowID);
            })
            .catch(error => {
            }).finally(() => {
                this.setState({
                    loaderComment: false,
                    progressComment: 0,
                    remainingTimeComment: 0
                })

            });
    }

    handleDeleteComment = async (commentId: number | string) => {
        const authToken = localStorage.getItem("authToken")
        const headers = {
            token: authToken,
        };
        const handleDeleteComment = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteCommentApiId = handleDeleteComment.messageId;
        handleDeleteComment.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        handleDeleteComment.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCommentsEndPoint}/${commentId}`
        );
        handleDeleteComment.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        runEngine.sendMessage(handleDeleteComment.id, handleDeleteComment);
    }
    downvotePost = (postId: number | string, type: string, activityType: string) => {
        const authToken = localStorage.getItem("authToken")
        const header = {
            token: authToken,
        };
        let formData = new FormData();
        formData.append("dislikeable_id", `${postId}`);
        formData.append("dislikeable_type", type);
        const downvotePostMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.unlikeID = downvotePostMessage.messageId;
        downvotePostMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.downvoteEndpoint
        );
        runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
    }
    upvotePost = (postId: number | string, type: string, activityType: string) => {
        const authToken = localStorage.getItem("authToken")
        const header = {
            token: authToken,
        };
        let formData = new FormData();
        formData.append("likeable_id", `${postId}`);
        formData.append("likeable_type", type);
        const upvotePostMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.likeID = upvotePostMessage.messageId;
        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.upvoteEndpoint
        );
        runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
    }

    handleUpdateActivity = (type: string) => {
        this.setState({
            showComment: !this.state.showComment,
            postShowID: "",
            activityType: type
        })
        this.handleNetworkTab()
    }
    upvotePostComment = (postId: number | string, type: string) => {
        const authToken = localStorage.getItem("authToken")
        const header = {
            token: authToken,
        };
        let formData = new FormData();
        formData.append("likeable_id", `${postId}`);
        formData.append("likeable_type", type);
        const upvotePostMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.upvoteCommentID = upvotePostMessage.messageId;
        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.upvoteEndpoint
        );
        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        upvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
    }
    downvotePostComment = (postId: number | string, type: string) => {
        const authToken = localStorage.getItem("authToken")
        const header = {
            token: authToken,
        };
        let formData = new FormData();
        formData.append("dislikeable_id", `${postId}`);
        formData.append("dislikeable_type", type);
        const downvotePostMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.downVoteCommentID = downvotePostMessage.messageId;
        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        downvotePostMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.downvoteEndpoint
        );
        runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
    }
    handleShowAllPosts = () => {
        const containerDiv = document.getElementById('pageContainer')
        if (containerDiv)
            containerDiv.scrollIntoView();
        this.setState({ showAllPosts: !this.state.showAllPosts })
    }
    handleDeletePostApi = (postId: number | string) => {
        const authToken = localStorage.getItem("authToken")
        this.setState({
            postToDelete: postId
        })
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };
        const deletePost = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deletePostApiId = deletePost.messageId;
        deletePost.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deletePostApi}/${postId}`
        );
        deletePost.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        deletePost.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        runEngine.sendMessage(deletePost.id, deletePost);
    }
    handleClose = () => {
        this.setState({
            openConfirmDialogNew: {
                open: false,
                title1: "",
                title2: "",
            },
        })
    }
    handleNavigate = () => {
        const toNavigate: Message = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.EmailAccountLoginBlock);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    }
    handlePostMenuCall = (receiverId: number | string, menuType?: 'connect' | 'following' | 'hidePost' | 'reportPost', invitationId?: number | string | boolean) => {
        if (menuType) {
            if (menuType === 'connect') {
                if (invitationId && typeof invitationId !== 'boolean')
                    return this.removeInvitationApi(invitationId)
                this.handleInvitationPopup(receiverId)
            } else if (menuType === 'hidePost') {
                this.handleHidePost(receiverId)
            } else if (menuType === 'following') {
                invitationId ? this.deleteFollowUserApi(receiverId) : this.followUserApiCall(receiverId)
            } else if (menuType === "reportPost") {
                this.setState({reportType: "post"})
                this.handleReportDialogue(receiverId)
            }
        }
    }
    followUserApiCall = async (accountId: number | string) => {
        const authToken = localStorage.getItem('authToken')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken,
        };
        let requestBody = {
            "data": {
                "type": "follow",
                "attributes": {
                    "account_id": accountId
                }
            }
        }
        const followUserMessageID = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.followUserPostApiCallId = followUserMessageID.messageId;
        followUserMessageID.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        followUserMessageID.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(requestBody)
        );
        followUserMessageID.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.followUserApiEndPoint}?token=${authToken}`
        );
        followUserMessageID.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
        );
        runEngine.sendMessage(followUserMessageID.id, followUserMessageID);
    }
    deleteFollowUserApi = async (accountId: number | string) => {
        const authToken = localStorage.getItem("authToken")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken,
        };
        const deleteFollower = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.followUserPostApiCallId = deleteFollower.messageId;
        deleteFollower.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        deleteFollower.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.followUserApiEndPoint}/${accountId}?token=${authToken}`
        );
        deleteFollower.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        runEngine.sendMessage(deleteFollower.id, deleteFollower);
    }
    handleHidePost = async (postId: number | string) => {
        this.setState({
            selectedPostId: postId
        })
        const authToken = localStorage.getItem('authToken')
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.hidePostApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.savePostEndpoint}/${postId}/hide_post`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleInvitationClose = () => {
        this.setState({
            openInvitationDialog: {
                ...this.state.openInvitationDialog,
                open: false,
            },
        })
    }
    handleInviteClick = (noteValue: string) => {
        this.sendInvitation(noteValue, this.state.openInvitationDialog.receiverId)
    }

      handleReportDialogue = (postId?: number | string ) => {
        if (!this.state.reportClick) {
          this.setState({
            selectedPostId: postId || ""
          })
          this.getReasonsList()
        }
        this.setState({
          reportClick: !this.state.reportClick,
          selectedReason: {
            id: 0,
            reason: "",
            created_at: "",
            updated_at: "",
            otherReason: ""
          },
        })
      }
    

    sendInvitation = async (noteValue: string, receiverId: string | number) => {
        this.setState({
            receiverId: receiverId
        })
        const authToken = await getStorageData("authToken")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken,
        };
        let invitationBody = {
            "connection_request": {
                "receiver_id": receiverId,
                "note": noteValue
            }
        }
        const sendInvitationMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sendInvitationPostApiCallId = sendInvitationMessage.messageId;
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(invitationBody)
        );
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendInvitationEndpoint
        );
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(sendInvitationMessage.id, sendInvitationMessage);
    }
    getUserFullName = () => {
        return this.state.userDetails.full_name ? `Mr. ${this.state.userDetails.full_name}` : ''
    }
    removeInvitationApi = async (connectionId: string | number, userId?: string | number) => {
        if (userId) {
            this.setState({
                openInvitationDialog: {
                    open: false,
                    receiverId: userId
                }
            })
        }
        const authToken = localStorage.getItem("authToken")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken,
        };
        const sendInvitationMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.removeInvitationPostApiCallId = sendInvitationMessage.messageId;
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.removeInvitationEndpoint}/${connectionId}/withdraw_connection_request`
        );
        sendInvitationMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        runEngine.sendMessage(sendInvitationMessage.id, sendInvitationMessage);
    }
    handleInvitationPopup = (receiverId: number | string) => {
        this.setState({
            openInvitationDialog: {
                open: true,
                receiverId: receiverId
            },
        })
    }
    createComment = (comment: string, files: File[], nested: boolean, commentId: number | string, postID: number | string, activityType: string) => {
        const authToken = localStorage.getItem("authToken")
        const startTime = Date.now();
        if(files.length > 0) {
            this.setState({loaderComment: true})
        }
        let commentData = new FormData();
        commentData.append("comment[commentable_type]", !nested ? "BxBlockPosts::Post" : "BxBlockComments::Comment");
        commentData.append("comment[post_id]", `${postID}`);
        commentData.append("comment[commentable_id]", `${commentId}`);
        commentData.append("comment[comment]", `${comment}`);
        files.forEach((file) => {
            commentData.append(`comment[comment_images][]`, file);
        });
        const config: AxiosRequestConfig = {
            headers: { token: authToken ?? '' },

            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                const { loaded, total } = progressEvent;
                if (total) {
                    const percentCompleted1 = Math.round((loaded * 100) / total)
                    const elapsedTime1 = (Date.now() - startTime) / 1000;
                    const uploadSpeed1 = loaded / elapsedTime1
                    const remainingTime1 = (total - loaded) / uploadSpeed1;
                    this.setState({
                        progressComment: percentCompleted1, 
                        remainingTimeComment: remainingTime1
                    })
                }
            }
        };
        axios.post(`${configJSON.baseURL.baseURL}/${configJSON.getCommentsEndPoint}`, commentData, config)
            .then(response => {
                this.handleNetworkTab();
            })
            .catch(error => {
            }).finally(() => {
                this.setState({
                    loaderComment: false,
                    remainingTimeComment: 0,
                    progressComment: 0,
                })

            });
    }

    handleSavePost = (postId: number | string, isSaved?: boolean) => {
        const authToken = localStorage.getItem("authToken")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.handleSavePostApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            isSaved ? `${configJSON.savePostApi}/${postId}/unsave_post` : `${configJSON.savePostApi}/${postId}/save_post`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            isSaved ? configJSON.deleteMethod : configJSON.postMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getInitial = (input?: string) => {
        if (input) {
            const words = input.split(' ');
            return words.map(word => word[0]?.toUpperCase()).join('');
        }
        return ''
    }

    toggleReadMore = (postId: string) => {
        this.setState((prevState) => {
          const { expandedPosts } = prevState;
          const isPostExpanded = expandedPosts.includes(postId);
    
          return {
            expandedPosts: isPostExpanded
              ? expandedPosts.filter(id => id !== postId)
              : [...expandedPosts, postId],
          };
        });
      };

    getProfileUrl = () => {
        const userRole = getUserRole();
        return handleConditionFunction(userRole === 'recruiter', 'RecruiterProfile', "JobApplicantProfile")
    }
    
    handleUserNavigation = (userId: number | string) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), userId === this.state.userDetails?.account_details?.id ? this.getProfileUrl() : 'Profile');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), userId);
        this.send(message);
        this.setState({ 
            tabPanelNo: 0, 
            userID: userId as string,
            activity: {
                posts: [],
                comments: [],
                reactions: [],
            },
            activityType: "posts"
        });
    }

    postContentHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            currentPost: {
                ...this.state.currentPost,
                description: event.target.value
            }
        })
    }
    handleDelete = (index: number) => {
        const updatedImages = this.state.currentPost.images ?
            this.state.currentPost?.images.filter((postImage, imageIndex) => imageIndex !== index) :
            [];

        this.setState({
            currentPost: {
                ...this.state.currentPost,
                images: updatedImages,
            }
        });
    };
    handleFeedImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = [
                ...this.state.currentPost.images,
                ...Array.from(event.target.files)
            ];
            const imageList = [...this.state.imageList, ...Array.from(event.target.files)];
            this.setState({
                currentPost: {
                    ...this.state.currentPost,
                    images: files,
                },
                imageList: imageList
            });
        }
        this.setState({ isPostImageUploaded: true })
    };
    handleStateUpdateClick = () => {
        this.setState({ isPostImageUploaded: false });
    }
    handleFeedModalOpen = (post?: Post) => {
        if (this.state.openEditFeedPost) {
            this.setState({
                openEditFeedPost: !this.state.openEditFeedPost,
                currentPost: {
                    id: '',
                    description: '',
                    images: [],
                    selectedTopicId: ''
                }
            })
        } else if (post) {
            this.setState({
                openEditFeedPost: !this.state.openEditFeedPost,
                currentPost: {
                    description: post.attributes.body ?? '',
                    images: post.attributes.images ?? '',
                    id: post.attributes.id ?? '',
                    selectedTopicId: post.attributes.sub_topic ?? ''
                }
            })
        }
    }
    formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'short' });
        return `${month} ${year}`;
    }
    toggleComments = (post: Post) => {
        this.setState({ showComment: !this.state.showComment, postShowID: post.id !== this.state.postShowID ? post.id : "" });
    };

    getUserDealExperience = async () => {
        const token = this.state.token;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const userDealData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        userDealData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        this.userDealId = userDealData.messageId;
        userDealData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userDealApi}`.replace(":connection_id", this.state.userID)
        );
        userDealData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(userDealData.id, userDealData);
    };

    getUserProfile = async () => {
        const token = this.state.token;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const userRecieveData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        userRecieveData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        this.userReceivedId = userRecieveData.messageId;
        userRecieveData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userProfileApi}`.replace(":connection_id", this.state.userID)
        );
        userRecieveData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(userRecieveData.id, userRecieveData);
    };

    getLoggedInUser = async () => {
        const token = this.state.token;
        const logged_in_user_id = localStorage.getItem("userID") || "";
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const userRecieveData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        userRecieveData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        this.userLoggedInID = userRecieveData.messageId;
        userRecieveData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userProfileApi}`.replace(":connection_id", logged_in_user_id)
        );
        userRecieveData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(userRecieveData.id, userRecieveData);
    };

    getPostList = async () => {
        const token = this.state.token;
        this.setState({loading: true})
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const userPostData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        this.userPostId = userPostData.messageId;
        userPostData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userPostApi}`.replace(":connection_id", this.state.userID) + `filter=${this.state.activityType}`

        );
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(userPostData.id, userPostData);
    };

    getReactionList = async () => {
        const token = this.state.token;
        this.setState({loading: true})
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const userPostData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        this.reactionId = userPostData.messageId;
        userPostData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userPostApi}`.replace(":connection_id", this.state.userID) + `filter=reactions`
        );
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(userPostData.id, userPostData);
    };

    getCommentList = async () => {
        const token = this.state.token;
        this.setState({loading: true})
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const userPostData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        this.commentId = userPostData.messageId;
        userPostData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userPostApi}`.replace(":connection_id", this.state.userID) + `filter=comments`
        );
        userPostData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(userPostData.id, userPostData);
    };

    FollowFromFollowing = async (account_id: string) => {
        const token = this.state.token;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const httpBody = {
            "data": {
                "type": "follow",
                "attributes": {
                    "account_id": account_id
                }
            }
        };
        const addFollowerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addFollowerFromFollowingCallId = addFollowerDataMessage.messageId;
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addFollowersApiEndPoint
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
        );
        runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);
    };

    addConnection = async (account_id: string) => {
        const token = this.state.token;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const httpBody = {
            "connection_request": {
                "receiver_id": account_id,
                "note": "",
            }
        };
        const addConnectionDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addConnectionId = addConnectionDataMessage.messageId;
        addConnectionDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addConnectionsApiEndPoint
        );
        addConnectionDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        addConnectionDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        addConnectionDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
        );
        runEngine.sendMessage(addConnectionDataMessage.id, addConnectionDataMessage);
    };

    unFollowFromFollowing = async () => {
        const token = this.state.token;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const unFollowDataUserMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.unFollowFromFollowingCallId = unFollowDataUserMessage.messageId;
        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.unFollowApisApiEndPoint}/${this.state.userProfile.user_profile?.data?.attributes?.account_details?.id}`
        );
        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `${configJSON.deleteMethod}`
        );
        runEngine.sendMessage(unFollowDataUserMessage.id, unFollowDataUserMessage);
    };

    removeConnectionRequest = async () => {
        const token = this.state.token;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const removeConnection = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        removeConnection.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        this.removeConnectionId = removeConnection.messageId;
        removeConnection.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeConnectionRequest.replace(':connection_request_id', this.state.userProfile.user_profile?.data?.attributes?.connection_request_details?.id)
        );
        removeConnection.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        runEngine.sendMessage(removeConnection.id, removeConnection);
    };

    withdrawConnectionRequest = async () => {
        const token = this.state.token;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const withdrawConnection = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        withdrawConnection.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        this.withdrawConnectionId = withdrawConnection.messageId;
        withdrawConnection.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.withdrawConnectionEndPoint.replace(':connection_request_id', this.state.userProfile.user_profile?.data?.attributes?.connection_request_details?.id)
        );
        withdrawConnection.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        runEngine.sendMessage(withdrawConnection.id, withdrawConnection);
    };
    getReasonsList = async () => {
        const token = this.state.token;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const reasonsData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        reasonsData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        this.reasondId = reasonsData.messageId;
        reasonsData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.resonEndPointApi}`
        );
        reasonsData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(reasonsData.id, reasonsData);
    }
    addReasons = async () => {
        const token = this.state.token;
        const headers = {
            token: token,
        };
        const formData = new FormData();
        this.state.reportType === "profile" ? formData.append("account_id", this.state.userID) : formData.append("post_id", `${this.state.selectedPostId}`);
        formData.append("reason_id", `${this.state.selectedReason.id}`);
        formData.append("other_reason", this.state.selectedReason.otherReason);
        const addReportsDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addReasonsId = addReportsDataMessage.messageId;
        addReportsDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addReasonsApiEndPoint
        );
        addReportsDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        addReportsDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        addReportsDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
        );
        runEngine.sendMessage(addReportsDataMessage.id, addReportsDataMessage);
    };

    async viewProfile(userId: number) {
        const authToken = await getStorageData("authToken")
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.viewProfileApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserDetailsEndPoint}/${userId}/view_profile`
        );
    
        const header = {
            token: authToken,
        };
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    // Customizable Area End
}