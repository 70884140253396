import React from "react";

// Customizable Area Start
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import PopoverModal from "../../../components/src/PopoverModal.web";
import ConfirmDialog from "../../../components/src/ConfirmDialog.web";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.container}>
        <Box style={webStyle.card as React.CSSProperties}>
          <div style={webStyle.header}>
            <div style={webStyle.markRead} id="mark-all-read-btn" onClick={this.handleMarlAllRead}>
              {
                !this.state.notificationsData.some(item => !item.attributes.is_read) ? <svg style={{cursor: "text"}} width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.86645 7.60358L1.53312 5.27025C1.40988 5.14548 1.24182 5.07526 1.06645 5.07526C0.891085 5.07526 0.723021 5.14548 0.599785 5.27025C0.339785 5.53025 0.339785 5.94358 0.599785 6.20358L3.39312 8.99692C3.65312 9.25692 4.07312 9.25692 4.33312 8.99692L11.3998 1.93692C11.6598 1.67692 11.6598 1.26358 11.3998 1.00358C11.2766 0.878816 11.1085 0.808594 10.9331 0.808594C10.7578 0.808594 10.5897 0.878816 10.4665 1.00358L3.86645 7.60358Z" fill="#9C9C9C" />
                </svg> : <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.86645 7.60358L1.53312 5.27025C1.40988 5.14548 1.24182 5.07526 1.06645 5.07526C0.891085 5.07526 0.723021 5.14548 0.599785 5.27025C0.339785 5.53025 0.339785 5.94358 0.599785 6.20358L3.39312 8.99692C3.65312 9.25692 4.07312 9.25692 4.33312 8.99692L11.3998 1.93692C11.6598 1.67692 11.6598 1.26358 11.3998 1.00358C11.2766 0.878816 11.1085 0.808594 10.9331 0.808594C10.7578 0.808594 10.5897 0.878816 10.4665 1.00358L3.86645 7.60358Z" fill="#1F497D" />
                </svg>
              }
              &nbsp;
              &nbsp;
              <Typography style={!this.state.notificationsData.some(item => !item.attributes.is_read) ? webStyle.headerTitle : webStyle.headerTitleActive}>Mark all as read</Typography>
            </div>
          </div>
          <Box style={webStyle.notificationContainer}>
            {
              this.state.notificationsData.map(notification => 
              <Box style={!notification.attributes.is_read ? webStyle.cardItemSelect : webStyle.cardItem} className="notification-item" key={notification.id}>
                <Box id="navigateOnNotification" style={webStyle.cardItemLeft} onClick={() => this.handleNavigation(notification)}>
                  &nbsp;
                  &nbsp;
                  <Avatar src={notification.attributes.account.profile_photo} style={!notification.attributes.is_read ? webStyle.pictureSelect : webStyle.picture} >
                  </Avatar>
                  &nbsp;
                  <Box style={webStyle.cardDeatils as React.CSSProperties}>
                    <Typography style={webStyle.title}>
                      {`${notification.attributes.account.full_name} - ${this.getCurrentTitle(notification.attributes.account)}`}
                      &nbsp;
                      {
                        !notification.attributes.is_read && <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="10" height="10" rx="5" fill="#1F497D" />
                        </svg>
                      }
                    </Typography>
                    <Typography style={webStyle.description}>
                      {this.trimText(notification.attributes.remarks)} 
                    </Typography>
                  </Box>
                </Box>
                <Box style={webStyle.cardItemRight as React.CSSProperties}>
                  <PopoverModal  job={{attributes: {status: "open"}, id: 5}} options={["Delete"]} handleoptionClick={() => this.handleOpenDeleteDialog(notification)}>
                    <Box style={{padding: "6px"}}>

                  <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14Z" fill="#9C9C9C" />
                  </svg>
                    </Box>
                  </PopoverModal>
                  <Typography style={webStyle.timeText}>
                    {notification.attributes.created_at}
                  </Typography>
                </Box>
                &nbsp;
                &nbsp;
                &nbsp;
              </Box>)
            }
        </Box>
        <Grid container style={{ display: "flex", width: "100%", justifyContent: "center" }} id="loader-container">
          {this.state.isInfiniteLoading && <CircularProgress style={{ color: '#1F497D' }} />}
        </Grid>
        </Box>
        <ConfirmDialog open={this.state.openConfirm} handleClose={this.handleConfirm} hadndleDelete={this.handleDeleteNotification} questionOne="Are you sure you want to delete this" questionTwo="notification?" />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "15px 0px 85px 0px"
  },
  card: {
    background: "white",
    padding: "10px 24px",
    borderRadius: "8px",
    paddingBottom: "16px",
    width: '100%',
    maxWidth: "980px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  markRead: {
    display: "flex",
    alignItems: "center"
  },
  cardItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "14px 0px",
    borderRadius: "8px",
    borderBottom: "1px solid #F4F4F4",
    width: "100%",
    marginBottom: "8px",
    minHeight: "55px",
    cursor: "pointer"
  },
  cardItemSelect: {
    justifyContent: "space-between",
    display: "flex",
    borderRadius: "8px",
    padding: "14px 0px",
    width: "100%",
    background: "#F4F4F4",
    marginBottom: "8px",
    minHeight: "55px",
    cursor: "pointer"
  },
  cardItemLeft: {
    display: "flex",
    justifyContent: "start",
    flex: 1
  },
  title: {
    display: "flex",
    justifyContent: "start",
    fontFamily: "Arial",
    alignItems: "center",
    fontWeight: "bold",
    color: "#1F497D",
    fontSize: "14px"
  },
  cardDeatils: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  description: {
    display: "flex",
    justifyContent: "start",
    fontFamily: "Arial",
    alignItems: "center",
    fontWeight: "lighter",
    color: "#9C9C9C",
    fontSize: "14px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-all" as const
  },
  cardItemRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between"
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: "end",
    paddingBottom: "8px",
    paddingTop: "5px",
    width: "100%"
  },
  headerTitle: {
    fontfamily: "Arial",
    fontWeight: "bold",
    fontSize: "14px",
    color: "#9C9C9C",
    cursor: "text"
  },
  headerTitleActive: {
    fontfamily: "Arial",
    fontWeight: "bold",
    fontSize: "14px",
    color: "#1F497D",
    cursor: "pointer"
  },
  notificationContainer: {
    borderTop: "1px solid #CBD5E1",
    width: "100%",
    padding: "8px 0px 0px 0px"
  },
  timeText: {
    fontfamily: "Arial",
    fontWeight: "lighter",
    fontSize: "14px",
    color: "#9C9C9C"
  },
  picture: {
    borderRadius: "50%",
    height: "44px",
    width: "44px",
    alignSelf: "center"
  },
  pictureSelect: {
    width: "44px",
    height: "44px",
    borderRadius: "50%",
    border: "2px solid #1F497D",
    alignSelf: "center"
  }
};
// Customizable Area End
