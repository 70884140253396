import React from 'react';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow, { TableRowProps } from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Avatar, Grid, ListItem, ListItemAvatar, ListItemText, Tooltip } from '@material-ui/core';
import CustomViewPopper from './CustomViewPopper';
import CustomPopper from './CustomPopper';
import { CandidateLeaderboard, Score } from '../../../packages/blocks/leaderboard/src/LeaderboardController.web';
import { getOrdinalSuffix } from './Utils.web';

const useRowStyles = makeStyles({
  root: {
    boxShadow: " 0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    '& > *': {
      borderBottom: '0px',
    },
    height: "72vh",
    overflowY: "scroll",
    overflowX: "hidden",
    '&::-webkit-scrollbar': {
      width: '0px', 
      borderRadius: '57px',
      margin: "50px 0px"
    },
    '&::-webkit-scrollbar-track': {
      background: '#D9D9D9',
      width: '3px', 
      borderRadius: '57px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#1F497D',
      borderRadius: '57px',
      width: '3px', 
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#1F497D',
    },
  },
  cellNameAvtar: { maxHeight: "32px", maxWidth: "32px", border: "2px solid #F4F4F4" },
  viewSkillButton: {
    color: "#FFFFFF",
    background: "#6D97C1",
    textTransform: "none",
    fontFamily: "Arial",
    borderRadius: "4px",
    height: "27px",
    "&:hover": {
      background: "#6D97C1",
    }
  },
  stickyRow: {
    position: "sticky",
    top: "81px",
    background: "white",
    zIndex: 1000
  },
  stickyRowZero: {
    position: "sticky",
    top: "-4px",
    background: "white",
    zIndex: 1000
  }
});

interface StyledTableRowProps extends TableRowProps {
  isFirst: boolean;
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderLeft: (props: StyledTableRowProps) => props.isFirst ? "" : "8px solid #FFFFFF",
      borderBottom: (props: StyledTableRowProps) => props.isFirst ? "0px" : "1px solid #F4F4F4",
      borderTop: "0px !important",
      backgroundColor: (props: StyledTableRowProps) =>
        props.isFirst ? "#FEF3C7" : "#FFFFFF",
      '&:hover': {
        backgroundColor: (props: StyledTableRowProps) => props.isFirst ? "#FEF3C7" : "#F1F5F9",
        borderLeft: (props: StyledTableRowProps) => props.isFirst ? "" : "8px solid #1F497D",
        borderBottom: "0px"
      },
    },
  }),
)(({ isFirst = false, ...other }: StyledTableRowProps) => <TableRow {...other} />);

const StyledTableRowSub = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "0px",
      bordedrLeft: "8px",
      backfaceVisibility: "hidden"
    },
  }),
)(({ isFirst = false, ...other }: StyledTableRowProps) => <TableRow {...other} />);

const StyledTableRowTitle = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFFFFF",
      fontFamily: "Arial",
      fontSize: "12px",
      color: "#1F497D",
      fontWeight: "bold",
      '&::before': {
        bottom: "0px",
        content: "''",
        width: "100%",
        height: "1px",
        background: "#F4F4F4",
        position: "absolute",
      }
    },
  }),
)(TableRow);

const StyledTableCellTitle = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "0px",
      backgroundColor: "#FFFFFF",
      fontFamily: "Arial",
      fontSize: "12px",
      color: "#1F497D",
      fontWeight: "bold",
      padding:"5px 0px 5px 10px",
    },
  }),
)(TableCell);

const StyledTableCellRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: "Arial",
      fontSize: "14px",
      color: "rgba(31, 73, 125, 1)",
      fontWeight: "lighter",
      borderBottom: "0px !important",
      padding: "5px 15px 5px 10px",
    },
  }),
)(TableCell);

const StyledTableCellRowOne = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: "12px",
      color: "rgba(31, 73, 125, 1)",
      borderBottom: "0px !important",
      fontFamily: "Arial",
      fontWeight: "lighter",
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
      textDecoration: "underline"
    },
  }),
)(TableCell);

const StyledTableCellFirst = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "0px",
      padding: "0px",
      "&.MuiTableCell-root": {
        padding: "0px !important",
      }
    },
  }),
)(TableCell);

interface KeyValuePair {
  label: string;
  value: string | number;
}

const DetailRow: React.FC<KeyValuePair> = ({ label, value }) => (
  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <div style={{ flex: 1 }}>
      <Typography style={{
        color: '#6D97C1',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Arial'
      }}>{label}</Typography>
    </div>
    <div>
      <Typography style={{
        color: '#D97706',
        fontSize: '10px',
        fontWeight: 400,
        fontFamily: 'Arial',
        marginTop: '3px'
      }}>{value}</Typography>
    </div>
  </div>
);
const GetTitle = (detail: Score) => {
  const renderDetails = (details: { label: string, value: string | number }[]) => (
    <Grid container style={{ padding: '12px 10px', minWidth: "250px" }}>
      {details.map(({ label, value }, index) => (
        <DetailRow key={index} label={label} value={value} />
      ))}
    </Grid>
  );

  const commonBoxStyles = {
    background: '#FFFFFF',
    borderRadius: '8px',
    minWidth: '150px'
  };

  const headerStyles = {
    background: '#F0F5F9',
    padding: '12px 10px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  };

  const titleStyles = {
    color: '#1F497D',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Arial'
  };


  switch (detail.attributes.name) {
    case "Personal Credentials Score":
      const personalDetails = [
        { label: 'Work Experience Points', value: detail.attributes.work_experience_points },
        { label: 'Skill Details Points', value: detail.attributes.skill_details_points },
        { label: 'Personal Details Points', value: detail.attributes.personal_details_points }
      ];

      return (
        <Box style={commonBoxStyles}>
          <Box style={headerStyles}>
            <Typography style={titleStyles}>
              {detail.attributes.name}
            </Typography>
          </Box>
          {renderDetails(personalDetails)}
        </Box>
      );

    case "Networking And Endorsement Score":
      const networkingDetails = [
        { label: 'Connections Points', value: detail.attributes.connections_points }
      ];

      return (
        <Box style={commonBoxStyles}>
          <Box style={headerStyles}>
            <Typography style={titleStyles}>
              {detail.attributes.name}
            </Typography>
          </Box>
          {renderDetails(networkingDetails)}
        </Box>
      );

    case "Deal Experience And Complexity Score":
      const dealDetails = [
        { label: 'Deal Contribution Points', value: detail.attributes.deal_contribution_points },
        { label: 'Deal size Complexity Points', value: detail.attributes.deal_size_complexity_points }
      ]

      return (
        <Box style={commonBoxStyles}>
          <Box style={headerStyles}>
            <Typography style={titleStyles}>
              {detail.attributes.name}
            </Typography>
          </Box>
          {renderDetails(dealDetails)}
        </Box>
      );

    case "Market Knowledge Score":
      const marketDetails = [
        { label: 'Market Knowledge Transaction Points', value: detail.attributes.market_knowledge_transaction_points }
      ]

      return (
        <Box style={commonBoxStyles}>
          <Box style={headerStyles}>
            <Typography style={titleStyles}>
              {detail.attributes.name}
            </Typography>
          </Box>
          {renderDetails(marketDetails)}
        </Box>
      );

    case "Community Engagement Score":
      const communityDetails = [
        { label: 'Feedposts Points', value: detail.attributes.feedposts_points },
        { label: 'Comments Points', value: detail.attributes.comments_points },
        { label: 'Upvotes Points', value: detail.attributes.upvotes_points },

      ]

      return (
        <Box style={commonBoxStyles}>
          <Box style={headerStyles}>
            <Typography style={titleStyles}>
              {detail.attributes.name}
            </Typography>
          </Box>
          {renderDetails(communityDetails)}
        </Box>
      );

    default:
      return <></>;
  }
};

function Row(props: {
  row: CandidateLeaderboard, isFirst: boolean, rowNumber: number, handleSendConnectRequest: (value: CandidateLeaderboard) => void,
  handleRecruiterNavigation: (value: number) => void,
  handleOpenChat: (value: CandidateLeaderboard) => void
}) {
  const { full_name, percentile_score, photo, overall_rank, current_title, city, skills = [], progress_details, total_potential_score } = props.row.attributes;
  let isFirst = props.isFirst;

  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <StyledTableRow isFirst={isFirst} className='table-row-last-895'>
        <StyledTableCellFirst style={{ borderBottom: "0px" }}><IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUpIcon
          style={{
            paddingLeft: isFirst ? "4px" : ""
          }} /> : <KeyboardArrowDownIcon
          style={{
            paddingLeft: isFirst ? "4px" : ""
          }} />}</IconButton></StyledTableCellFirst>
        <StyledTableCellRow align="left" style={{ fontSize: "14px", fontFamily: "Arial" }}>{getOrdinalSuffix(overall_rank)}</StyledTableCellRow>
        <StyledTableCellRow align="left" style={{cursor: isFirst ? "text" : "pointer"}}
          onClick={(event) => {
            props.handleRecruiterNavigation(props.row.attributes.account_id);
            event.stopPropagation()
          }}>
          <ListItem style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <ListItemAvatar style={{ minWidth: "40px" }}>
              <Avatar className={classes.cellNameAvtar} src={photo}>
                {full_name[0]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<Typography style={{ fontSize: "14px", fontFamily: "Arial", fontWeight: isFirst ? "bold" : "lighter" }}>{isFirst ? "YOU" : full_name}</Typography>}
            />
          </ListItem>
        </StyledTableCellRow>
        <StyledTableCellRow align="left">
          <div style={{ position: "relative" }}>
            <svg width="59" height="31" viewBox="0 0 59 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.51" d="M21.2449 20.3088C18.4734 19.4084 15.2636 5.45336 12.3218 5.45336C9.38 5.45336 0.5 24.1507 0.5 24.1507V30.0352H58.5V1.03516C58.5 1.03516 50.38 26.9681 46.3757 26.9681C42.3714 26.9681 39.4127 15.2199 37.3518 13.7135C35.2909 12.207 32.4527 12.5931 29.9915 13.7135C27.5303 14.8338 24.0164 21.2091 21.2449 20.3088Z" fill="url(#paint0_linear_12879_48957)" />
              <path d="M0.5 24.1507C0.5 24.1507 9.38 5.45336 12.3218 5.45336C15.2636 5.45336 18.4734 19.4084 21.2449 20.3088C24.0164 21.2091 27.5303 14.8338 29.9915 13.7135C32.4527 12.5931 35.2909 12.207 37.3518 13.7135C39.4127 15.2199 42.3714 26.9681 46.3757 26.9681C50.38 26.9681 58.5 1.03516 58.5 1.03516" stroke="#17365D" stroke-miterlimit="3.8637" stroke-linecap="round" />
              <circle cx="12" cy="4.5" r="1.75" fill="#1F497D" stroke="white" stroke-width="0.5" />
              <defs>
                <linearGradient id="paint0_linear_12879_48957" x1="26.6806" y1="7.45089" x2="27.535" y2="28.2943" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#0E4FA0" />
                  <stop offset="1" stop-color="#A0BAD4" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
            <div style={{ border: "2px solid #F1F5F9", borderRadius: "5px", background: "white", padding: "2px", position: "absolute", top: "-12px", left: "20%" }}>
              <Typography style={{ color: "#0F172A", fontWeight: "lighter", fontSize: "9px" }}>{Math.round(percentile_score)}%</Typography>
            </div>
          </div>
        </StyledTableCellRow>
        <StyledTableCellRow align="center" style={{ fontSize: "14px", fontFamily: "Arial" }}>{Math.round(total_potential_score)}</StyledTableCellRow>
        <StyledTableCellRow align="left" style={{ fontSize: "14px", fontFamily: "Arial" }}>{current_title}</StyledTableCellRow>
        <StyledTableCellRow align="left" style={{ fontSize: "14px", fontFamily: "Arial" }}>{city}</StyledTableCellRow>
        <StyledTableCellRow align="left">
          <CustomViewPopper
            rowId={props.row.id}
            options={skills}
          />
        </StyledTableCellRow>
        <StyledTableCellRow align="left">
          {
            isFirst ? <></> :
              <CustomPopper
                testId="Popper"
                rowId={props.row.id}
                rowData={props.row}
                onSendMessage={() => props.handleOpenChat(props.row)}
                onSendInvite={() => props.handleSendConnectRequest(props.row)}
              />
          }
        </StyledTableCellRow>
      </StyledTableRow>
      <StyledTableRowSub isFirst={isFirst}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: "0px", borderLeft: "8px solid rgba(238, 236, 225, 0.2)", background: "rgba(238, 236, 225, 0.2)", padding: "0px" }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit style={{
            marginLeft: "-4px",
            background: "#fcfbf9"
          }}>
            <Table aria-label="purchases" style={{marginLeft: "45px"}}>
              <TableHead style={{ border: "0px" }}>
              </TableHead>
              <TableBody>
                {
                  progress_details.data.map((detail) => <StyledTableRowSub isFirst={isFirst}>
                    <CustomTooltip
                      placement={props.rowNumber === 0 ? "bottom-end" : "top-end"}
                      title={GetTitle(detail)}
                    >
                      <StyledTableCellRowOne align="right" style={{ borderBottom: "1px solid #F4F4F4", padding: "30px", fontSize: "12px", fontFamily: "Arial" }}>
                        {detail.attributes.name}
                      </StyledTableCellRowOne>
                    </CustomTooltip>
                    <StyledTableCellRow align="left" style={{ borderBottom: "1px solid #F4F4F4", paddingLeft: "0px", paddingRight: "66px", color: "#6D97C1", fontSize: "12px", fontFamily: "Arial" }}>
                      {detail.attributes.name === "Professional Development Score" ? "-" : Math.round(detail.attributes.total_points)}
                    </StyledTableCellRow>
                    <StyledTableCellRow style={{ borderBottom: "1px solid #F4F4F4", }}></StyledTableCellRow>
                    <StyledTableCellRow style={{ borderBottom: "1px solid #F4F4F4" }}></StyledTableCellRow>
                    <StyledTableCellRow align="right" style={{ borderBottom: "1px solid #F4F4F4" }}></StyledTableCellRow>
                    <StyledTableCellRow align="right" style={{ borderBottom: "1px solid #F4F4F4" }}></StyledTableCellRow>
                    <StyledTableCellRow align="right" style={{ borderBottom: "1px solid #F4F4F4" }}></StyledTableCellRow>
                    <StyledTableCellRow align="right" style={{ borderBottom: "1px solid #F4F4F4" }}></StyledTableCellRow>
                    <StyledTableCellRow align="right" style={{ borderBottom: "1px solid #F4F4F4" }}></StyledTableCellRow>
                    <StyledTableCellRow align="right" style={{ borderBottom: "1px solid #F4F4F4" }}></StyledTableCellRow>
                    <StyledTableCellRow align="right" style={{ borderBottom: "1px solid #F4F4F4" }}></StyledTableCellRow>
                    <StyledTableCellRow align="right" style={{ borderBottom: "1px solid #F4F4F4" }}></StyledTableCellRow>
                    <br />
                    <br />
                  </StyledTableRowSub>
                  )
                }
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </StyledTableRowSub>
    </React.Fragment>
  );
}

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    padding: '0px',
    borderRadius: '8px',
    maxWidth: "100%"
  },
  arrow: {
    color: '#FFFFFF',
  },
}))(Tooltip);

interface Props {
  data: CandidateLeaderboard[];
  currentUserData: CandidateLeaderboard;
  loadMoreData: () => void;
  isFetching: boolean;
  handleSendConnectRequest: (value: CandidateLeaderboard) => void;
  handleOpenChat: (value: CandidateLeaderboard) => void;
  handleRecruiterNavigation: (value: number) => void;
}

export default function TableComponent(props: Props) {
  const classes = useRowStyles();
  const { data, currentUserData, loadMoreData, isFetching, handleOpenChat, handleSendConnectRequest, handleRecruiterNavigation } = props;

  React.useEffect(() => {
    const handleScroll = () => {
      if (!tableContainerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;

      if (scrollTop + clientHeight >= scrollHeight - scrollTop && !isFetching) {
        loadMoreData();
      }
    };

    const ref = tableContainerRef.current;
    if (ref) {
      ref.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ref) {
        ref.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isFetching, loadMoreData]);

  const tableContainerRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <TableContainer component={Paper} className={classes.root} ref={tableContainerRef}>
      <Table aria-label="collapsible table" style={{ position: "relative", margin: "-4px" }}>
        <TableHead className={classes.stickyRowZero} style={{ border: "0px" }} id="header-table">
          <StyledTableRowTitle>
            <StyledTableCellTitle>Rank</StyledTableCellTitle>
            <StyledTableCellTitle align="left">Name</StyledTableCellTitle>
            <StyledTableCellTitle align="left">Percentile Score</StyledTableCellTitle>
            <StyledTableCellTitle align="left">Potential Score</StyledTableCellTitle>
            <StyledTableCellTitle align="left">Job Title</StyledTableCellTitle>
            <StyledTableCellTitle align="left">City</StyledTableCellTitle>
            <StyledTableCellTitle align="left">Skills</StyledTableCellTitle>
            <StyledTableCellTitle align="left"></StyledTableCellTitle>
          </StyledTableRowTitle>
          {
            Object.keys(currentUserData).length > 0 && <Row handleRecruiterNavigation={()=> {}} key={currentUserData.id} row={currentUserData} isFirst={true} rowNumber={898989898989898988} handleOpenChat={handleOpenChat} handleSendConnectRequest={handleSendConnectRequest} />
          }
        </TableHead>
        {
          data.length > 0 && <TableBody>
            {data.filter((user) => user.id !== currentUserData.id).map((row, index) => (
              <Row handleRecruiterNavigation={handleRecruiterNavigation} key={row.id} row={row} isFirst={false} rowNumber={index} handleOpenChat={handleOpenChat} handleSendConnectRequest={handleSendConnectRequest} />
            ))}
            {
              isFetching && <TableRow style={{ padding: "0px", margin: "0px" }}>
                <TableCell style={{ padding: "0px 0px 0px 6px", border: "0px" }}>
                  <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 9.5C8 8.4 7.1 7.5 6 7.5C4.9 7.5 4 8.4 4 9.5C4 10.6 4.9 11.5 6 11.5C7.1 11.5 8 10.6 8 9.5ZM10 9.5C10 10.6 10.9 11.5 12 11.5C13.1 11.5 14 10.6 14 9.5C14 8.4 13.1 7.5 12 7.5C10.9 7.5 10 8.4 10 9.5ZM18 11.5C16.9 11.5 16 10.6 16 9.5C16 8.4 16.9 7.5 18 7.5C19.1 7.5 20 8.4 20 9.5C20 10.6 19.1 11.5 18 11.5Z" fill="#64748B" />
                  </svg>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        }
      </Table>
      {data.length <= 0 && <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%"
      }}>
        <Typography style={{
          fontFamily: "Arial",
          fontSize: "16px",
          fontWeight: "bold",
          color: "#1F497D"
        }}>
          No data found
        </Typography>
      </div>}
    </TableContainer>
  );
}
